import {
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import { useState, useEffect } from "react";
import {AdapterDateFns} from "@mui/x-date-pickers-pro/AdapterDateFns";
import { objectDeepEqualityCheck } from "./utils";
import { Edit, Close } from "@mui/icons-material";
import ContractRequestTextField from "./ContractRequestTextField";

const CustomListItem = withStyles({
  root: {
    backgroundColor: "white",
  },
})(ListItem);

const SubmitButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "rgba(0,128,0, .9)",
    "&:hover": { backgroundColor: "rgba(0,128,0, 1)" },
    width: 200,
  },
})(Button);

const defaultValues = {
  firstName: "",
  lastName: "",
  phone: "",
  npn: "",
  gender: "",
  residentState: "",
  ssn: "",
  birthDate: null,
};

const InfoContents = (props) => {
  const { row, currentClickedRow, updateLead, personalInfoMissing } = props;
  const [leadUpdateInput, setLeadUpdateInput] = useState(defaultValues);
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const setInputValue = (val, key) => {
    setLeadUpdateInput((leadInput) => {
      return { ...leadInput, [key]: val };
    });
  };

  useEffect(() => {
    if (!currentClickedRow) {
      setLeadUpdateInput(defaultValues);
      setEditPersonalInfo(false);
    } else {
      setLeadUpdateInput({
         ...row,
        firstName: row.firstName ?? "",
        lastName: row.lastName ?? "",
        phone: row.phone ?? "",
        npn: row.npn ?? "",
        gender: row.gender ?? "",
        residentState: row.residentState ?? "",
        ssn: row.ssn ?? "",
        birthDate: row.birthDate ?? null,
      });
    }
  }, [currentClickedRow, row]);

  const changesNotMade = objectDeepEqualityCheck(row, leadUpdateInput);

  const makeSubmission = () => {
    setEditPersonalInfo(false);
    updateLead(leadUpdateInput);
  };

  const bDayForDisplay = row.birthDate
    ? new Date(row.birthDate).toLocaleDateString()
    : "";

  const styleInput = (value) => {
    return {
      border: personalInfoMissing && !value ? ".5px #ff7b00 solid" : "",
    };
  };
  const secondaryStyleCheck = (bool) => {
    return {
      color: !bool ? "#053760" : "",
      fontWeight: !bool ? "" : "bold",
      fontStyle: !bool ? "italic" : "",
    };
  };
  const {
    firstName,
    lastName,
    email,
    phone,
    npn,
    gender,
    residentState,
    ssn,
    birthDate,
  } = row;
  return (
    <Grid
      container
      justifyContent="center"
      style={{ backgroundColor: "rgb(245 245 245)", padding: 5 }}
    >
      <Grid item xs={12} sx={{ mt: 2 }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            style={{ color: editPersonalInfo ? "red" : "black" }}
            onClick={() => setEditPersonalInfo(!editPersonalInfo)}
          >
            {editPersonalInfo ? <Close /> : <Edit />}
          </IconButton>
        </div>
        <form
          align="center"
          onSubmit={(e) => {
            e.preventDefault();
            changesNotMade ? void 0 : makeSubmission();
          }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            sx={{ mt: 4 }}
            style={{
              marginBottom: 5,
            }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                fieldToggle={editPersonalInfo}
                displayName="First Name"
                stateValue={leadUpdateInput.firstName}
                rowValue={firstName}
                keyName="firstName"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                fieldToggle={editPersonalInfo}
                displayName="Last Name"
                stateValue={leadUpdateInput.lastName}
                rowValue={lastName}
                keyName="lastName"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem>
                <ListItemText
                  primary="Email"
                  secondary={!email ? "No Data" : email}
                  secondaryTypographyProps={{
                    style: secondaryStyleCheck(email),
                  }}
                />
              </CustomListItem>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                fieldToggle={editPersonalInfo}
                displayName="Phone"
                stateValue={leadUpdateInput.phone}
                rowValue={phone}
                keyName="phone"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                fieldToggle={editPersonalInfo}
                displayName="NPN"
                stateValue={leadUpdateInput.npn}
                rowValue={npn}
                keyName="npn"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem style={styleInput(gender)}>
                {editPersonalInfo ? (
                  <TextField
                    label="Gender"
                    value={leadUpdateInput.gender}
                    onChange={(e) => setInputValue(e.target.value, "gender")}
                    fullWidth
                    select
                  >
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                  </TextField>
                ) : (
                  <ListItemText
                    primary="Gender"
                    secondary={!gender ? "No Data" : gender}
                    secondaryTypographyProps={{
                      style: secondaryStyleCheck(gender),
                    }}
                  />
                )}
              </CustomListItem>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem>
                {editPersonalInfo ? (
                  <TextField
                    label="Resident State"
                    value={leadUpdateInput.residentState}
                    onChange={(e) =>
                      setInputValue(e.target.value, "residentState")
                    }
                    fullWidth
                    autoComplete="off"
                  />
                ) : (
                  <ListItemText
                    primary="Resident State"
                    secondary={!residentState ? "No Data" : residentState}
                    secondaryTypographyProps={{
                      style: secondaryStyleCheck(residentState),
                    }}
                  />
                )}
              </CustomListItem>
            </Grid>            
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem style={styleInput(ssn)}>                    
                {editPersonalInfo ? (
                  <TextField
                    label="SSN"
                    value={leadUpdateInput.ssn}
                    onChange={(e) => setInputValue(e.target.value, "ssn")}
                    fullWidth
                    autoComplete="off"
                  />
                ) : (
                  <ListItemText
                    primary="SSN"
                    secondary={!ssn ? "No Data" : ssn?.substring(ssn?.length - 4)}
                    secondaryTypographyProps={{
                      style: secondaryStyleCheck(ssn),
                    }}
                  />
                )}
              </CustomListItem>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem style={styleInput(birthDate)}>
                {editPersonalInfo ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      openTo="year"
                      label="DOB"
                      value={leadUpdateInput.birthDate}
                      onChange={(date) => setInputValue(date, "birthDate")}
                      fullWidth
                      format="MM/dd/yy"
                    />
                  </LocalizationProvider>
                ) : (
                  <ListItemText
                    primary="DOB"
                    secondary={!bDayForDisplay ? "No Data" : bDayForDisplay}
                    secondaryTypographyProps={{
                      style: secondaryStyleCheck(birthDate),
                    }}
                  />
                )}
              </CustomListItem>
            </Grid>
          </Grid>
          {editPersonalInfo && (
            <SubmitButton
              variant="contained"
              type="submit"
              disabled={changesNotMade}
            >
              Submit
            </SubmitButton>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default InfoContents;
