import { useState, useEffect, useMemo, memo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import classes from "./ProductionStatisticsMUI.module.css";
import { getAgencyStatsProfileOverYears } from "../../services/GraphQl/BDSWebService";
import { setFilteredTotals } from "../../store/actions/production/filteredProductionTotals";

import {
  DataGridPro,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

const ProductionStatisticsMUI = (props) => {
  const { planYear, carrierName } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const filterModel = useSelector(
    (state) => state.currentProduction.filterModel
  );
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    const fetchData = async () => {
      if (agencyId !== null) {
        const results = await getAgencyStatsProfileOverYears(
          parseInt(agencyId)
        );
        setData(results.data.getAgencyStatsProfileOverYears);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [carrierName, planYear]);

  /// Columns for the DataGrid
  /// The field property is the name of the property in the data source object that the column should render.
  /// The headerName property is the text rendered in the column header cell.
  /// The width property defines the width of the column.
  /// The hide property defines if the column is initially hidden.
  /// The headerAlign property defines the horizontal alignment of the column header cell.
  /// The align property defines the horizontal alignment of the cell values.
  /// The headerClassName property defines the CSS class applied to the column header cell.
  /// The type property defines the type of the column value. It can be set to string, number, date, dateTime, boolean, or singleSelect.
  /// The valueOptions property is an array of values that the column value can be set to. It is only used when the type property is set to singleSelect.
  const columns = useMemo(
    () => [
      {
        field: "agentName",
        headerName: "Agent Name",
        width: 200,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "carrierName",
        headerName: "Carrier Name",
        width: 125,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "companyName",
        headerName: "Company Name",
        width: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "hlevel",
        headerName: "Hlevel",
        width: 100,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "ma",
        headerName: "MA",
        width: 10,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "number",
      },
      {
        field: "medsupp",
        headerName: "MEDSUPP",
        width: 10,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        type: "number",
      },
      {
        field: "pdp",
        headerName: "PDP",
        width: 10,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "number",
      },
      {
        field: "planYear",
        headerName: "Plan Year",
        width: 10,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "uplines",
        headerName: "Uplines",
        width: 480,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "county",
        headerName: "County",
        width: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "string",
      },
      {
        field: "state",
        headerName: "State",
        width: 10,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "singleSelect",
        valueOptions: [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "CT",
          "DC",
          "DE",
          "FL",
          "GA",
          "HI",
          "IA",
          "ID",
          "IL",
          "IN",
          "KS",
          "KY",
          "LA",
          "MA",
          "MD",
          "ME",
          "MI",
          "MN",
          "MO",
          "MS",
          "MT",
          "NC",
          "ND",
          "NE",
          "NH",
          "NJ",
          "NM",
          "NV",
          "NY",
          "OH",
          "OK",
          "OR",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VA",
          "VT",
          "WA",
          "WI",
          "WV",
          "WY",
        ],
      },
      {
        field: "effectivedate",
        headerName: "Effective Date",
        width: 100,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        type: "date",
        valueFormatter: (params) => {
          return moment(params.value).format("L");
        },
      },
    ],
    []
  );

  /// The uniqueRows array is used to remove duplicate rows from the data array.
  const uniqueRows = [];
  const dataRows = data.map((row) => {
    return {
      id:
        row.agentId +
        row.carrierId +
        row.companyId +
        row.planYear +
        row.effectivedate +
        row.county +
        row.state,
      agentName: row.agentName,
      carrierName: row.carrierName,
      companyName: row.companyName,
      hlevel: row.hlevel,
      ma: row.ma,
      medsupp: row.medsupp,
      pdp: row.pdp,
      planYear: row.planYear,
      uplines: row.uplines,
      county: row.county,
      state: row.state,
      effectivedate: row.effectivedate,
    };
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const ODD_OPACITY = 0.2;

  /// StripedDataGrid is a styled version of DataGridPro
  /// The getRowClassName property is used to apply the even and odd CSS classes to the rows.
  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const sxBox = {
    height: 640,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  const MemoizedDataGrid = memo(StripedDataGrid);

  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        <MemoizedDataGrid
          apiRef={apiRef}
          slots={{ toolbar: CustomToolbar }}
          loading={isLoading}
          rows={uniqueRows.toSorted((a, b) =>
            a.agentName.localeCompare(b.agentName)
          )}
          columns={columns}
          pagination={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
          disableRowSelectionOnClick
          {...((carrierName || planYear) && {
            initialState: {
              filter: {
                filterModel: filterModel,
              },
            },
          })}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          onStateChange={() => {
            const filteredSortedRowIds =
              gridFilteredSortedRowIdsSelector(apiRef);
            const filteredSortedRows = filteredSortedRowIds.map((id) =>
              apiRef.current.getRow(id)
            );
            const maTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.ma),
              0
            );
            const msTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.medsupp),
              0
            );
            const pdpTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.pdp),
              0
            );

            dispatch(
              setFilteredTotals({
                maTotal: maTotal,
                msTotal: msTotal,
                pdpTotal: pdpTotal,
              })
            );
          }}
        />
      </Box>
    </div>
  );
};

export default ProductionStatisticsMUI;
