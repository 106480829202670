import React from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";
import AgencyPill from "../components/AgencyPill";
import QuickSearch from "../components/QuickSearch";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      padding: "10px 5px 5px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        minHeight: "auto",
      },
    },
    typoGraphy: {
      fontSize: "calc(18px + (36 - 18) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      color: "white",
      fontWeight: "lighter",
    },
  };
});

function AgencyMenu(props) {
  const {
    carrierSearchHandler,
    groupSearchHandler,
    contractStatusSearchHandler,
    levelSearchHandler,
    selectedAgencySearchHandler,
    clearSearchHandler,
    carriers,
    agencyName,
    levels,
    agentSearchInputValue,
    topAgentCallback,
    onPageChanged,
    onAlphaPageChanged,
    isSingleView,
    Count,
    pageLimit,
    currentAlphaPage,
    currentPage,
    carriersOptions,
    groupOptions,
    contractOptions,
    levelOptions,
    handleAgentSearchInputChange,
    clearAgentNameSearch,
    download,
    downloadable,
    dataToDownload,
    popCsvLink,
    toggleStatusCallback,
    awayEnabledStatus,
    filterByAwayStatus,
  } = props;

  const { classes } = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      data-tour="ad__agencyMenu"
    >
      <Grid item style={{ padding: 8 }} data-tour="ad__agencyPill">
        <AgencyPill
          agencyName={agencyName}
          topAgentCallback={topAgentCallback}
          toggleStatusCallback={toggleStatusCallback}
        />
      </Grid>
      <Grid item style={{ padding: 8 }}>
        <Typography className={classes.typoGraphy}>My Agents</Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <QuickSearch
          carriers={carriers}
          levels={levels}
          agentSearchInputValue={agentSearchInputValue}
          handleAgentSearchInputChange={handleAgentSearchInputChange}
          clearSearchHandler={clearSearchHandler}
          carrierSearchHandler={carrierSearchHandler}
          groupSearchHandler={groupSearchHandler}
          contractStatusSearchHandler={contractStatusSearchHandler}
          levelSearchHandler={levelSearchHandler}
          selectedAgencySearchHandler={selectedAgencySearchHandler}
          onPageChanged={onPageChanged}
          onAlphaPageChanged={onAlphaPageChanged}
          isSingleView={isSingleView}
          Count={Count}
          pageLimit={pageLimit}
          currentAlphaPage={currentAlphaPage}
          currentPage={currentPage}
          carriersOptions={carriersOptions}
          groupOptions={groupOptions}
          contractOptions={contractOptions}
          levelOptions={levelOptions}
          download={download}
          downloadable={downloadable}
          dataToDownload={dataToDownload}
          popCsvLink={popCsvLink}
          clearAgentNameSearch={clearAgentNameSearch}
          awayEnabledStatus={awayEnabledStatus}
          filterByAwayStatus={filterByAwayStatus}
        />
      </Grid>
    </Grid>
  );
}

export default AgencyMenu;
