import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Chip, Grid } from "@mui/material";

const styles = (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "#3f51b5",
    },
    "& .MuiInputLabel-root": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-root": {
      color: "#3f51b5",
    },
    "& .MuiInputBase-root": {
      color: "#3f51b5",
      backgroundColor: "#eee",
    },
    borderRadius: 0,
  },
});

class AgentChips extends React.Component {
  render() {
    const { agents, handleAgentClicked, handleAgentDelete, rowData } =
      this.props;

    let agentNames = agents.map((agent, index) => (
      <Chip
        label={agent.fName + " " + agent.lName}
        onClick={() => handleAgentClicked(index)}
        onDelete={() => !rowData && handleAgentDelete(index)}
        style={{ backgroundColor: "#3F51B6" }}
        color="primary"
      />
    ));

    return (
      <Grid
        container
        id="agentChips"
        style={{
          backgroundColor: "rgb(239, 240, 241)",
        }}
      >
        <Grid item id="agentsToAdd">
          Requested Agents:{" "}
        </Grid>
        <Grid item id="agentsToAddLabel">
          {agentNames}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AgentChips);
