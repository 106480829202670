import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "tss-react/mui";
import {
  Grid,
  Card,
  Typography,
  Button,
  ButtonGroup,
  Modal,
  Backdrop,
  Fade,
  TextField,
  MenuItem,
} from "@mui/material/";
import Responsive from "react-responsive";
import AgentStepper from "../components/common/AgentStepper";
import { AllOut, Adjust, Notifications, Email } from "@mui/icons-material/";
import AgentTile from "../components/agentTileFiles/AgentTile";
import AgencyMenu from "../components/AgencyMenu";
import "./AgentList.css";
import AgentListUtils from "../utils/AgentListUtils";
import PageContainerWithMenu from "./PageContainerWithMenu";
import { clearAgentList } from "../store/actions/agents/agents";
import ReactGA from "react-ga";
import agentParse from "../components/agentTileFiles/agentParser";
import AgentLoader from "../services/webservices/AgentLoader";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import AgentListTour from "../components/agentTileFiles/agentListTour";
import AgentContactList from "../components/agentTileFiles/AgentContactList";
import RTSTable from "../components/rtsFiles/RTSTable";
import AgentListRtsTour from "../components/agentTileFiles/AgentListRtsTour";
import BallotIcon from "@mui/icons-material/Ballot";
import { getCertsTable } from "../services/GraphQl/BDSWebService";
import SendNotificationsDialog from "../components/common/sendCommunications";
import HtmlToolTip from "../components/common/HtmlTooltip";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    tileGrid: {
      padding: 10,
      height: "auto",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    card: {
      height: "100%",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#575757",
    },
    certsLoadingCard: {
      height: "100%",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#575757",
    },
    agentsInView: {
      fontSize: 18,
      color: "#444242",
    },
    agentsInViewContainer: {
      textAlign: "left",
      paddingLeft: 10,
      marginTop: 2,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "50%",
      margin: "auto",
    },
    modalPaper: {
      backgroundColor: "#f7f7f7",
      border: "2px solid #000",
      //boxShadow: theme.shadows[5],
      padding: theme.spacing(0.5),
      // width: "50vw",
      // height: "50vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderRadius: 5,
    },
  };
});

const AgentList = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initialAgents = useSelector((agents) => agents.agents, shallowEqual);
  const [agents, setAgents] = useState(initialAgents);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [unfilteredAgents, setUnfilteredAgents] = useState([]);
  const [rts, setRts] = useState([]);
  const [pageLimit, setPageLimit] = useState(6);
  const [carriers, setCarriers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [agentSearchInputValue, setAgentSearchInputValue] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [levels, setLevels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentAlphaPage, setCurrentAlphaPage] = useState(1);
  const [isSingleView, setIsSingleView] = useState(false);
  const [singleAgent, setSingleAgent] = useState({});
  const [carriersOptions, setCarriersOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [showAllContractsList, setShowAllContractsList] = useState([]);
  const [globalShowAllPressed, setGlobalShowAllPressed] = useState(false);
  const [showContactListPressed, setShowContactListPressed] = useState(false);
  const [showRTSTablePressed, setShowRTSTablePressed] = useState(false);
  const [rtsModalOpen, setRtsModalOpen] = useState(false);
  const [openCerts, setOpenCerts] = useState(false);
  const [openedFrom, setOpenedFrom] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [communicationsTitle, setCommunicationsTitle] = useState("");
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [awayEnabledStatus, setAwayEnabledStatus] = useState("Off");
  const [tourIsOpen, setTourIsOpen] = useState(false);
  const [rtsTourOpen, setRtsTourOpen] = useState(false);
  const [breakoutProduction, setBreakoutProduction] = useState(false);
  const [csvLink, setCsvLink] = useState(null);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const Mobile = (props) => <Responsive {...props} maxWidth={959} />;
  const Default = (props) => <Responsive {...props} minWidth={960} />;

  const Count = agents.length;
  const ModalTitle = "Please Note:";
  const ModalMessage =
    "Information displayed is derived from carrier reports as they are received. For real-time status, please refer to the applicable carrier portal or contact the carrier’s Broker Support hotline. Agents are ultimately responsible for ensuring they are properly licensed, appointed, and certified for the applicable plan year prior to selling. Berwick Insurance Group is not responsible for any errors or omissions of any Ready to Sell (RTS) information provided.";

  const offset = (currentPage - 1) * pageLimit;
  const [currentAgents, setCurrentAgents] = useState(
    agents.slice(offset, offset + pageLimit)
  );

  const loadAgents = useCallback(async () => {
    if (initialAgents.length === 0) return;

    //this works when there are agents
    const res = AgentListUtils.LoadAgents(initialAgents);

    setLevels(res.distinctLevels);
    setAgents(res.sortedAgents);
    setCurrentAgents(res.sortedAgents.slice(offset, offset + pageLimit));
    setCarriers(res.distinctCarriers.toSorted((a, b) => a.localeCompare(b)));
    setFilteredAgents(res.sortedAgents);
    setUnfilteredAgents(res.sortedAgents);
    setIsLoading(false);
  }, [initialAgents, offset, pageLimit]);

  const filterAgents = useCallback(
    (filter, fromLink) => {
      let updatedList = fromLink ? [...unfilteredAgents] : [...filteredAgents];
      updatedList = updatedList.filter(filter);

      if (fromLink) {
        setSingleAgent(updatedList[0]);
        setIsSingleView(true);
      } else {
        setAgents(updatedList);
        setCurrentPage(1);
      }
    },
    [filteredAgents, unfilteredAgents]
  );

  useEffect(() => {
    setCurrentAgents(agents.slice(offset, offset + pageLimit));
  }, [currentPage, agents, offset, pageLimit]);

  const CallFilter = useCallback(() => {
    ReactGA.event({
      category: "AgentListFilter",
      action: "filter",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " filtered agent list.",
    });

    //first filter aganist carriers
    let filteredAgentsList = JSON.parse(JSON.stringify(unfilteredAgents));
    if (carriersOptions.length > 0) {
      filteredAgentsList = AgentListUtils.BuildAgentContractMap(
        carriersOptions,
        filteredAgentsList
      );
    }
    //second filter aganist contract status
    if (contractOptions.length > 0) {
      filteredAgentsList = AgentListUtils.FilterByStatus(
        contractOptions,
        filteredAgentsList
      );
    }

    //third filter aganist level
    if (levelOptions.length > 0) {
      filteredAgentsList = AgentListUtils.FilterByLevel(
        levelOptions,
        filteredAgentsList
      );
    }
    //fourth filter aganist groups
    if (groupOptions.length > 0) {
      const lowerCasegroup = groupOptions.map((item) => item.toLowerCase());
      filteredAgentsList = AgentListUtils.FilterByGroup(
        lowerCasegroup,
        filteredAgentsList
      );
    }

    if (agentSearchInputValue && agentSearchInputValue !== "") {
      const filterByNameCriteria = (agent) => {
        return (
          (
            agent.firstname.toLowerCase().replace(/\s+/g, "") +
            agent.lastname.toLowerCase().replace(/\s+/g, "")
          ).search(agentSearchInputValue.toLowerCase().replace(/\s+/g, "")) !==
          -1
        );
      };
      filteredAgentsList = filteredAgentsList.filter(filterByNameCriteria);
    }
    if (awayEnabledStatus !== "Off") {
      filteredAgentsList = filteredAgentsList.filter(({ userInfo }) =>
        awayEnabledStatus === "Registered" ? userInfo : !userInfo
      );
    }
    //finially set list
    setAgents(filteredAgentsList);
    setFilteredAgents(filteredAgentsList);
    setCurrentAlphaPage(1);
    setCurrentPage(1);
  }, [
    agentSearchInputValue,
    carriersOptions,
    contractOptions,
    groupOptions,
    levelOptions,
    unfilteredAgents,
    awayEnabledStatus,
  ]);

  useEffect(() => {
    CallFilter();
  }, [
    CallFilter,
    agentSearchInputValue,
    carriersOptions,
    contractOptions,
    groupOptions,
    levelOptions,
    unfilteredAgents,
  ]);

  useEffect(() => {
    const runInit = async () => {
      await Init();
    };
    const agencyName = sessionStorage.getItem("agencyName");
    setAgencyName(agencyName);

    if (initialAgents.length === 0) {
      runInit();
    } else if (initialAgents.length > 0 && filteredAgents.length === 0)
      loadAgents();
    else setIsLoading(false);
  }, [initialAgents.length, loadAgents]);

  const handleClose = () => {
    setRtsModalOpen(false);
  };

  const closeTour = () => {
    setTourIsOpen(false);
    setRtsModalOpen(true);
  };

  const download = useCallback(() => {
    ReactGA.event({
      category: "Agents",
      action: "Download",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " downloaded agent list.",
    });

    const spreadsheetRows = [];

    if (showContactListPressed) {
      filteredAgents.forEach((agent) => {
        const record = {
          Name: agent.firstname + " " + agent.lastname,
          Email: agent.email,
          Phone: agent.phone,
          Address: agent.address,
          City: agent.city,
          State: agent.state,
          Zip: agent.zip,
        };
        spreadsheetRows.push(record);
      });
    } else if (showRTSTablePressed) {
      rts
        .filter(({ planYear }) => planYear === selectedYear)
        .forEach((item) => {
          const record = {
            Name: item.agentName,
            NPN: item.npn,
            Email: item.email,
            "Plan Year": item.planYear,
            Carrier: item.carrierName,
            "Is Corp": item.isCorporation ? "Yes" : "No",
            "Is Certified": item.certified,
            "Is RTS": item.ready,
            Product: item.product,
            State: item.state,
          };
          spreadsheetRows.push(record);
        });
    } else {
      filteredAgents.forEach((agent) => {
        agent.agentCarriers.forEach((ac) => {
          ac.contracts.forEach((contract) => {
            const record = {
              Carrier: ac.carrier,
              "First Name": agent.firstname,
              "Last Name": agent.lastname,
              Corporation: contract.is_corporation ? "Yes" : "",
              "Company Name": ac.companyName ? ac.companyName : "",
              Phone: agent.phone,
              "E-mail": agent.email,
              Street: agent.address,
              "Resident City": agent.city,
              "Resident State": agent.state,
              "Zip Code": agent.zip,
              "Resident County": agent.county,
              "Contract State": contract.state,
              "Contract Status": agentParse.statusParse(
                contract.contractStatus
              ),
              "Status Date": agentParse.formateStatsDate(contract),
              "Writing Number": contract.writing_number,
              "Agent Level": ac.agent_level,
              Upline: ac.upline.agent_upline,
            };
            spreadsheetRows.push(record);
          });
        });
      });
    }
    setDataToDownload(spreadsheetRows);
  });

  useEffect(() => {
    if (csvLink?.link && dataToDownload.length > 0) {
      csvLink.link.click();
    }
  }, [csvLink?.link, dataToDownload]);

  const setShowAllContracts = (agent, result) => {
    ReactGA.event({
      category: "AgentList",
      action: "Show All Contracts - Tile",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " toggled show all contracts on tile.",
    });
    const showAll = showAllContractsList;
    if (result) {
      /* add agent id to show all list */
      setShowAllContractsList([...showAll, agent.agentId]);
    } else {
      /* remove agent id from list */
      const index = showAll.indexOf(agent.agentId);
      if (index !== -1) {
        setShowAllContractsList([showAll.splice(index, 1)]);
      }
    }
  };

  const showAllContracts = (agent) => {
    return showAllContractsList.indexOf(agent.agentId) !== -1;
  };

  const handleShowContractsForAllAgents = () => {
    ReactGA.event({
      category: "AgentList",
      action: "Show All Contracts - Page",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " toggled show all contracts for page.",
    });
    const agentIdsToShowAllContracts = globalShowAllPressed
      ? []
      : agents.map((agent) => agent.agentId);
    if (showContactListPressed) {
      setShowContactListPressed(false);
    } else {
      setShowAllContractsList(agentIdsToShowAllContracts);
      setGlobalShowAllPressed(!globalShowAllPressed);
      setShowRTSTablePressed(false);
    }
  };

  const handleShowAgentContactList = () => {
    ReactGA.event({
      category: "AgentList",
      action: "Show Agent Contact List",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " toggled show agent contact list page.",
    });

    setShowContactListPressed(!showContactListPressed);
    setShowRTSTablePressed(false);
  };

  const handleShowRTSTable = (e) => {
    e.preventDefault();
    setShowRTSTablePressed(!showRTSTablePressed);
    setShowContactListPressed(false);
  };

  const sendCommunication = (titleToUse) => {
    setIsDialogOpen(true);
    setCommunicationsTitle(titleToUse);
  };

  const handleShowAllContractsChange = (agent) => {
    setShowAllContracts(agent, !showAllContracts(agent));
  };

  const topAgentFilter = (agentName) => {
    if (!isLoading) {
      setBreakoutProduction(true);
      setAgentSearchInputValue(agentName);
    }
  };

  const onPageChanged = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const handleAlphaSort = (currentAlphaPage) => {
    setCurrentAlphaPage(currentAlphaPage);

    ReactGA.event({
      category: "AgentList",
      action: "AlphaSort",
      label:
        "Agency : " + sessionStorage.getItem("agencyId") + " used alpha sort.",
    });

    let filterCriteria = (agent) => {
      return agent;
    };

    if (currentAlphaPage !== 1) {
      filterCriteria = (agent) => {
        return (
          agent.lastname[0]
            .toLowerCase()
            .replace(/\s+/g, "")
            .search(
              String.fromCharCode(65 + currentAlphaPage - 2)
                .toLowerCase()
                .replace(/\s+/g, "")
            ) !== -1
        );
      };
    }

    filterAgents(filterCriteria);
  };

  const handleAgentSearchInputChange = (event) => {
    event.preventDefault();
    searchByName(event);
  };

  const clearAgentNameSearch = () => {
    setAgentSearchInputValue("");
  };

  const searchByName = (event) => {
    ReactGA.event({
      category: "AgentListFilter",
      action: "search",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " searched for agent.",
    });
    const agentName = event.target.value;
    setAgentSearchInputValue(agentName);
    const filterCriteria = (agent) => {
      return (
        agent.firstname.toLowerCase().search(agentName.toLowerCase()) !== -1 ||
        agent.lastname.toLowerCase().search(agentName.toLowerCase()) !== -1
      );
    };
    filterAgents(filterCriteria);
  };

  const ClearList = () => {
    setAgents([]);
    setCurrentAgents([]);
    setPageLimit(6);
    setCarriers([]);
    setIsLoading(true);
    setAgentSearchInputValue("");
    setAgencyName("");
    setLevels([]);
    setCurrentPage(1);
    setCurrentAlphaPage(1);
    setIsSingleView(false);
    setSingleAgent({});
    setCarriersOptions([]);
    setGroupOptions([]);
    setContractOptions([]);
    setLevelOptions([]);
    setDataToDownload([]);
    setShowAllContractsList([]);
    setGlobalShowAllPressed(false);
    setShowContactListPressed(false);
    setShowRTSTablePressed(false);
    setRtsModalOpen(false);
    setOpenCerts(false);
    setOpenedFrom("");
    setSelectedYear("");
    setYears([]);
    setIsDialogOpen(false);
    setCommunicationsTitle("");
    setRefreshLoading(false);
    setAwayEnabledStatus("Off");
  };

  const closeRtsTour = () => {
    setRtsTourOpen(false);
  };

  const handleLevelSearch = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "AgentListFilter",
      action: "LevelSearch",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " used level search.",
    });
    const value = event.target.value;
    setLevelOptions(value);
  };

  const handleClearSearch = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "AgentListFilter",
      action: "ClearSearch",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " used clear search.",
    });

    setAgentSearchInputValue("");
    setCarriersOptions([]);
    setGroupOptions([]);
    setContractOptions([]);
    setLevelOptions([]);
    setIsSingleView(false);
    setSingleAgent({});
    setGlobalShowAllPressed(false);
    setShowContactListPressed(false);
    setShowRTSTablePressed(false);
    setShowAllContractsList([]);
    setAwayEnabledStatus("Off");
    ResetList();
  };

  const handleTopProducingSearch = (event) => {};

  const handleCarrierSearch = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "AgentListFilter",
      action: "CarrierSearch",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " used carrier search.",
    });
    setCarriersOptions(event.target.value);
  };

  const handleGroupSearch = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "AgencyListFilter",
      action: "AgencySearch",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " used agency search.",
    });
    setGroupOptions(event.target.value);
  };

  const handleContractStatusSearch = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "AgentListFilter",
      action: "StatusSearch",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " used status search.",
    });
    const value = event.target.value;
    setContractOptions(value);
  };

  const ResetList = () => {
    setFilteredAgents([...unfilteredAgents]);
    setAgents([...unfilteredAgents]);
    setCurrentPage(1);
    setCurrentAlphaPage(1);
  };

  const handleAgentClick = (agent) => (event) => {
    event.preventDefault();

    if (!isSingleView) {
      setIsSingleView(true);
      setSingleAgent(agent);
    } else {
      setIsSingleView(false);
    }
  };

  const popCsvLink = (csvLink) => {
    setCsvLink(csvLink);
  };

  const handletoggleStatusCallback = (e, statuses) => {
    e.preventDefault();
    setContractOptions(statuses);
  };

  const changeSelectedYear = (e) => {
    const target = e.target.value;
    setSelectedYear(target);
  };

  const filterByAwayStatus = (type) => {
    setAwayEnabledStatus(type);
  };

  const awayRegistered = agents.filter((agent) =>
    communicationsTitle !== "Email"
      ? agent.userInfo?.loginDate !== null
      : awayEnabledStatus === "Unregistered"
      ? !agent.userInfo
      : true
  );

  let containerView = null;
  if (showContactListPressed && currentAgents.length > 1) {
    containerView = AgentContactList(agents);
  } else if (showRTSTablePressed) {
    containerView = RTSTable(
      rts.filter(({ planYear }) => planYear === selectedYear)
    );
  } else if (isSingleView || currentAgents.length === 1) {
    const agent = currentAgents.length === 1 ? currentAgents[0] : singleAgent;
    const showAllContractsForAgent = showAllContracts(agent);
    containerView = (
      <Card
        style={{
          margin: "24px",
          width: "100%",
          height: "100%",
        }}
        className={classes.card}
      >
        <AgentTile
          agent={agent}
          openCerts={openCerts}
          onAgentClick={handleAgentClick}
          isSingleView={true}
          setShowAllContracts={setShowAllContracts}
          showAllContracts={showAllContractsForAgent}
          handleShowAllContractsChange={handleShowAllContractsChange}
          breakoutProduction={breakoutProduction}
        />
      </Card>
    );
  } else if (
    currentAgents.length > 1 ||
    (!showContactListPressed && !globalShowAllPressed && !showRTSTablePressed)
  ) {
    containerView = currentAgents.map((agent) => (
      <Grid
        key={agent.agentId}
        className={classes.tileGrid}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Card elevation={10} className={classes.card}>
          <AgentTile
            agent={agent}
            openCerts={openCerts}
            onAgentClick={handleAgentClick}
            isSingleView={isSingleView}
            setShowAllContracts={setShowAllContracts}
            showAllContracts={showAllContracts(agent)}
            handleShowAllContractsChange={handleShowAllContractsChange}
            classes={classes}
          />
        </Card>
      </Grid>
    ));
  }

  const Init = async (refresh) => {
    const initHelperFunction = async () => {
      AgentLoader.loadAgents(
        sessionStorage.getItem("agencyId"),
        async () => {}
      );
    };

    /* do not load if loader has already been fired */
    if (
      !initialAgents ||
      !Array.isArray(initialAgents) ||
      initialAgents.length <= 0
    ) {
      if (!tourIsOpen && !rtsTourOpen) {
        setRtsModalOpen(true);
      }
      await initHelperFunction();
    } else if (refresh) {
      setRefreshLoading(true);
      ClearList();
      dispatch(clearAgentList());
      initHelperFunction();
      setRefreshLoading(false);
    } else {
      loadAgents();
    }

    const rtsResponse = await getCertsTable(sessionStorage.getItem("agencyId"));
    const years = [
      ...new Set(
        rtsResponse.map(({ planYear }) => planYear).sort((x, y) => y - x)
      ),
    ];
    setYears(years);
    setSelectedYear(years[0]);
    setRts(rtsResponse);
    setYears(years);
    setSelectedYear(years[0]);

    /* if we came from the production table filter by name */
    if (props.location.state) {
      if (!props.location.state.isContractBased) {
        setBreakoutProduction(true);
      }
      let { agentName, statuses } = props.location.state;
      if (agentName) {
        if (agentName.ma_top) {
          agentName = agentName.ma_top;
        }
        if (agentName.ms_top) {
          agentName = agentName.ms_top;
        }
        if (agentName.value) {
          agentName = agentName.value;
        }
        setAgentSearchInputValue(agentName);
      }
      if (statuses) {
        setContractOptions(statuses);
      }
    }
  };

  useEffect(() => {
    if (props.location.state) {
      if (!props.location.state.isContractBased) {
        setBreakoutProduction(true);
      }
      let { agentName, statuses } = props.location.state;
      if (agentName) {
        if (agentName.ma_top) {
          agentName = agentName.ma_top;
        }
        if (agentName.ms_top) {
          agentName = agentName.ms_top;
        }
        if (agentName.value) {
          agentName = agentName.value;
        }
        setAgentSearchInputValue(agentName);
      }
      if (statuses) {
        setContractOptions(statuses);
      }
    }
  }, [props.location.state]);

  return (
    <>
      <div>
        <PageContainerWithMenu
          overlayActive={isLoading}
          overlayloaderText="Creating Agent Tiles..."
          menuPadding={10}
          type={"agents"}
          reloadAgents={Init}
          menu={
            <AgencyMenu
              toggleStatusCallback={handletoggleStatusCallback}
              agentSearchInputValue={agentSearchInputValue}
              handleAgentSearchInputChange={handleAgentSearchInputChange}
              carriers={carriers}
              levels={levels}
              clearAgentNameSearch={clearAgentNameSearch}
              clearSearchHandler={handleClearSearch}
              topProducingHandler={handleTopProducingSearch}
              carrierSearchHandler={handleCarrierSearch}
              groupSearchHandler={handleGroupSearch}
              contractStatusSearchHandler={handleContractStatusSearch}
              levelSearchHandler={handleLevelSearch}
              agencyName={agencyName}
              topAgentCallback={topAgentFilter}
              onAlphaPageChanged={handleAlphaSort}
              currentAlphaPage={currentAlphaPage}
              currentPage={currentPage}
              onPageChanged={onPageChanged}
              isSingleView={isSingleView}
              pageLimit={pageLimit}
              carriersOptions={carriersOptions}
              groupOptions={groupOptions}
              contractOptions={contractOptions}
              levelOptions={levelOptions}
              download={download}
              downloadable={agents.length > 0}
              dataToDownload={dataToDownload}
              popCsvLink={popCsvLink}
              awayEnabledStatus={awayEnabledStatus}
              filterByAwayStatus={filterByAwayStatus}
            />
          }
          padding="10"
          content={
            <div>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
                className={classes.agentsInViewContainer}
              >
                <Grid item>
                  <Typography className={classes.agentsInView}>
                    Agents In View: {agents.length}
                  </Typography>
                </Grid>
                <Grid item>
                  {!globalShowAllPressed ? (
                    <HtmlToolTip
                      placement="bottom"
                      title="Show All Contracts For All Agents"
                    >
                      <ButtonGroup size="small">
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            color: "#eee",
                            backgroundColor: "#3f51b5",
                          }}
                          onClick={handleShowContractsForAllAgents}
                        >
                          <AllOut style={{ fontSize: "18px" }} />
                        </Button>
                      </ButtonGroup>
                    </HtmlToolTip>
                  ) : (
                    <HtmlToolTip
                      placement="right"
                      title="Hide All Contracts For All Agents"
                    >
                      <ButtonGroup size="small">
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            color: "#eee",
                            backgroundColor: "#3f51b5",
                          }}
                          onClick={handleShowContractsForAllAgents}
                        >
                          <Adjust style={{ fontSize: "18px" }} />
                        </Button>
                      </ButtonGroup>
                    </HtmlToolTip>
                  )}
                </Grid>
                <Grid item>
                  <HtmlToolTip
                    placement="bottom"
                    title="Show Agent Contact List"
                  >
                    <ButtonGroup size="small">
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          color: "#eee",
                          backgroundColor: "#3f51b5",
                        }}
                        onClick={handleShowAgentContactList}
                      >
                        <Email style={{ fontSize: "18px" }} />
                      </Button>
                    </ButtonGroup>
                  </HtmlToolTip>
                </Grid>
                <Grid item>
                  <HtmlToolTip placement="bottom" title="Show Ready To Sell">
                    <ButtonGroup size="small">
                      <Button
                        variant="contained"
                        size="small"
                        data-tour="ad__rtsCertsButton"
                        style={{
                          color: "#eee",
                          backgroundColor: "#3f51b5",
                        }}
                        onClick={handleShowRTSTable}
                      >
                        <BallotIcon style={{ fontSize: "18px" }} />
                      </Button>
                    </ButtonGroup>
                  </HtmlToolTip>
                </Grid>
                <Grid item>
                  <HtmlToolTip placement="bottom" title={"Send Notifications"}>
                    <ButtonGroup size="small">
                      <Button
                        variant="contained"
                        size="small"
                        data-tour="ad__showNotificationsButton"
                        style={{
                          color: "#eee",
                          backgroundColor: "#3f51b5",
                        }}
                        onClick={() => sendCommunication("Notification")}
                      >
                        <Notifications style={{ fontSize: "18px" }} />
                      </Button>
                    </ButtonGroup>
                  </HtmlToolTip>
                </Grid>
                {showContactListPressed && (
                  <Grid item>
                    <HtmlToolTip title="The agent list is modified via the filter options">
                      <Button
                        style={{ outline: "none" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => sendCommunication("Email")}
                      >
                        Email Listed Agents
                      </Button>
                    </HtmlToolTip>
                  </Grid>
                )}
                {showRTSTablePressed && (
                  <Grid item xs={2}>
                    <TextField
                      onChange={changeSelectedYear}
                      value={selectedYear}
                      select
                      variant="filled"
                      fullWidth
                      style={{ background: "#eee", borderRadius: 2 }}
                      size="small"
                      label="RTS Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={0} direction="row" alignItems="stretch">
                {containerView}
              </Grid>
              <Grid
                container
                direction="row"
                spacing={0}
                justifyContent="center"
              >
                {!isSingleView &&
                !showContactListPressed &&
                !showRTSTablePressed &&
                agents.length > pageLimit ? (
                  <Grid item xs={12}>
                    <AgentStepper
                      Count={Count}
                      pageLimit={pageLimit}
                      currentPage={currentPage}
                      onBack={() => setCurrentPage(currentPage - 1)}
                      onNext={() => setCurrentPage(currentPage + 1)}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} />
                )}
              </Grid>
            </div>
          }
        />
        <AgentListTour
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          isOpen={tourIsOpen}
          closeTour={closeTour}
          rounded={5}
          accentColor={"white"}
        />
        <AgentListRtsTour
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
          isOpen={rtsTourOpen}
          closeTour={closeRtsTour}
          rounded={5}
          accentColor={"white"}
          openedFrom={openedFrom}
        />
      </div>
      <SendNotificationsDialog
        currentAgents={awayRegistered}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setCommunicationsTitle("");
        }}
        title={communicationsTitle}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={rtsModalOpen}
        onClose={handleClose}
        disableBackdropClick
        closeAfterTransition
        slot={Backdrop}
        disableEscapeKeyDown
        slotProps={{
          backdrop: { timeout: 500 },
        }}
      >
        <Fade in={rtsModalOpen}>
          <div className={classes.modalPaper}>
            <Default>
              <Typography
                color="error"
                variant="h5"
                gutterBottom
                id="transition-modal-title"
              >
                {ModalTitle}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                id="transition-modal-description"
              >
                {ModalMessage}
              </Typography>
              <Button
                contained="contained"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: "1.5em" }}
                onClick={() => setRtsModalOpen(false)}
              >
                OK
              </Button>
            </Default>
            <Mobile>
              <Typography
                color="error"
                variant="h5"
                gutterBottom
                id="transition-modal-title"
              >
                {ModalTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                id="transition-modal-description"
              >
                {ModalMessage}
              </Typography>
              <Button
                contained="contained"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: "1.5em" }}
                onClick={() => setRtsModalOpen(false)}
              >
                OK
              </Button>
            </Mobile>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default AgentList;
