import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { PlayArrow, AccountTree } from "@mui/icons-material";
import { agentHierarchyWithIds } from "../../services/GraphQl/BDSWebService";
import HtmlToolTip from "../common/HtmlTooltip";
export const colorOptions = [
  "#84A9C0",
  "#466362",
  "#7E6551",
  "#8896AB",
  "#938581",
  "#B3CBB9",
  "#DDD8B8",
  "#6A66A3",
  "#BBC7DC",
];

const AgentHierarchyModal = (props) => {
  const {
    currentAgentId,
    openAH,
    toggleAH,
    agentName,
    uplineArr,
    setUplineArr,
  } = props;
  const [colorIdentifier, setColorIdentifier] = useState([]);
  const [tempAgentId, setTempAgentId] = useState("");
  //function to split both string for upline and ids
  const splitFilter = (strg, splitBy) => {
    //split and remove Berwick by name or by id
    return strg.split(`${splitBy}`).filter((data) => {
      return !/\bberwick|1775\b/gi.test(data);
    });
  };
  useEffect(() => {
    //only run when an agent hasn't hit the backend yet
    if (openAH && currentAgentId !== tempAgentId) {
      const agencyId = sessionStorage.getItem("agencyId");
      setTempAgentId(currentAgentId);
      setUplineArr([]);
      agentHierarchyWithIds(currentAgentId, agencyId).then((rows) => {
        let idArr = [];
        //map return data to be usable
        const mapRows = rows.map((row) => {
          //split both variables here
          const splitUplines = splitFilter(row.uplineString, "<");
          const splitIds = splitFilter(row.agencyList, ",");
          //map upline names to return needed row
          const mergedArr = splitUplines
            .filter((d, i) => i !== 0)
            .map((data, i) => {
              const hierarchyEle = data.trim();
              //push id to array for later use in assigning colors
              if (!idArr.includes(splitIds[i])) {
                idArr.push(splitIds[i]);
              }
              return {
                hierarchyEle,
                id: splitIds[i],
              };
            });
          //return reconstructed row: carrier name and array of hierarchy map
          return { carrier: row.carrierName, mergedArr };
        });
        //assign a color based on acceptable color range from colorOptions arr
        setColorIdentifier(
          idArr.map((data, i) => {
            return {
              color: colorOptions[i],
              colorId: data,
            };
          })
        );
        setUplineArr(mapRows);
      });
    }
  }, [openAH, uplineArr]);

  return (
    <Dialog open={openAH} onClose={toggleAH} maxWidth="lg" fullWidth>
      <DialogTitle style={{ textAlign: "center" }}>All Uplines</DialogTitle>
      {uplineArr.length > 0 ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 400 }}
        >
          <Grid
            item
            xs={8}
            sm={6}
            md={2}
            style={{
              background: "#535353",
              padding: 20,
              textAlign: "center",
              color: "#eee",
              borderRadius: 10,
            }}
          >
            {agentName}
          </Grid>
          <Grid
            item
            xs={12}
            md={1}
            align="center"
            style={{ margin: "10px 4px" }}
          >
            <AccountTree style={{ color: "#535353", fontSize: 24 }} />
          </Grid>
          <Grid item>
            {uplineArr.map((row, index) => {
              return (
                <Grid
                  key={`${row.carrier} ${index}`}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{
                    borderRadius: 10,
                    background: "#eee",
                    margin: 4,
                    borderLeft: `solid 12px #535353`,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    align="start"
                    style={{
                      fontSize: 12,
                      padding: 10,
                      background: "rgb(225 225 225)",
                      color: "black",
                    }}
                  >
                    {row.carrier}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                    style={{ padding: 10 }}
                  >
                    {row.mergedArr.length === 0 && (
                      <Grid
                        item
                        style={{
                          borderRadius: 5,
                          width: 155,
                          padding: 5,
                          margin: 2,
                          background: "#535353",
                          color: "#eee",
                          textAlign: "center",
                        }}
                      >
                        Direct Agent
                      </Grid>
                    )}
                    {row.mergedArr.map(({ hierarchyEle, id }, i) => {
                      //grab assigned color
                      const background = colorIdentifier.find(
                        ({ colorId }) => colorId === id
                      );
                      return (
                        <Grid item key={`${id} ${i}`}>
                          <Grid container alignItems="center">
                            <HtmlToolTip
                              title={hierarchyEle}
                              backgroundColor={
                                background ? background.color : ""
                              }
                              color="#eee"
                            >
                              <Typography
                                style={{
                                  color: "white",
                                  fontSize: 14,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  display: "block",
                                  background: background
                                    ? background.color
                                    : "",
                                  borderRadius: 5,
                                  width: 155,
                                  padding: 5,
                                  margin: 2,
                                  textAlign: "center",
                                }}
                              >
                                {hierarchyEle}
                              </Typography>
                            </HtmlToolTip>
                            {i !== row.mergedArr.length - 1 && (
                              <PlayArrow
                                style={{
                                  color: background ? background.color : "",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          style={{ height: 300 }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item style={{ fontSize: 22 }}>
            ...Loading
          </Grid>
        </Grid>
      )}
      <DialogActions>
        <Button variant="contained" onClick={toggleAH} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AgentHierarchyModal;
