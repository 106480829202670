import React from "react";
import { Grid } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const LineChartModel = (props) => {
  const { lineData, product } = props;

  if (lineData.length > 0) {
    return (
      <div style={{ width: "98%" }}>
        <Grid container direction="column" justifyContent="right">
          <Grid item padding={1}>
            <label>{product}</label>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <ResponsiveContainer width="100%" height={140}>
            <LineChart
              height={200}
              data={lineData}
              margin={{ top: 15, right: 5, left: 5, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="quarter" />
              <YAxis />
              <Tooltip />
              <Line name=" " dataKey="Year1" stroke="#F3A3F1" />
              <Line name=" " dataKey="Year2" stroke="#040400" />
              <Line name=" " dataKey="Year3" stroke="#33A1FF" />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};

export default LineChartModel;
