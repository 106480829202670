import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Card, CardContent } from "@mui/material";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";

const styles = (theme) => {
  const titleRoot = {
    fontSize: "2em",
    color: "#003760",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  const tableTextRoot = {
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  return {
    titleRoot,
    tableTextRoot,
    card: {
      height: "100%",
      backgroundColor: "#FFFFFF",
      padding: 0,
      margin: 0,
    },
    title: {
      ...titleRoot,
      fontSize: "1em",
    },
    titleInSingleView: {
      ...titleRoot,
    },
    table: {
      padding: 0,
    },
    tableText: {
      ...tableTextRoot,
      fontSize: "0.8em",
    },
    tableTextInSingleView: {
      ...tableTextRoot,
    },
    Tooltip: {},
  };
};
function CertsTable(props) {
  const {
    classes,
    isSingleView,
    certsData,
    toggleCertsProduction,
    productsData,
    changeState,
  } = props;
  const data = certsData;
  const titleClasses = isSingleView ? classes.titleInSingleView : classes.title;
  const tableTextclasses = isSingleView
    ? classes.tableTextInSingleView
    : classes.tableText;
  if (productsData.products?.length === 0) {
    setTimeout(() => {
      return changeState("products");
    }, 2000);
    return (
      <Card className={classes.card} elevation={3}>
        <Typography
          className={titleClasses}
          color="textPrimary"
          gutterBottom
          align="left"
          style={{
            textTransform: "uppercase",
            letterSpacing: "3px",
            fontWeight: "bold",
            paddingInlineStart: "1em",
            margin: "3em 0 3em",
            color: "red",
          }}
        >
          No products
        </Typography>
      </Card>
    );
  } else {
    return (
      <Card className={classes.card} elevation={3}>
        <CardContent>
          <Typography
            className={titleClasses}
            color="textPrimary"
            gutterBottom
            align="left"
          >
            Products
          </Typography>

          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Carrier
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Plan Year
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Is Corporation
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Is Certified
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Product
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  State
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Ready
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsData.products.map((row, i) => (
                <TableRow
                  key={i}
                  onClick={() => toggleCertsProduction(i, data)}
                >
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {productsData.name}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {productsData.planYear}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {productsData.isCorporation ? "YES" : "NO"}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {row.certified === "true" ? "YES" : "NO"}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {row.product}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {row.state}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="left"
                  >
                    {row.carrierNote === "" ? row.ready : row.carrierNote }  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
export default withStyles(styles)(CertsTable);
