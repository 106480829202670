import { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  FormLabel,
  Grid,
} from "@mui/material";
import {
  AccountCircleTwoTone,
  ArrowDropDown,
  ArrowDropUp,
  AssignmentTwoTone,
  Close,
  Error,
  HowToVoteOutlined,
} from "@mui/icons-material";
import OcrRadioGroup from "./OcrRadioGroup";
import ContractContents from "./ContractContents";
import InfoContents from "./InfoContents";

const OcrCollapseContents = (props) => {
  const {
    row,
    onboardContractRequests,
    currentClickedRow,
    requestsToProcess,
    setRequestsToProcess,
    infoCollapseArr,
    setInfoCollapseArr,
    mediaQuery800px,
    updateOcr,
    updateLead,
    _openProcessDialog,
    checkMissingContractInfo,
    personalInfoMissing,
    itemToShow,
    carrierNameList,
    hasMissingInfo,
  } = props;
  const [clearSelected, setClearSelected] = useState(false);
  const [personalInfoCollapse, setPersonalInfoCollapse] = useState(false);
  const getColorByIdAndType = (id) => {
    const _row = requestsToProcess.find((req) => req.ocrId === id);
    switch (_row?.status) {
      case undefined:
        return "";
      case "send":
        return "0, 128, 0,";
      case "deny":
        return "255, 0, 0,";
      default:
        return "";
    }
  };
  const allDenyRequests = !requestsToProcess.some(
    (rtp) => rtp.status === "send"
  );
  const checkAgainstRequests = () => {
    let result = [];
    for (let item of requestsToProcess) {
      const checkRow = checkMissingContractInfo([item]);
      if (checkRow && item.status === "send") {
        result.push(1);
      }
    }
    return result.length !== 0;
  };
  const processDisabled =
    onboardContractRequests?.length === requestsToProcess.length &&
    (allDenyRequests
      ? allDenyRequests
      : checkAgainstRequests()
      ? !hasMissingInfo
      : true);
  useEffect(() => {
    if (!currentClickedRow && personalInfoCollapse) {
      setPersonalInfoCollapse(false);
    }
  }, [currentClickedRow, personalInfoCollapse]);
  const backgroundColorToShow = (state) => {
    return state ? "#ff7b00" : "rgb(0, 55, 96)";
  };

  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 4,
      }}
    >
      <div
        style={{
          background: "white",
          display: "grid",
          placeItems: "center",
          padding: 5,
        }}
      >
        <div style={{ marginTop: 16 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingTop: 5,
              paddingRight: 10,
            }}
          >
            <Button
              onClick={() => setPersonalInfoCollapse(!personalInfoCollapse)}
              startIcon={
                personalInfoMissing ? <Error /> : <AccountCircleTwoTone />
              }
              endIcon={
                personalInfoCollapse ? <ArrowDropUp /> : <ArrowDropDown />
              }
              variant="contained"
              style={{
                backgroundColor: backgroundColorToShow(personalInfoMissing),
                color: "#eee",
                marginBottom: 15,
              }}
              size="medium"
            >
              Personal Info
            </Button>
          </div>
          <Collapse in={personalInfoCollapse}>
            <InfoContents
              row={row}
              currentClickedRow={currentClickedRow}
              updateLead={updateLead}
              personalInfoMissing={personalInfoMissing}
            />
          </Collapse>
        </div>
      </div>
      {onboardContractRequests?.map((contractRequest, i) => {
        const colorToUse = getColorByIdAndType(contractRequest.ocrId);
        const isCollapsed = infoCollapseArr.includes(contractRequest.ocrId);
        const isAnthem =
          contractRequest.carrierName.toLowerCase().search("anthem") !== -1;
        const isScan =
          contractRequest.carrierName.toLowerCase().search("scan") !== -1;
        const hasMissingContractInfo = checkMissingContractInfo([
          contractRequest,
        ]);
        const iconToShow = itemToShow(hasMissingContractInfo);
        const radioDisabled = hasMissingContractInfo || personalInfoMissing;
        return (
          <div
            style={{
              padding: 20,
            }}
            key={`${i}-${contractRequest.ocrId}`}
          >
            <Card>
              <CardHeader
                style={{
                  backgroundColor: colorToUse ? `rgba(${colorToUse} .10)` : "",
                  borderBottom: colorToUse
                    ? `2px solid rgba(${colorToUse} 1)`
                    : "",
                  borderRight: colorToUse
                    ? `8px solid rgba(${colorToUse} 1)`
                    : "",
                  transition: "all .10s ease-in-out",
                }}
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h3 style={{ fontWeight: "300" }}>
                        {contractRequest.carrierName}
                      </h3>
                      <i style={{ fontSize: 16 }}>
                        Created on {contractRequest.createDate}
                      </i>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: mediaQuery800px ? "center" : "flex-end",
                        padding: 5,
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{
                            color: radioDisabled
                              ? "#ff7b00"
                              : "rgb(0 0 0 / 80%)",
                            borderBottomColor: radioDisabled
                              ? "#ff7b00"
                              : "rgb(0 0 0 / 40%)",
                          }}
                        >
                          {radioDisabled
                            ? "Information Missing"
                            : "Request Status"}
                        </FormLabel>

                        <OcrRadioGroup
                          contractRequest={contractRequest}
                          currentClickedRow={currentClickedRow}
                          setRequestsToProcess={setRequestsToProcess}
                          clearSelected={clearSelected}
                          setClearSelected={setClearSelected}
                          radioDisabled={radioDisabled}
                        />
                      </FormControl>
                    </div>
                  </div>
                }
              />
              <CardContent
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {iconToShow}
                  <Button
                    small
                    onClick={() =>
                      setInfoCollapseArr((ica) =>
                        isCollapsed
                          ? ica.filter((id) => id !== contractRequest.ocrId)
                          : [...ica, contractRequest.ocrId]
                      )
                    }
                    startIcon={
                      hasMissingContractInfo ? <Error /> : <AssignmentTwoTone />
                    }
                    endIcon={isCollapsed ? <ArrowDropUp /> : <ArrowDropDown />}
                    variant="contained"
                    style={{
                      backgroundColor: backgroundColorToShow(
                        hasMissingContractInfo
                      ),
                      color: "#eee",
                    }}
                    size="medium"
                  >
                    Contract Info
                  </Button>
                </div>
              </CardContent>
              <Collapse in={isCollapsed}>
                <ContractContents
                  contractRequest={contractRequest}
                  isCollapsed={isCollapsed}
                  updateOcr={updateOcr}
                  isScan={isScan}
                  isAnthem={isAnthem}
                  carrierNameList={carrierNameList}
                />
              </Collapse>
            </Card>
          </div>
        );
      })}
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{ color: "green", marginTop: 5, padding: 20 }}
      >
        <Grid item xs={12} sm container alignItems="center">
          <p style={{ color: "rgba(255, 0, 0, .70)" }}>
            To proceed, you must select a request option (Send or Deny) on each
            contract request listed above.
          </p>
        </Grid>
        <ButtonGroup>
          {requestsToProcess.length > 0 && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setClearSelected(true);
              }}
              startIcon={<Close />}
              aria-label="clear-selections"
              variant="outlined"
              style={{ color: "red", borderColor: "red" }}
            >
              Clear
            </Button>
          )}
          <Button
            onClick={(e) => {
              e.stopPropagation();
              _openProcessDialog();
            }}
            endIcon={<HowToVoteOutlined />}
            aria-label="proceed-contract-request"
            disabled={!processDisabled}
            variant="outlined"
            color="inherit"
          >
            Process
          </Button>
        </ButtonGroup>
      </Grid>
    </div>
  );
};

export default OcrCollapseContents;
