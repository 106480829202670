import {
  SET_FILTERED_PRODUCTION_TOTALS,
  CLEAR_FILTERED_PRODUCTION_TOTALS,
  SET_PRODUCTION_FILTER_MODEL,
  CLEAR_PRODUCTION_FILTER_MODEL,
} from "../../constants/action-types";

/* redux-thunk implementation */
export const setFilteredTotals = (
  filteredMaTotal,
  filteredMsTotal,
  filteredPdpTotal
) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILTERED_PRODUCTION_TOTALS,
      payload: filteredMaTotal,
      filteredMsTotal,
      filteredPdpTotal,
    });
  };
};

export const setProductionFilters = (filterModel) => {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCTION_FILTER_MODEL,
      payload: filterModel,
    });
  };
};

export const clearFilteredProductionTotals = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTERED_PRODUCTION_TOTALS });
  };
};

export const clearProductionFilterModel = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PRODUCTION_FILTER_MODEL });
  };
};
