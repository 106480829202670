import React, { useState } from "react";
import { Popper } from "@mui/base/Popper";
import { styled, css } from "@mui/system";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const ProductionDataByCarrier = (props) => {
  const { data } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  /// This function is used to load the values for the popper
  const loadValues = () => {
    const results = [];
    data.map((item) => results.push(item.carrierName + ": " + item.ETLDate));
    return results;
  };

  /// This function is used to handle the click event
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /// This function is used to handle the click away event
  const handleClickAway = () => {
    setAnchorEl(false);
  };

  /// This is the popper open state
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <TriggerButton
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          Report Dates
        </TriggerButton>
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <StyledPopperDiv>
            {loadValues().map((item) => (
              <div>{item}</div>
            ))}
          </StyledPopperDiv>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

/// This is the styled component for the trigger button
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

/// This is the styled component for the trigger button
const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

/// This is the styled component for the trigger button
const TriggerButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 1px ${
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(45, 45, 60, 0.2)"
  }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`
);

/// This is the styled component for the popper div
const StyledPopperDiv = styled("div")(
  ({ theme }) => css`
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: ${theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    padding: 0.75rem;
    color: ${theme.palette.mode === "dark" ? grey[100] : grey[700]};
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    opacity: 1;
    margin: 0.25rem 0;
  `
);

export default ProductionDataByCarrier;
