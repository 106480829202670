/* Agent List Support */
export const SET_AGENT_LIST = "SET_AGENT_LIST";
export const CLEAR_AGENT_LIST = "CLEAR_AGENT_LIST";
export const SET_AGENT_LIST_AGENCIES = "SET_AGENT_LIST_AGENCIES";
export const SET_AGENT_LIST_AGENCIES_ERROR = "SET_AGENT_LIST_AGENCIES_ERROR";
export const CLEAR_AGENT_LIST_AGENCIES = "CLEAR_AGENT_LIST_AGENCIES";
export const SET_AGENT_LIST_CRITERIA = "SET_AGENT_LIST_CRITERIA";
export const CLEAR_AGENT_LIST_CRITERIA = "CLEAR_AGENT_LIST_CRITERIA";
export const AGENT_LIST_LOADING = "AGENT_LIST_LOADING";

/* Production Support */
export const SET_PRODUCTION = "SET_PRODUCTION";
export const SET_CURRENT_PRODUCTION = "SET_CURRENT_PRODUCTION";
export const SET_PRODUCTION_ERROR = "SET_PRODUCTION_ERROR";
export const SET_FILTERED_PRODUCTION_TOTALS = "SET_FILTERED_PRODUCTION_TOTALS";
export const CLEAR_FILTERED_PRODUCTION_TOTALS =
  "CLEAR_FILTERED_PRODUCTION_TOTALS";
export const SET_PRODUCTION_FILTER_MODEL = "SET_PRODUCTION_FILTER_MODEL";
export const CLEAR_PRODUCTION_FILTER_MODEL = "CLEAR_PRODUCTION_FILTER_MODEL";
export const SET_CRITERIA = "SET_CRITERIA";
export const CLEAR_CRITERIA = "CLEAR_CRITERIA";
export const SET_DIRTY = "SET_DIRTY";
export const SET_LEVELS = "SET_LEVELS";
export const SET_AGENCIES = "SET_AGENCIES";
export const SET_AGENCIES_ERROR = "SET_AGENCIES_ERROR";
export const CLEAR_LEVELS = "CLEAR_LEVELS";
export const CLEAR_AGENCIES = "CLEAR_AGENCIES";
export const SET_ETL_DATES = "SET_ETL_DATES";
export const CLEAR_ETL_DATES = "CLEAR_ETL_DATES";

export const CLEAR_CURRENT_PRODUCTION = "CLEAR_CURRENT_PRODUCTION";
export const PRODUCTION_LOADING = "PRODUCTION_LOADING";
export const CURRENT_PRODUCTION_LOADING = "CURRENT_PRODUCTION_LOADING";
export const LOADING = "LOADING";

/* Contracts */
export const SET_CONTRACT_STATUSES = "SET_CONTRACT_STATUSES";
export const CLEAR_CONTRACT_STATUSES = "CLEAR_CONTRACT_STATUSES";

/* global */
export const CLEAR_ALL = "CLEAR_ALL";
export const GET_ALL = "GET_ALL";
export const GET_ALL_ERROR = "GET_ALL_ERROR";

/* Contract Request Form */
export const SET_ALL_CARRIERS = "SET_ALL_CARRIERS";
export const SET_ALL_CARRIERS_ERROR = "SET_ALL_CARRIERS_ERROR";
export const CLEAR_ALL_CARRIERS = "CLEAR_ALL_CARRIERS";
export const GET_STATES = "GET_STATES";
export const GET_STATES_ERROR = "GET_STATES_ERROR";
export const GET_AGENTLIST = "GET_AGENTLIST";
export const GET_AGENTLIST_ERROR = "GET_AGENTLIST_ERROR";

/* PLAN CHANGE */
export const SET_PLAN_PRODUCTION = "SET_PLAN_PRODUCTION";
export const SET_PLAN_PRODUCTION_ERROR = "SET_PLAN_PRODUCTION_ERROR";
export const CLEAR_PLAN_PRODUCTION = "CLEAR_PLAN_PRODUCTION";
export const PLAN_PRODUCTION_LOADING = "PLAN_PRODUCTION_LOADING";
export const SET_PLANCHANGE_CARRIERS = "SET_PLANCHANGE_CARRIERS";
export const SET_PLANCHANGE_CARRIERS_ERROR = "PLANCHANGE_CARRIERS_ERROR";
export const CLEAR_PLANCHANGE_CARRIERS = "CLEAR_PLANCHANGE_CARRIERS";
export const PLANCHANGE_CARRIERS_LOADING = "PLANCHANGE_CARRIERS_LOADING";
export const SET_FILTERED_PLANCHANGE_TOTALS = "SET_FILTERED_PLANCHANGE_TOTALS";
export const CLEAR_FILTERED_PLANCHANGE_TOTALS =
  "CLEAR_FILTERED_PLANCHANGE_TOTALS";
export const SET_PLANCHANGE_FILTER_MODEL = "SET_PLANCHANGE_FILTER_MODEL";
export const CLEAR_PLANCHANGE_FILTER_MODEL = "CLEAR_PLANCHANGE_FILTER_MODEL";

/* Production Headers */
export const SET_PRODUCTION_TOTALS = "SET_PRODUCTION_TOTALS";

/* Sent Notifications */
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_ERROR = "SET_NOTIFICATIONS_ERROR";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

/* Contract Requests */
export const SET_CONTRACT_REQUESTS = "SET_CONTRACT_REQUESTS";
export const SET_CONTRACT_REQUESTS_ERROR = "SET_CONTRACT_REQUESTS_ERROR";
export const CLEAR_CONTRACT_REQUESTS = "CLEAR_CONTRACT_REQUESTS";
export const SET_CONTRACT_REQUESTS_LOADING = "SET_CONTRACT_REQUESTS_LOADING";

/* Recruiter */
export const SET_RECRUITER_INFO = "SET_RECRUITER_INFO";
export const UPDATE_RECRUITER_INFO = "UPDATE_RECRUITER_INFO";
export const SET_RECRUITER_INFO_ERROR = "SET_RECRUITER_INFO_ERROR";
export const CLEAR_RECRUITER_INFO = "CLEAR_RECRUITER_INFO";
