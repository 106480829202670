import React from "react";
import moment from "moment";

const agentParse = {
  agentParseTooltip,
  createIcon,
  statusParse,
  statusParseReverse,
  formateStatsDate,
};

function agentParseTooltip(contractStatus, writingNum) {
  let text;
  switch (contractStatus) {
    case "AGENT_CERT":
      text = "Agent in certification stage";
      break;
    case "APPROVED":
    case "APPROVED_NOT_SELLING":
      text = "Has been issued writing # " + writingNum;
      break;
    case "APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED":
      text = "Agent has initiated intent to move away process";
      break;
    case "APPROVED_RELEASING":
      //displays as "Approved - Released"
      text =
        "Agent has been approved for immediate self-release, OR agent has initiated carrier self-release process";
      break;
    case "INTENT_MOVE_BIG":
      text =
        "Contracting process can begin once agent is eligible to move under Berwick";
      break;
    case "PENDING_WRITING":
      text =
        "Final stages, waiting for carrier to issue writing # " + writingNum;
      break;
    case "RECEIVED":
      text = "Received contract with errors, waiting on agent to correct";
      break;
    case "SENT_TO_AGENT":
      text = "Waiting for contract to be completed by agent ";
      break;
    case "SUBMITTED":
      text = "Contract has been submitted to carrier";
      break;
    case "SUBMITTED_MISSING_DOC":
      text =
        "Received a missing document notice from carrier, agent has been informed";
      break;
    case "SUBMITTED_NO_CERT":
      text =
        "Contract has been submitted to carrier, and there are no certifications required";
      break;
    case "WITHDRAWN":
      text =
        "Contract never completed due to either agent inaction or carrier request";
      break;
    case "REJECTED":
      text = "Failed background";
      break;
    case "TERMINATED":
      text = "Contract has been terminated";
      break;

    default:
      text = "not found";
  }
  return text;
}

function createIcon(contractStatus) {
  const succesState = (
    <i
      className="fa fa-check"
      style={{ color: "green ", fontSize: "1em" }}
      aria-hidden="true"
    />
  );
  const warrningState = (
    <i
      className="fas fa-exclamation-triangle"
      style={{ color: "#ff9933", fontSize: "1em" }}
      aria-hidden="true"
    />
  );
  const wrongState = (
    <i
      className="fas fa-ban"
      style={{ color: "red ", fontSize: "1em" }}
      aria-hidden="true"
    />
  );

  switch (contractStatus) {
    case "APPROVED":
    case "APPROVED_NOT_SELLING":
      return succesState;
    case "APPROVED_RELEASING":
    case "AGENT_CERT":
    case "APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED":
    case "INTENT_MOVE_BIG":
    case "PENDING_WRITING":
    case "RECEIVED":
    case "SENT_TO_AGENT":
    case "SUBMITTED":
    case "SUBMITTED_MISSING_DOC":
    case "SUBMITTED_NO_CERT":
      return warrningState;

    case "WITHDRAWN":
    case "REJECTED":
    case "TERMINATED":
      return wrongState;

    default:
      return warrningState;
  }
}

function statusParse(contractStatus) {
  let text;
  switch (contractStatus) {
    case "AGENT_CERT":
      text = "Agent Cert";
      break;
    case "APPROVED":
    case "APPROVED_NOT_SELLING":
      text = "Approved";
      break;
    case "APPROVED_RELEASING":
      text = "Approved - Released";
      break;
    case "APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED":
      text = "Approved - Intent to Move Away Submitted";
      break;
    case "INTENT_MOVE_BIG":
      text = "Intent move big";
      break;
    case "PENDING_WRITING":
      text = "Pending writing";
      break;
    case "RECEIVED":
      text = "Received";
      break;
    case "SENT_TO_AGENT":
      text = "Sent to agent";
      break;
    case "SUBMITTED":
      text = "Submitted";
      break;
    case "SUBMITTED_MISSING_DOC":
      text = "Submitted missing doc";
      break;
    case "SUBMITTED_NO_CERT":
      text = "Submitted no cert";
      break;
    case "WITHDRAWN":
      text = "Withdrawn";
      break;
    case "REJECTED":
      text = "Rejected";
      break;
    case "TERMINATED":
      text = "Terminated";
      break;

    default:
      text = "not found";
  }
  return text;
}

function statusParseReverse(contractStatus) {
  let text;
  switch (contractStatus) {
    case "Agent Cert":
      text = "AGENT_CERT";
      break;
    case "Approved":
      text = "APPROVED";
      break;
    case "Approved not Selling":
      text = "APPROVED_NOT_SELLING";
      break;
    case "Approved - Intent to Move Away Submitted":
      text = "APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED";
      break;
    case "Approved - Released":
      text = "APPROVED_RELEASING";
      break;
    case "Intent move big":
      text = "INTENT_MOVE_BIG";
      break;
    case "Pending writing":
      text = "PENDING_WRITING";
      break;
    case "Received":
      text = "RECEIVED";
      break;
    case "Sent to agent":
      text = "SENT_TO_AGENT";
      break;
    case "Submitted":
      text = "SUBMITTED";
      break;
    case "Submitted missing doc":
      text = "SUBMITTED_MISSING_DOC";
      break;
    case "Submitted no cert":
      text = "SUBMITTED_NO_CERT";
      break;
    case "Withdrawn":
      text = "WITHDRAWN";
      break;
    case "Rejected":
      text = "REJECTED";
      break;
    case "Terminated":
      text = "TERMINATED";
      break;

    default:
      text = "not found";
  }
  return text;
}

function formateStatsDate(contract) {
  if (contract.contractStatus === "APPROVED") {
    return contract.effective_date
      ? moment(contract.effective_date).format("MM/DD/YYYY")
      : "Date Unknown";
  } else if (contract.contractStatus === "SENT_TO_AGENT") {
    return contract.sent_date
      ? moment(contract.sent_date).format("MM/DD/YYYY")
      : "Date Unknown";
  } else {
    return contract.status_date
      ? moment(contract.status_date).format("MM/DD/YYYY")
      : "Date Unknown";
  }
}

export default agentParse;
