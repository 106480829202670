import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import DashboardTitle from "../DashboardTitle";
import {
  AppBar,
  Toolbar,
  ButtonGroup,
  IconButton,
  Button,
  Badge,
  Grid,
  Typography,
} from "@mui/material";
import { clearStore as clearStoreAction } from "../../store/actions/index";
import { setCriteria } from "../../store/actions/production/productionActions";
import LinkButtonWithTooltip from "./LinkButtonWithTooltip";
import history from "../../utils/history";
import { persistor } from "../../store";
import {
  Refresh,
  NotificationImportant,
  NotificationsNone,
  Group,
  Business,
  Dashboard,
  HowToVoteOutlined,
  Assessment,
  AlternateEmailOutlined,
  Share,
  GroupAdd,
} from "@mui/icons-material";
import ContractRequestModal from "../common/ContractRequestModal";
import client from "../../services/GraphQl/client";
import HtmlToolTip from "./HtmlTooltip";
import { setContractRequests } from "../../store/actions/contract-requests/contractRequests";
import {
  crSubscribe,
  crUnsubscribe,
  ocrSubscribe,
  ocrUnsubscribe,
} from "../../services/GraphQl/BDSWebService";
import MobileMenu from "./MobileMenu";
import ShareAway from "./ShareAway";

const theme = createTheme({
  palette: {
    primary: { main: "#444242" },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "HelveticaNeueLTStd",
      "Helvetica Neue LT Std",
      "HelveticaNeue-Light",
      "Helvetica Neue Light",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "Lucida Grande",
      "sans-serif",
    ].join(","),
  },
});

const useStyles = makeStyles()((theme) => {
  return {
    home: {
      color: "#FFFFFF",
    },
    content: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    shareButton: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "inline-flex",
      },
    },
    mobileContent: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    exitButton: {
      textAlign: "end",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  };
});

const NavBar = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [openShareAway, setOpenShareAway] = useState(false);
  const [newOcrCount, setNewOcrCount] = useState(0);
  const contractRequests = useSelector(
    (currentContractRequests) => currentContractRequests.currentContractRequests
  );
  const recruiterCode = useSelector(
    (recruiterStore) =>
      recruiterStore.recruiterStore.recruiterInfo?.recruiterCode
  );

  useEffect(() => {
    dispatch(setContractRequests());
    const agencyId = sessionStorage.getItem("agencyId");
    crSubscribe(agencyId, (callback) => {
      if (callback) {
        dispatch(setContractRequests());
      }
    });
    ocrSubscribe(agencyId, (callback) => {
      if (callback) {
        const newOcrCount = localStorage.getItem("newOcrCount");
        const storedOcrCount = localStorage.getItem("storedOcrCount");
        const insertVal = parseInt(newOcrCount) + 1;
        setNewOcrCount(insertVal);
        localStorage.setItem("newOcrCount", `${insertVal}`);
        localStorage.setItem(
          "storedOcrCount",
          `${parseInt(storedOcrCount) + 1}`
        );
      }
    });
    const newOcrCount = localStorage.getItem("newOcrCount");
    setNewOcrCount(parseInt(newOcrCount));

    return () => {
      crUnsubscribe();
      ocrUnsubscribe();
    };
  }, [dispatch]);

  const navigate = (path) => {
    history.push(path);
  };

  const clearStore = () => {
    dispatch(clearStoreAction());
    dispatch(
      setCriteria({
        selectedCarrier: "",
        selectedAgency: "",
        selectedLevel: "",
        selectedPlanYear: "",
        selectedProducts: "",
        displayCounty: false,
        displayEffectiveData: false,
        displayState: false,
        displayWritingNumber: false,
      })
    );
    persistor.purge();
    sessionStorage.clear();
    client.clearStore();
    crUnsubscribe();
    ocrUnsubscribe();
    history.push("/login");
  };

  const { type, ForceRefresh } = props;

  const agencies = JSON.parse(sessionStorage.getItem("subAgenciesFor1904"));
  const agencyId = sessionStorage.getItem("agencyId");
  const checkAgencies = agencies
    ?.map((data) => data.agencyId)
    .filter((item) => item !== "1904")
    .includes(agencyId);

  const homeButton = (
    <LinkButtonWithTooltip pathName={`/`} title="Home" type="Dashboard" />
  );
  const agentsButton = (
    <LinkButtonWithTooltip
      pathName={`/AgentList`}
      title="My Agents"
      type="Group"
    />
  );
  const productionButton = (
    <LinkButtonWithTooltip
      data-tour="ad__production"
      pathName={`/Production`}
      title="Production"
      type="Business"
    />
  );
  const downlineCommunicationButton = (
    <LinkButtonWithTooltip
      pathName={`/DownlineCommunications`}
      title="Downline Communications"
      type="DownlineCommunications"
    />
  );

  const agentIdCount = [
    ...new Set(
      contractRequests?.storedContractRequests
        ?.filter((r) => r.status === "pending")
        .map(({ agentId }) => agentId)
    ),
  ].length;
  const contractRequestLength =
    contractRequests?.storedContractRequests?.filter(
      (r) => r.status === "pending"
    ).length;

  const pendingRequestsButton = (
    <Link to={{ pathname: "/PendingRequests" }}>
      <HtmlToolTip
        title={
          <span>
            <b style={{ fontSize: 14 }}>{contractRequestLength}</b> Contract
            Requests Pending From <b style={{ fontSize: 14 }}>{agentIdCount}</b>{" "}
            Different Agent(s)
          </span>
        }
      >
        <Button size="small" sx={{ margin: "4px 12px 4px 12px" }}>
          <Badge
            style={{ color: "#eee", outline: "none" }}
            badgeContent={
              contractRequestLength > 0 && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    background: "#0094ff",
                    color: "whitesmoke",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                >
                  {contractRequestLength}
                </Grid>
              )
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {contractRequestLength > 0 ? (
              <NotificationImportant
                fontSize="large"
                className={classes.notificationIcon}
              />
            ) : (
              <NotificationsNone
                fontSize="large"
                className={classes.notificationIcon}
              />
            )}
          </Badge>
        </Button>
      </HtmlToolTip>
    </Link>
  );

  const clrButton = (
    <LinkButtonWithTooltip
      pathName="/CommissionLossReport"
      title="Commission Loss Report"
      type="Report"
    />
  );

  const leadsButton = (
    <Link to={{ pathname: "/Leads" }}>
      <HtmlToolTip title="My Leads">
        <Button size="small" sx={{ margin: "4px 10px 4px 10px" }}>
          <Badge
            style={{ color: "#eee", outline: "none" }}
            badgeContent={
              newOcrCount > 0 && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    background: "#0094ff",
                    color: "whitesmoke",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                >
                  {newOcrCount}
                </Grid>
              )
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <GroupAdd fontSize="large" />
          </Badge>
        </Button>
      </HtmlToolTip>
    </Link>
  );

  const contractRequestButton = (
    <LinkButtonWithTooltip
      onClick={() => (checkAgencies ? void 0 : setContractModalOpen(true))}
      title={
        checkAgencies ? (
          "For contract requests, please contact your upline."
        ) : (
          <Typography>
            Make an Agent Contract Request
            <br></br>
            <br></br>
            Questions? We are here to help! Call our agent support team at
            1-888-745-2320
          </Typography>
        )
      }
      type="ContractRequest"
    />
  );
  const exitButton = (
    <LinkButtonWithTooltip
      onClick={clearStore}
      pathname="/login"
      title="Logout"
      type="Logout"
    />
  );
  const recruiterShare = (
    <HtmlToolTip title="Onboard For Your Agency">
      <IconButton
        className={classes.shareButton}
        style={{ color: "white", outline: "none" }}
        onClick={() => setOpenShareAway(true)}
        size="small"
      >
        <Share fontSize="large" />
      </IconButton>
    </HtmlToolTip>
  );
  const refreshButton = (
    <IconButton
      sx={{ color: "white", outline: "none", margin: "2px 0px 2px 0px" }}
      onClick={ForceRefresh}
      size="small"
    >
      <Refresh fontSize="large" data-tour="ad__appbar_refresh" />
    </IconButton>
  );

  const shouldShowLeads = recruiterCode;
  const navButtons = shouldShowLeads
    ? [
        homeButton,
        agentsButton,
        leadsButton,
        productionButton,
        contractRequestButton,
        clrButton,
        downlineCommunicationButton,
        pendingRequestsButton,
        refreshButton,
      ]
    : [
        homeButton,
        agentsButton,
        productionButton,
        contractRequestButton,
        clrButton,
        downlineCommunicationButton,
        pendingRequestsButton,
        refreshButton,
      ];

  switch (type) {
    case "home":
      navButtons.splice(0, 1);
      break;
    case "agents":
      navButtons.splice(1, 1);
      break;
    case "leads":
      navButtons.splice(2, 1);
      break;
    case "production":
      navButtons.splice(shouldShowLeads ? 3 : 2, 1);
      break;
    case "clr":
      navButtons.splice(shouldShowLeads ? 5 : 4, 1);
      break;
    case "communications":
      navButtons.splice(shouldShowLeads ? 6 : 5, 1);
      break;
    case "requests":
      navButtons.splice(shouldShowLeads ? 7 : 6, 1);
      break;
    default:
      break;
  }

  const recruiterMobileItem = {
    label: "Onboard For Your Agency",
    icon: <Share />,
    clickFunc: (e) => setOpenShareAway(true),
  };

  const leadsMobileItem = {
    label: "My Leads",
    icon: <GroupAdd />,
    clickFunc: () => navigate("/Leads"),
  };

  const mobileMenuItems = [
    {
      label: "Home",
      icon: <Dashboard />,
      clickFunc: () => navigate("/"),
    },
    {
      label: "My Agents",
      icon: <Group />,
      clickFunc: () => navigate("/AgentList"),
    },
    {
      label: "Production",
      icon: <Business />,
      clickFunc: () => navigate("/Production"),
    },
    {
      label: "Contract Request",
      icon: <HowToVoteOutlined />,
      clickFunc: () => (checkAgencies ? void 0 : setContractModalOpen(true)),
      disabled: checkAgencies,
    },
    {
      label: "Commission Loss Report",
      icon: <Assessment />,
      clickFunc: () => navigate("/CommissionLossReport"),
    },
    {
      label: "Downline Communications",
      icon: <AlternateEmailOutlined />,
      clickFunc: () => navigate("/DownlineCommunications"),
    },
    {
      label: "Pending Requests",
      icon: (
        <Badge
          style={{ outline: "none" }}
          badgeContent={
            contractRequestLength > 0 && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  background: "#0094ff",
                  color: "whitesmoke",
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                }}
              >
                {contractRequestLength}
              </Grid>
            )
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {contractRequestLength > 0 ? (
            <NotificationImportant />
          ) : (
            <NotificationsNone />
          )}
        </Badge>
      ),
      clickFunc: () => navigate("/PendingRequests"),
    },
    {
      label: "Refresh",
      icon: <Refresh />,
      clickFunc: (e) => ForceRefresh(e),
    },
  ];
  // Splice in share button to show if recruiterCode exists
  // Add in at second to last position
  const spliceMobileItems = [...mobileMenuItems];
  spliceMobileItems.splice(mobileMenuItems.length - 1, 0, recruiterMobileItem);
  spliceMobileItems.splice(2, 0, leadsMobileItem);
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar data-tour="ad__appbar" position="fixed" color="primary">
          <Toolbar style={{ width: "100%" }} disableGutters>
            <div className={classes.content}>
              <ButtonGroup size="small">
                {navButtons.map((button, i) => (
                  <span key={i}>{button}</span>
                ))}
              </ButtonGroup>
            </div>
            <div className={classes.mobileContent}>
              <MobileMenu
                mobileMenuItems={
                  shouldShowLeads ? spliceMobileItems : mobileMenuItems
                }
              />
            </div>
            <DashboardTitle />
            <Grid item xs={2} md={4} className={classes.exitButton}>
              {shouldShowLeads && recruiterShare}
              {exitButton}
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <ContractRequestModal
        contractModalOpen={contractModalOpen}
        handleClose={() => {
          setContractModalOpen(false);
        }}
      />
      <ShareAway
        openShareAway={openShareAway}
        closeModal={() => setOpenShareAway(false)}
      />
    </>
  );
};

export default NavBar;
