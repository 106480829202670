import {
  CLEAR_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_ERROR,
} from "../../constants/action-types";

const initState = {
  NotificationsError: undefined,
  storedNotifications: [],
};

export const currentNotifications = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATIONS:
      return { ...state, storedNotifications: payload };
    case CLEAR_NOTIFICATIONS:
      return payload;
    case SET_NOTIFICATIONS_ERROR:
      return { ...state, NotificationsError: payload };
    default:
      return state;
  }
};
