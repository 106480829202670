import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Card, CardContent } from "@mui/material";
import {
  Grid,
  Paper,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
} from "@mui/material/";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { setCurrentProduction } from "../../store/actions/production/CurrentProduction";

const styles = (theme) => {
  const titleRoot = {
    fontSize: "2em",
    color: "#003760",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  const tableTextRoot = {
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  return {
    titleRoot,
    tableTextRoot,
    card: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 0,
      margin: 0,
    },
    title: {
      ...titleRoot,
      fontSize: "1em",
    },
    titleInSingleView: {
      ...titleRoot,
    },
    table: {
      padding: 0,
      width: "100%",
    },
    tableText: {
      ...tableTextRoot,
      fontSize: "0.8em",
    },
    tableTextInSingleView: {
      ...tableTextRoot,
    },
    tabGroupContainer: {
      paddingBottom: 10,
    },
    tabGroup: {
      backgroundColor: "white",
      border: 1,
      borderColor: "black",
    },
    tab: {
      fontSize: 12,
      border: 2,
      borderColor: "black",
    },
  };
};

class ConnectedBreakoutProductionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: props.currentProduction.currentYear,
    };
  }
  yearSelectionChange = (event, value) => {
    /* if this is historical, load the data */
    if (!this.props.currentProduction.production[value]) {
      const agencyId = sessionStorage.getItem("agencyId");
      const agentId = sessionStorage.getItem("agentId");
      this.props.setCurrentProduction(agencyId, agentId, value);
    }
    this.setState({ selectedYear: value });
  };

  render() {
    const { classes, isSingleView, agent, currentProduction } = this.props;
    const { selectedYear } = this.state;
    var HasProduction = sessionStorage.getItem("HasProduction");
    let isLoading = currentProduction.isLoading;
    if (isLoading === undefined) {
      isLoading = false;
    }
    var currentYearProduction = [];
    if (!isLoading && !HasProduction) {
      if (currentProduction.production[selectedYear]) {
        currentYearProduction = currentProduction.production[selectedYear].data;
      }
    }
    var maGrandTotal = 0;
    var msGrandTotal = 0;
    var pdpGrandTotal = 0;
    const rows = [];
    currentYearProduction.forEach((carrierProd) => {
      const agentCarrierProd = carrierProd.result.filter(
        (row) => row.agent_id === agent.agentId
      );
      /* build row for each carrier */
      /* sum all agent rows for each product */
      var maSum = 0;
      var msSum = 0;
      var pdpSum = 0;
      if (agentCarrierProd.length > 0) {
        agentCarrierProd.map((prod) => (maSum += prod.ma_production));
        agentCarrierProd.map((prod) => (msSum += prod.medsupp_production));
        agentCarrierProd.map((prod) => (pdpSum += prod.pdp_production));
      }
      rows.push(
        <TableRow>
          <TableCell
            className={classes.tableTextClasses}
            component="th"
            scope="row"
          >
            {carrierProd.name}
          </TableCell>
          <TableCell
            className={classes.tableTextClasses}
            component="th"
            scope="row"
          >
            {maSum}
          </TableCell>
          <TableCell
            className={classes.tableTextClasses}
            component="th"
            scope="row"
          >
            {msSum}
          </TableCell>
          <TableCell
            className={classes.tableTextClasses}
            component="th"
            scope="row"
          >
            {pdpSum}
          </TableCell>
        </TableRow>
      );
      /* build grand totals of all carriers */
      if (agentCarrierProd.length > 0) {
        maGrandTotal += maSum;
        msGrandTotal += msSum;
        pdpGrandTotal += pdpSum;
      }
    });

    const titleClasses = isSingleView
      ? classes.titleInSingleView
      : classes.title;
    const tableTextclasses = isSingleView
      ? classes.tableTextInSingleView
      : classes.tableText;

    return (
      <LoadingOverlay
        active={isLoading}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
          }),
        }}
        spinner //={<RingLoader color={"white"} />}
        text="Retrieving Historical Data..."
      >
        <Card className={classes.card} elevation={3}>
          <CardContent>
            <Grid container className={classes.tabGroupContainer}>
              <Paper square elevation={0} className={classes.tabGroup}>
                <Tabs
                  onChange={this.yearSelectionChange}
                  value={selectedYear}
                  style={{ borderColor: "black" }}
                >
                  {currentProduction.availablePlanYears.map((year) => (
                    <Tab
                      disabled={HasProduction}
                      value={year}
                      className={classes.tab}
                      label={year}
                    />
                  ))}
                </Tabs>
              </Paper>
            </Grid>
            <Typography
              className={titleClasses}
              color="textPrimary"
              gutterBottom
            >
              Agent Production Summary
            </Typography>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={tableTextclasses}>Carrier</TableCell>
                  <TableCell className={tableTextclasses}>MA</TableCell>
                  <TableCell className={tableTextclasses}>Med Supp</TableCell>
                  <TableCell className={tableTextclasses}>PDP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows}
                <TableRow
                  style={{
                    borderTop: "solid",
                    borderTopColor: "black",
                    borderTopWidth: "3px",
                  }}
                >
                  <TableCell style={{ fontWeight: "bold" }}>Totals</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {maGrandTotal}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {msGrandTotal}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {pdpGrandTotal}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProduction: state.currentProduction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentProduction: (agencyId, agentId, year) =>
      dispatch(setCurrentProduction(agencyId, agentId, year)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConnectedBreakoutProductionCard));
