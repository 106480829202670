import { CLEAR_AGENT_LIST, SET_AGENT_LIST } from "../../constants/action-types";
import {
  agencyAgentUserInfo,
  getAgentsForDashboardGql,
} from "../../../services/GraphQl/BDSWebService";

/* redux-thunk implementation */
export const fetchAgentList = (agencyId, offset, limit, callback) => {
  return async (dispatch) => {
    await getAgentsForDashboardGql(agencyId, offset, limit)
      .then(async (res) => {
        if (res) {
          const userInfoAllAgents = await agencyAgentUserInfo(
            res.map(({ agentId }) => agentId)
          );
          const addAgentInfoToRes = res.map((row) => {
            const userInfo = userInfoAllAgents?.find(
              ({ id }) => id === row.agentId
            );
            return { ...row, userInfo };
          });
          dispatch(setAgentList(addAgentInfoToRes));
        }
        return res;
      })
      .then((res) => {
        if (callback) callback(res);
      });
  };
};

export const clearAgentList = () => {
  return (dispatch) => {
    dispatch(clearAgentListAction([]));
  };
};

/* redux action */
const setAgentList = (data) => {
  return {
    type: SET_AGENT_LIST,
    payload: data,
  };
};

/* redux action */
const clearAgentListAction = (data) => {
  return {
    type: CLEAR_AGENT_LIST,
    payload: data,
  };
};
