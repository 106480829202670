import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid } from "@mui/material";
import ContractItem2 from "./ContractItem2";

const styles = (theme) => ({
  paper: {
    backgroundColor: "#707070",
    padding: 8,
    boxShadow: "3px 3px 0.5px #555 inset",
  },
  contractHeader: {
    minHeight: "10em",
    marginBottom: "5px",
  },
});

class ContractArea extends Component {
  render() {
    const { contract, isSingleView } = this.props;

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{
          width: "100%",
          height: "fit-content",
        }}
      >
        <Grid item xs={12}>
          <ContractItem2
            contracts={contract.contracts}
            isSingleView={isSingleView}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ContractArea);
