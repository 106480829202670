import React from "react";
import withStyles from "@mui/styles/withStyles";
import SelectorWithState from "./common/SelectorWithState";
import { Grid, Paper, Typography } from "@mui/material";

const styles = theme => ({
  inputContainer: {
    backgroundColor: "currentColor",
    color: "#eee",
    fontWeight: "bold"
  },
  selectorInput: {},
  typoGraphy: {
    color: "#003760",
    fontSize: "calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)))",
    lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    fontWeight: "bold"
  }
});

const RenderSelectorWithState = props => {
  const { classes, label, data, searchHandler, options } = props;
  return (
    <Paper
      square={true}
      style={{ marginTop: "15px" }}
      elevation={5}
      className={classes.inputContainer}
    >
      <Grid
        container
        className={classes.selectorInput}
        spacing={0}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid xs={4} item>
          <Typography className={classes.typoGraphy}>{label}</Typography>
        </Grid>
        <Grid xs={8} item>
          <SelectorWithState
            options={options}
            label={label}
            data={data}
            searchHandler={searchHandler}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(RenderSelectorWithState);