import React from "react";
import withStyles from "@mui/styles/withStyles";
import stringUtls from "../../utils/stringUtils";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import {
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import AutoComplete from "@mui/material/Autocomplete";
import { connect } from "react-redux";
import {
  getStatesMethod,
  getAgentsMethod,
} from "../../store/actions/dashboard/contractForm";
import moment from "moment";

const styles = (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "#3f51b5",
    },
    "& .MuiInputLabel-root": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-root": {
      color: "#3f51b5",
    },
    "& .MuiInputBase-root": {
      color: "#3f51b5",
      backgroundColor: "#eee",
    },
    "& .Mui-error": {
      color: "black",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  overlay: {
    height: "100%",
  },
});

class AgentPanel extends React.Component {
  numbersOnly = (e, fieldLength = 10) => {
    let numericValue = e.target.value.replace(/\D+/g, "");
    e.target.value = numericValue.toString().slice(0, fieldLength);
  };

  render() {
    const {
      handleAddAgent,
      classes,
      handleInputChange,
      agentChipIndex,
      resetAgentPanel,
      statesOptionsData,
      agentListData,
      existingAgentSelectionChange,
      existingAgent,
      agentValidationErrors,
      rowData,
      agentsLength,
    } = this.props;
    const corpfieldsShown = existingAgent.ContractingAsCorp || false;
    let agentListArray = [];

    if (
      !statesOptionsData ||
      statesOptionsData.length === 0 ||
      statesOptionsData == null ||
      statesOptionsData === undefined
    ) {
      this.props.setStates();
    }

    if (
      !agentListData ||
      agentListData.length === 0 ||
      agentListData == null ||
      agentListData === undefined
    ) {
      this.props.setAgentList();
    } else {
      // use data to create view
      agentListArray = agentListData.map((agentData) => ({
        key:
          stringUtls.firstUpperLetter(agentData.lastname.toLowerCase()) +
          ", " +
          stringUtls.firstUpperLetter(agentData.firstname.toLowerCase()),
        value: agentData.agent_id,
        fName: stringUtls.firstUpperLetter(agentData.firstname.toLowerCase()),
        lName: stringUtls.firstUpperLetter(agentData.lastname.toLowerCase()),
        dob: moment(agentData.dob).format("YYYY-MM-DD"),
        npn: agentData.npn,
        phone: agentData.phone,
        gender: agentData.gender,
        email: agentData.email,
        stateAbbreviation: agentData.state,
        license: agentData.license,
      }));
    }

    const disabledFromTable =
      agentChipIndex == null && agentsLength === 1 && rowData;

    return (
      <div>
        <LoadingOverlay
          active={
            statesOptionsData.length === 0 ||
            statesOptionsData === null ||
            statesOptionsData === undefined ||
            agentListData.length === 0 ||
            agentListData === null ||
            agentListData === undefined
          }
          className={classes.overlay}
          styles={{
            spinner: (base) => ({
              ...base,
              width: "100px",
            }),
          }}
          spinner
          text={"Loading..."}
        >
          <Grid container alignItems="flex-end" sx={{ pt: 2 }}>
            <Grid item xs={6}>
              <Typography className={classes.heading}>
                <b>Add Agent</b>
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <AutoComplete
                disabled={rowData}
                options={agentListArray}
                getOptionLabel={(option) => option.key}
                clearOnEscape={true}
                openOnFocus
                blurOnSelect
                size="small"
                autoHighlight
                value={existingAgent?.key ? existingAgent : null}
                name="existingAgentAutocomplete"
                id="existingAgentAutocomplete"
                onChange={(event, value) => {
                  if (value !== undefined && value !== null) {
                    existingAgentSelectionChange(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Existing Agents Search"
                    variant="outlined"
                    placeholder="Search by name..."
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            // spacing={1}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              border: "solid black thin",
            }}
          >
            <Grid
              item
              xs={6}
              style={{
                padding: "0 2.5px 0 5px",
              }}
            >
              <TextField
                id="filled-fname-input"
                name="fName"
                label="First Name"
                margin="dense"
                fullWidth
                variant="filled"
                autoComplete="new-password"
                required
                classes={{ root: classes.root }}
                value={existingAgent.fName || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.fName !== undefined}
                helperText={agentValidationErrors.fName}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 5px 0 2.5px",
              }}
            >
              <TextField
                id="filled-lname-input"
                name="lName"
                label="Last Name"
                margin="dense"
                fullWidth
                variant="filled"
                autoComplete="new-password"
                required
                classes={{ root: classes.root }}
                value={existingAgent.lName || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.lName !== undefined}
                helperText={agentValidationErrors.lName}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                padding: "0 2.5px 0 5px",
              }}
            >
              <TextField
                id="filled-phone-input"
                name="phone"
                onInput={(e) => this.numbersOnly(e, 10)}
                label="Phone #"
                margin="dense"
                type="text"
                fullWidth
                variant="filled"
                autoComplete="new-password"
                required
                classes={{ root: classes.root }}
                value={existingAgent.phone || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.phone !== undefined}
                helperText={agentValidationErrors.phone}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                padding: "0 2.5px 0 5px",
              }}
            >
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="filled-gender-input-label">Gender</InputLabel>
                <Select
                  id="filled-gender-input"
                  name="gender"
                  labelId="filled-gender-input-label"
                  value={existingAgent.gender || ""}
                  onChange={handleInputChange}
                  disabled={disabledFromTable}
                >
                  <MenuItem key="1" value="FEMALE">
                    Female
                  </MenuItem>
                  <MenuItem key="2" value="MALE">
                    Male
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                padding: "0 5px 0 5px",
              }}
            >
              <FormControl
                sx={{ mt: 1 }}
                fullWidth
                error={agentValidationErrors.stateAbbreviation !== undefined}
              >
                <InputLabel id="filled-state-input-label">
                  State of residence
                </InputLabel>
                <Select
                  id="filled-state-input"
                  name="stateAbbreviation"
                  labelId="filled-state-input-label"
                  value={existingAgent.stateAbbreviation || ""}
                  onChange={handleInputChange}
                  disabled={disabledFromTable}
                >
                  {statesOptionsData.map((statesData) => (
                    <MenuItem key={statesData.code} value={statesData.code}>
                      {statesData.name}
                    </MenuItem>
                  ))}
                </Select>
                {agentValidationErrors.stateAbbreviation !== undefined && (
                  <FormHelperText>
                    {agentValidationErrors.stateAbbreviation}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                padding: "0 5px 0 5px",
              }}
            >
              <TextField
                id="filled-email-input"
                label="Email"
                type="email"
                name="email"
                fullWidth
                autoComplete="new-password"
                margin="dense"
                variant="filled"
                required
                classes={{ root: classes.root }}
                value={existingAgent.email || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.email !== undefined}
                helperText={agentValidationErrors.email}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 2.5px 0 5px",
              }}
            >
              <TextField
                id="filled-NPN-input"
                label="NPN"
                name="npn"
                autoComplete="new-password"
                placeholder="Not required for WelBe contracts"
                fullWidth
                margin="dense"
                variant="filled"
                onInput={(e) => this.numbersOnly(e, 15)}
                maxLength="15"
                minLength="4"
                classes={{ root: classes.root }}
                value={existingAgent.npn || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.npn !== undefined}
                helperText={agentValidationErrors.npn}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 5px 0 5px",
              }}
            >
              <TextField
                id="filled-license-input"
                label="License #"
                name="license"
                autoComplete="new-password"
                fullWidth
                placeholder={
                  !existingAgent.license
                    ? "          Required for SCAN contracts"
                    : "License Number On File"
                }
                margin="dense"
                variant="filled"
                classes={{ root: classes.root }}
                value={existingAgent.license || ""}
                onChange={handleInputChange}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 2.5px 0 5px",
              }}
            >
              <TextField
                id="filled-socialNumber-input"
                label="SSN"
                name="socialNumber"
                autoComplete="new-password"
                placeholder="            Required for Anthem contracts"
                onInput={(e) => this.numbersOnly(e, 9)}
                fullWidth
                margin="dense"
                variant="filled"
                classes={{ root: classes.root }}
                value={existingAgent.socialNumber || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.socialNumber !== undefined}
                helperText={agentValidationErrors.socialNumber}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 5px 0 5px",
              }}
            >
              <TextField
                id="filled-dob-input"
                label="DOB"
                type="date"
                name="dob"
                autoComplete="new-password"
                fullWidth
                margin="dense"
                variant="filled"
                classes={{ root: classes.root }}
                value={existingAgent.dob || ""}
                defaultValue={null}
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                disabled={disabledFromTable}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                padding: "0 5px 0 5px",
                marginLeft: 4,
                marginRight: 4,
                marginTop: 8,
                backgroundColor: "#eee",
                borderRadius: "5px 5px 0px 0px",
                color: "#3f51b5",
                borderBottom: "solid rgba(0,0,0,0.38) thin",
              }}
            >
              <Typography component="label">Contracting As Corp?</Typography>
              <Checkbox
                disabled={disabledFromTable}
                id="filled-contractingAsCorpValue-input"
                name="ContractingAsCorp"
                variant="filled"
                classes={{ root: classes.root }}
                checked={corpfieldsShown}
                onChange={handleInputChange}
                color="primary"
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 2.5px 0 5px",
              }}
              hidden={!corpfieldsShown}
            >
              <TextField
                id="filled-corpName-input"
                label="Corp Name"
                //type="corpName"
                name="corpName"
                autoComplete="new-password"
                fullWidth
                margin="dense"
                variant="filled"
                required={corpfieldsShown}
                classes={{ root: classes.root }}
                value={existingAgent.corpName || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.corpName !== undefined}
                helperText={agentValidationErrors.corpName}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: "0 2.5px 0 5px",
              }}
              hidden={!corpfieldsShown}
            >
              <TextField
                id="filled-corpNPN-input"
                label="Corp NPN"
                name="corpNPN"
                autoComplete="new-password"
                fullWidth
                margin="dense"
                variant="filled"
                onInput={(e) => this.numbersOnly(e, 15)}
                required={corpfieldsShown}
                classes={{ root: classes.root }}
                value={existingAgent.corpNPN || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.corpNPN !== undefined}
                helperText={agentValidationErrors.corpNPN}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                padding: "0 2.5px 0 5px",
              }}
              hidden={!corpfieldsShown}
            >
              <TextField
                id="filled-TIN-input"
                label="TIN"
                name="corpTIN"
                placeholder="TIN required for Anthem Corp Contracts"
                autoComplete="new-password"
                fullWidth
                margin="dense"
                variant="filled"
                onInput={(e) => this.numbersOnly(e, 12)}
                classes={{ root: classes.root }}
                value={existingAgent.corpTIN || ""}
                onChange={handleInputChange}
                error={agentValidationErrors.corpTIN !== undefined}
                helperText={agentValidationErrors.corpTIN}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                THE AGENCY MUST HAVE AN INSURANCE LICENSE IN ALL STATES FOR
                REQUESTED CONTRACTS
              </Typography>
              <p
                style={{
                  color: "red",
                  fontStyle: "italic",
                  fontSize: "smaller",
                  fontWeight: "bold",
                }}
              >
                NOTE: License # REQUIRED to process SCAN contracts.
              </p>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div className={classes.root}>
                <Button
                  onClick={handleAddAgent}
                  style={{
                    color: "#eee",
                    backgroundColor: disabledFromTable ? "grey" : "#3f51b5",
                  }}
                  classes={{ root: classes.button }}
                  disabled={disabledFromTable}
                >
                  {agentChipIndex == null ? "Add Agent" : "Update Agent"}
                </Button>
                {existingAgent.key != null ? (
                  <span>
                    <Button
                      onClick={resetAgentPanel}
                      style={{ backgroundColor: "#fff176" }}
                      classes={{ root: classes.button }}
                      disabled={rowData}
                    >
                      Reset Agent Form
                    </Button>
                  </span>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStates: () => dispatch(getStatesMethod()),
    setAgentList: () =>
      dispatch(getAgentsMethod(sessionStorage.getItem("agencyId"), 0, 10000)),
  };
};

const mapStateToProps = (state) => {
  return {
    statesOptionsData: state.statesOptionsData,
    agentListData: state.agentListData,
    isFetchingAgentList: state.isFetchingAgentList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentPanel));
