import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import { getCLR } from "../../services/GraphQl/BDSWebService";
import classes from "./CommissionLossReports.module.css";
import {
  DataGridPro,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import moment from "moment";

const CommissionLossReports = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    const fetchData = async () => {
      if (agencyId !== null) {
        const results = await getCLR(parseInt(agencyId));
        const formattedData = results.data.getCLR.map((row) => {
          row.receivedDate = moment(row.receivedDate).format("MMMM YYYY");
          row.effectiveDate = new Date(Number(row.effectiveDate));
          return row;
        });
        setData(formattedData);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  /// Columns for the DataGrid
  /// The field property is the name of the property in the data object.
  /// The headerName property is the name of the column header.
  /// The width property is the width of the column.
  /// The headerAlign property is the alignment of the column header.
  /// The align property is the alignment of the column cells.
  /// The headerClassName property is the CSS class name for the column header.
  /// The type property is the type of column.
  /// The valueOptions property is the list of options for the column.
  const columns = [
    {
      field: "carrierName",
      headerName: "Carrier",
      width: 75,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      valueOptions: ["UHC", "Aetna", "Humana"],
    },
    {
      field: "agentName",
      headerName: "Agent Name",
      width: 225,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "writingNumber",
      headerName: "Writing Number",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "number",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "upline",
      headerName: "Parent Upline",
      width: 350,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "memberName",
      headerName: "Member",
      width: 225,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "date",
      valueFormatter: (params) => {
        return moment(params.value).format("L");
      },
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "dropDate",
      headerName: "Loss Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "planProduct",
      headerName: "Plan Product",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "policy",
      headerName: "Policy Number",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "hicn",
      headerName: "HICN",
      width: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },    
  ];

  /// The uniqueRows array is used to store the unique rows.
  /// The dataRows array is used to store the data rows.
  const uniqueRows = [];
  const dataRows = data.map((row) => {
    return {
      id: row.policy + row.writingNumber + row.planProduct + row.carrierName,
      agentName: row.agentName,
      writingNumber: row.writingNumber,
      upline: row.upline,
      memberName: row.memberName,
      effectiveDate: row.effectiveDate,
      dropDate: row.dropDate,
      planProduct: row.planProduct,
      policy: row.policy,
      hicn: row.hicn,
      carrierName: row.carrierName,
    };
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const ODD_OPACITY = 0.2;

  /// StripedDataGrid is a styled version of DataGridPro
  /// The getRowClassName property is used to apply the even and odd CSS classes to the rows.
  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const sxBox = {
    height: 640,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
        <Box sx={sxBox}>
          <StripedDataGrid
            slots={{ toolbar: CustomToolbar }}
            loading={isLoading}
            rows={uniqueRows.sort((a, b) =>
              a.agentName.localeCompare(b.agentName)
            )}
            columns={columns}
            pagination={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </Box>
    </div>
  );
};

export default CommissionLossReports;
