import { combineReducers } from "redux";
import { productionReducer } from "./production/productionReducer";
import { currentProduction } from "./production/CurrentProduction";
import { currentNotifications } from "./notifications/notifications";
import { contracts } from "./contracts";
import { agentListAgencies } from "./agents/agencies";
import {
  contractFormData,
  statesOptionsData,
  agentListData,
} from "./dashboard/ContractForm";
import { planChange } from "./plan-change/planChangeReducer";
import { planChangeCarriers } from "./plan-change/planChangeCarriersReducer";
import { currentContractRequests } from "./contract-requests/contractRequests";
import { agents } from "./agents/agents";
import { recruiterStore } from "./recruiter-info/recruiterInfo";
import {
  CLEAR_ALL,
  GET_ALL,
  GET_ALL_ERROR,
  AGENT_LIST_LOADING,
} from "../constants/action-types";

const appReducer = combineReducers({
  agents,
  contracts,
  currentProduction,
  production: productionReducer,
  agentListAgencies,
  contractFormData,
  statesOptionsData,
  agentListData,
  planChange,
  planChangeCarriers,
  currentNotifications,
  currentContractRequests,
  recruiterStore,
});

const initState = appReducer({}, {});
const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
      state = initState;
      break;
    case GET_ALL:
      state = { ...state, ...action.payload };
      break;
    case GET_ALL_ERROR:
      state = { ...state, FetchingAgentsError: action.payload };
      break;
    case AGENT_LIST_LOADING:
      state = { ...state, isFetchingAgentList: action.payload };
      break;
    default:
      break;
  }
  return appReducer(state, action);
};

export default rootReducer;
