import { useState } from "react";
import PageContainerWithMenu from "../../containers/PageContainerWithMenu";
import PlainMenu from "../PlainMenu";
import { Grid, Switch } from "@mui/material";
import ContractRequests from "./ContractRequests";
import { makeStyles } from "tss-react/mui";

const ContractRequestsInfo = () => {
  const [pendingContractsToggle, setPendingContractsToggle] = useState(true);
  const useStyles = makeStyles()((theme) => {
    return {
      switchContainer: {
        color: "rgb(0, 55, 96)",
        backgroundColor: "white",
        padding: "0px 12px",
        borderRadius: 5,
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        marginRight: 0,
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "rgb(0, 55, 96)",
          opacity: 0.75,
        },
        "& .MuiSwitch-colorSecondary": {
          color: "rgb(0, 55, 96)",
        },
        "& .MuiSwitch-input": { display: "flex" },
      },
    };
  });
  const { classes } = useStyles();
  return (
    <PageContainerWithMenu
      data-tour="ad__home"
      type="requests"
      HasMenu={true}
      menu={
        <PlainMenu
          title="Contract Requests"
          submenu={
            <div style={{ margin: "0 10px 0 10px" }}>
              <Grid
                container
                alignItems="center"
                className={classes.switchContainer}
              >
                <Grid item>Pending Contracts</Grid>
                <Switch
                  checked={!pendingContractsToggle}
                  onChange={() =>
                    setPendingContractsToggle(!pendingContractsToggle)
                  }
                />
                <Grid item>Submitted Contracts</Grid>
              </Grid>
            </div>
          }
        />
      }
      content={
        <ContractRequests pendingContractsToggle={pendingContractsToggle} />
      }
    />
  );
};
export default ContractRequestsInfo;
