import React, { useState } from "react";
import { Divider, Grid, Link, Typography, Button } from "@mui/material/";
import withStyles from "@mui/styles/withStyles";
import StringUtils from "../../../src/utils/stringUtils";
import AgentHierarchyModal from "./AgentHierarchyModal";

const styles = (theme) => ({
  gridItem: { height: "fit-content" },
  agentName: {
    color: "white",
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: {
      //xs, extra-small: 0px or larger
      fontSize: "calc(1vw + 1.5vh)",
    },
    [theme.breakpoints.up("sm")]: {
      //sm, small: 600px or larger
      fontSize: "calc(1vw + 1vh)",
    },
  },
  agentDetail: {
    color: "white",
    [theme.breakpoints.up("xs")]: {
      //xs, extra-small: 0px or larger
      fontSize: "calc(0.5vw + 0.7vh)",
    },
    [theme.breakpoints.up("sm")]: {
      //sm, small: 600px or larger
      fontSize: "calc(0.5vw + 0.7vh)",
    },
  },
  agentHoverDetail: {
    "&:hover": {
      color: "yellow",
      textDecoration: "underline",
    },
    cursor: "pointer",
  },
  divider: {
    borderLeft: "2px solid white",
    height: 15,
    marginLeft: 10,
    marginRight: 10,
  },
});

const AgentTileTitle = (props) => {
  const { agent, contract, classes, isSingleView, onAgentClick } = props;
  const [uplineArr, setUplineArr] = useState([]);
  const [openAH, setOpenAH] = useState(false);
  const compName = contract.is_corporation
    ? " - (" + StringUtils.firstUpperLetter(contract.companyName) + ")"
    : null;
  const icon = isSingleView ? "compress" : "expand";
  const toggleAH = () => {
    setOpenAH(!openAH);
  };
  return (
    <Grid container dir="row" justifyContent="space-between">
      <Grid item xs style={{ padding: 5 }}>
        <Grid container alignItems="center">
          <Typography component="body1" className={classes.agentName}>
            {agent.firstname} {agent.lastname} {compName}
          </Typography>
          <Divider item className={classes.divider} />
          <Link
            className={[classes.agentDetail, classes.agentHoverDetail].join(
              " "
            )}
            href={"mailto:" + agent.email}
          >
            {agent.email}
          </Link>
          <Divider className={classes.divider} />
          <Link
            className={[classes.agentDetail, classes.agentHoverDetail].join(
              " "
            )}
            href={"tel:" + agent.phone}
          >
            {agent.phone}
          </Link>
        </Grid>
        <Typography className={classes.agentDetail}>
          {agent.address ? agent.address + " " : ""}
          {agent.city === ""
            ? agent.state
              ? agent.state
              : "" + (agent.zip ? " " + agent.zip : "")
            : agent.city +
              ", " +
              (agent.state ? agent.state : "") +
              (agent.zip ? " " + agent.zip : "")}
        </Typography>
      </Grid>
      {!agent.userInfo?.loginDate && (
        <Grid item style={{ marginRight: 30 }}>
          <Button
            variant="text"
            size="small"
            style={{ outline: "none", color: "yellow" }}
          >
            ***Not Registered With Agent Way***
          </Button>
        </Grid>
      )}
      <Grid item style={{ marginRight: 10 }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ outline: "none" }}
          onClick={toggleAH}
        >
          Display ALL uplines
        </Button>
        <AgentHierarchyModal
          currentAgentId={agent.agentId}
          openAH={openAH}
          toggleAH={toggleAH}
          agentName={`${agent.firstname} ${agent.lastname}`}
          uplineArr={uplineArr}
          setUplineArr={setUplineArr}
        />
      </Grid>
      <Grid item>
        <i
          onClick={onAgentClick(agent)}
          className={`fa fa-${icon} fa-2x`}
          style={{
            color: "white",
            cursor: "pointer",
            marginRight: "15px",
            alignSelf: "center",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AgentTileTitle);
