import { colors } from "@mui/material/";
import { createTheme,} from "@mui/material/styles";


  var AppTheme = createTheme({
    typography: {
      fontFamily: [
        "HelveticaNeueLTStd", "Helvetica Neue LT Std", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "sans-serif"
      ].join(","),
    },

    Paper: {

    },

    palette: {
      primary1Color: colors.grey100,
      primary2Color: colors.indigo700,
      primary3Color: colors.indigo100,
      accent1Color: colors.lightGreenA200,
      accent2Color: colors.lightGreenA400,
      accent3Color: colors.lightGreenA100,
      textColor: colors.fullWhite,
      canvasColor: "#303030"
    },
    overrides: {}
  });
  export default AppTheme;

// AppTheme.paper.backgroundColor = "#303030";
// AppTheme.menu.backgroundColor = "#303030"; // doesn't do anything
// AppTheme.dropDownMenu.backgroundColor = "#303030"; // doesn't do anything
//AppTheme.menu.containerBackgroundColor = "#303030";
