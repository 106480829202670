import { contractRequestsAgency } from "../../../services/GraphQl/BDSWebService";
import {
  CLEAR_CONTRACT_REQUESTS,
  SET_CONTRACT_REQUESTS,
  SET_CONTRACT_REQUESTS_ERROR,
  SET_CONTRACT_REQUESTS_LOADING,
} from "../../constants/action-types";

export const setContractRequests = () => {
  return (dispatch) => {
    const agencyId = sessionStorage.getItem("agencyId");
    dispatch({ type: SET_CONTRACT_REQUESTS_LOADING, payload: true });
    contractRequestsAgency(agencyId)
      .then((result) => {
        dispatch({ type: SET_CONTRACT_REQUESTS, payload: result });
      })
      .catch((err) => {
        dispatch({ type: SET_CONTRACT_REQUESTS_ERROR, payload: err });
      })
      .finally(() => {
        dispatch({ type: SET_CONTRACT_REQUESTS_LOADING, payload: false });
      });
  };
};

export const clearContractRequests = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CONTRACT_REQUESTS });
  };
};
