import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import React, { useState } from "react";

const MobileMenu = (props) => {
  const { mobileMenuItems } = props;
  const [menuOpen, setMenuOpen] = useState(null);
  const openMenu = (e) => {
    setMenuOpen(e.currentTarget);
  };
  const closeMenu = () => {
    setMenuOpen(null);
  };
  return (
    <>
      <IconButton
        onClick={(e) => openMenu(e)}
        aria-controls="navbar-menu"
        aria-haspopup="true"
        style={{ color: "white", outline: "none" }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="navbar-menu"
        anchorEl={menuOpen}
        keepMounted
        open={Boolean(menuOpen)}
        onClose={closeMenu}
      >
        {mobileMenuItems.map(({ label, icon, clickFunc, disabled }, i) => {
          return (
            <MenuItem key={i} disabled={disabled} onClick={(e) => clickFunc(e)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MobileMenu;
