import React from "react";
import useStyles from "../common/useStyles";
import { Grid, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";

const CustomTourContent = ({ openedFrom, closeTour, history, message }) => {
  const styles = useStyles();
  return (
    <Grid className={styles.tourGrid} container justifyContent="center">
      <Grid item>{message}</Grid>
      <IconButton
        className={styles.tourIconBtn}
        variant="text"
        onClick={() => {
          if (openedFrom && openedFrom === "AgentList") {
            closeTour();
          } else {
            history &&
              history.push({
                pathname: "/NewFeatures",
                state: {
                  rtsTourOpen: false,
                },
              });
          }
        }}
      >
        <Close />
      </IconButton>
    </Grid>
  );
};

export default CustomTourContent;
