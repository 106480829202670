import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material/";
import ContractArea from "./ContractArea";
import AgentTileTitle from "./AgentTileTitle";
import CarrierTab from "../CarrierTab";
import AgentHierarchy3 from "./AgentHierarchy3";
import AgentListUtils from "../../utils/AgentListUtils";
import AllContractsItem from "./AllContractsItem";
import BreakoutProductionCard from "./BreakoutProductionCard";
import { certsPerAgent } from "../../services/GraphQl/BDSWebService";
import CertsTable from "../rtsFiles/CertsTable";
import { makeStyles } from "tss-react/mui";

import _ from "lodash";
import ProdPerCertRow from "../rtsFiles/ProdPerCertRow";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      [theme.breakpoints.down("sm")]: {
        height: "16em",
        overflowY: "auto",
        overflowX: "hidden",
      },
      height: "fit-content",
    },
    rootInSingleView: {
      height: "calc(80vh)",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    gridItem: {
      paddingTop: "0.5em",
      height: "fit-content",
    },
    contractArea: {
      height: "100%",
      flexGrow: 1,
    },
    contractAreaInsingleView: {
      [theme.breakpoints.down("sm")]: {
        height: "fit-content",
      },
    },
    productionBreakout: {
      paddingTop: "0.5em",
      height: "fit-content",
    },
  };
});

const AgentTile = (props) => {
  const { classes } = useStyles();
  const {
    agent,
    onAgentClick,
    isSingleView,
    showAllContracts,
    setShowAllContracts,
    classes: classesFromParent,
  } = props;

  let { agentCarriers } = props.agent;
  const newAgentCarriers = AgentListUtils.HandleFormateCarriers(agentCarriers);

  const [contract, setContract] = useState(newAgentCarriers[0]);
  const [agentCarriersState, setAgentCarriersState] =
    useState(newAgentCarriers);
  const [breakoutProduction, setBreakoutProduction] = useState(
    props.breakoutProduction
  );
  const [certsData, setCertsData] = useState([]);
  const [openCerts, setOpenCerts] = useState(props.openCerts);
  const [openProducts, setOpenProducts] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [planYears, setPlanYears] = useState([]);
  const [selectedPlanYear, setSelectedPlanYear] = useState("");
  const [certsLoading, setCertsLoading] = useState(false);

  const handleContractChange = (selectContract) => {
    if (contract !== selectContract) {
      setContract(selectContract);
    }
  };

  useEffect(() => {
    if (
      !_.isEqual(contract, newAgentCarriers[0]) ||
      !_.isEqual(agentCarriersState, newAgentCarriers)
    ) {
      setContract(newAgentCarriers[0]);
      setAgentCarriersState(newAgentCarriers);
    }
  }, [agentCarriers, contract, newAgentCarriers, agentCarriersState]);

  const handleBreakoutProductionChange = async () => {
    if (!breakoutProduction) {
      props.setShowAllContracts(props.agent, false);
    }
    setBreakoutProduction(!breakoutProduction);
    setOpenCerts(false);
    setOpenProducts(false);
  };

  const handleShowAllContractsChange = (agent) => {
    if (!props.showAllContracts) {
      setBreakoutProduction(false);
      setOpenCerts(false);
      setOpenProducts(false);
    }
    props.handleShowAllContractsChange(agent);
  };

  const showContracts = (classes, contract, isSingleView) => (
    <Grid container data-tour="ad__contractData">
      <Grid item xs={12} md={7} className={classes.gridItem}>
        <ContractArea contract={contract} isSingleView={isSingleView} />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        style={{
          height: "fit-content",
        }}
      >
        <AgentHierarchy3
          agentUpline={contract.upline?.agent_upline}
          isSingleView={isSingleView}
        />
      </Grid>
    </Grid>
  );

  const showAllContractsContainer = (classes, agentCarriers, isSingleView) => (
    <Grid container>
      <Grid item xs={12} className={classes.gridItem}>
        <AllContractsItem
          isSingleView={isSingleView}
          agentCarriers={agentCarriers}
        />
      </Grid>
    </Grid>
  );

  const toggleCertsProduction = (row) => {
    if (!openProducts) {
      setOpenProducts(!openProducts);
      setOpenCerts(!openCerts);
      setProductsData(row);
    }
  };

  const showProdPerCertRow = (classes, isSingleView) => {
    return (
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <ProdPerCertRow
            certsData={certsData}
            changeState={changeState}
            classes={classes}
            isSingleView={isSingleView}
            productsData={productsData}
            toggleCertsProduction={toggleCertsProduction}
          />
        </Grid>
      </Grid>
    );
  };

  const showAllCerts = (classes, isSingleView, agentCarriers) => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.gridItem}
          data-tour="ad__rtsCertsTableView"
        >
          <CertsTable
            certsData={certsData}
            classes={classes}
            isSingleView={isSingleView}
            showProductsPerRow={toggleCertsProduction}
            selectedPlanYear={selectedPlanYear}
            certsLoading={certsLoading}
            changeState={changeState}
            agentCarriers={agentCarriers}
          />
        </Grid>
      </Grid>
    );
  };

  const showProduction = (classes, agent, isSingleView) => (
    <Grid container>
      <Grid item xs={12} className={classes.gridItem}>
        <BreakoutProductionCard
          className={classes.productionBreakout}
          isSingleView={isSingleView}
          agent={agent}
        />
      </Grid>
    </Grid>
  );

  const toggleShowallCerts = async () => {
    /* need to make sure breakoutProduction and setShowAllContracts is false */
    const agencyId = sessionStorage.getItem("agencyId");
    props.setShowAllContracts(props.agent, false);
    setBreakoutProduction(false);
    if (certsData.length >= 1) {
      setOpenCerts(!openCerts);
    } else {
      setOpenCerts(!openCerts);
      setCertsLoading(true);
      await certsPerAgent(props.agent.agentId.toString(), agencyId).then(
        ({ data }) => {
          const years = [
            ...new Set(
              data.certsPerAgentDash
                .map(({ planYear }) => planYear)
                .sort((x, y) => y - x)
            ),
          ];
          setCertsData(data.certsPerAgentDash);
          setPlanYears(years);
          setSelectedPlanYear(years[0]);
          setCertsLoading(false);
        }
      );
    }
    if (openProducts) {
      setOpenProducts(!openProducts);
    }
    if (breakoutProduction || showAllContracts) {
      setOpenProducts(false);
    }
  };

  const getContent = (classes, contract, agentCarriers, isSingleView) => {
    if (props.showAllContracts) {
      return showAllContractsContainer(classes, agentCarriers, isSingleView);
    }
    if (breakoutProduction) {
      return showProduction(classes, props.agent, isSingleView);
    }
    if (openCerts) {
      return showAllCerts(classes, isSingleView, agentCarriers);
    }
    if (openProducts) {
      return showProdPerCertRow(classes, isSingleView);
    }
    return showContracts(classes, contract, isSingleView);
  };

  const handleChangeYear = (e) => {
    setSelectedPlanYear(e.target.value);
  };

  const changeState = (type) => {
    type === "products" ? setOpenProducts(false) : setOpenCerts(false);
  };

  const filterDataByYear = (year, data) =>
    data.filter((x) => x.planYear === year);

  const rootClasses = isSingleView
    ? classes.rootInSingleView
    : classesFromParent.root;

  return (
    <Grid
      alignContent="flex-start"
      container
      spacing={0}
      className={rootClasses}
    >
      <AgentTileTitle
        isSingleView={isSingleView}
        onAgentClick={onAgentClick}
        agent={agent}
        contract={contract}
      />
      <Grid alignContent="flex-start" container spacing={0}>
        <CarrierTab
          certsData={certsData}
          agentCarriers={agentCarriers}
          openCerts={openCerts}
          openProducts={openProducts}
          toggleShowallCerts={toggleShowallCerts}
          certsPerAgent={certsPerAgent}
          currentContract={contract}
          onSelectContract={handleContractChange}
          showAllContracts={showAllContracts}
          handleShowAllContractsChange={handleShowAllContractsChange}
          setShowAllContracts={setShowAllContracts}
          agent={agent}
          handleBreakoutProductionChange={handleBreakoutProductionChange}
          breakoutProduction={breakoutProduction}
          filterDataByYear={filterDataByYear}
          planYears={planYears}
          selectedPlanYear={selectedPlanYear}
          handleChangeYear={handleChangeYear}
        />
        {getContent(classes, contract, agentCarriers, isSingleView)}
      </Grid>
    </Grid>
  );
};

export default AgentTile;
