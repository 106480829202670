import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Chip, Grid } from "@mui/material";

const styles = (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "#3f51b5",
    },
    "& .MuiInputLabel-root": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-root": {
      color: "#3f51b5",
    },
    "& .MuiInputBase-root": {
      color: "#3f51b5",
      backgroundColor: "#eee",
    },
    borderRadius: 0,
  },
});

class ContractChips extends React.Component {
  render() {
    const { contracts, handleContractClicked, handleContractDelete, rowData } =
      this.props;

    let contractNames = contracts.map((contract, index) => (
      <Chip
        label={contract.carrier + " " + contract.upline}
        onClick={() => handleContractClicked(index)}
        onDelete={() => !rowData && handleContractDelete(index)}
        style={{ backgroundColor: "#3F51B6" }}
        color="primary"
      />
    ));

    return (
      <Grid
        container
        id="contractChips"
        style={{
          backgroundColor: "rgb(239, 240, 241)",
          paddingTop: 20,
        }}
      >
        <Grid item id="contractsToAdd">
          Requested Contracts:{" "}
        </Grid>
        <Grid item id="contractsToAddLabel">
          {contractNames}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ContractChips);
