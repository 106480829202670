import {
  SET_ALL_CARRIERS,
  CLEAR_ALL_CARRIERS,
  GET_STATES,
  GET_AGENTLIST,
} from "../../constants/action-types";

export const contractFormData = (state = [], action) => {
  switch (action.type) {
    case SET_ALL_CARRIERS:
      return action.payload;
    case CLEAR_ALL_CARRIERS:
      return action.payload;
    default:
      return state;
  }
};

export const statesOptionsData = (state = [], action) => {
  switch (action.type) {
    case GET_STATES:
      return action.payload;
    default:
      return state;
  }
};

export const agentListData = (state = [], action) => {
  switch (action.type) {
    case GET_AGENTLIST:
      return action.payload;
    default:
      return state;
  }
};
