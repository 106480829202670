import React from "react";
import { makeStyles } from "tss-react/mui";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    heading: {
      color: "#003760",
    },
    table: {
      padding: 10,
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 34,
      width: 3,
      backgroundColor: "grey",
    },
  };
});

const ProductionOverview = (props) => {
  const {
    currentProduction,
    currentProduction: { currentYear },
  } = props;
  const { classes } = useStyles();

  let ma_top = 0,
    ma_total = 0,
    ms_top = 0,
    ms_total = 0;
  const currentProductionData = currentProduction.production[currentYear];
  if (currentProduction && currentYear && currentProductionData) {
    ma_top = currentProductionData.ma_top;
    ma_total = currentProductionData.ma_total;
    ms_top = currentProductionData.ms_top;
    ms_total = currentProductionData.ms_total;
  }
  return (
    <>
      <Typography
        className={classes.heading}
        gutterBottom
        variant="h5"
        component="h3"
      >
        Production Overview
      </Typography>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Product</TableCell>
              <TableCell align="center">Agency Production Total</TableCell>
              <TableCell
                align="center"
                style={{ padding: 0, borderBottom: "none" }}
              >
                <Divider orientation="vertical" className={classes.divider} />
              </TableCell>
              <TableCell align="center">Top Producing Agent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="ma_prod">
              <TableCell component="th" scope="row">
                Medicare Advantage
              </TableCell>
              <TableCell align="center">{ma_total}</TableCell>
              <TableCell
                align="center"
                style={{ padding: 0, borderBottom: "none" }}
              >
                <Divider orientation="vertical" className={classes.divider} />
              </TableCell>
              <TableCell align="center">
                {ma_top !== "No Production" && ma_top !== 0 ? (
                  <Link
                    to={{
                      pathname: `/AgentList`,
                      state: {
                        agentName: { ma_top },
                      },
                    }}
                  >
                    <Typography>{ma_top}</Typography>
                  </Link>
                ) : (
                  <Typography>{ma_top}</Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow key="ms_prod">
              <TableCell component="th" scope="row">
                Medicare Supplement
              </TableCell>
              <TableCell align="center">{ms_total}</TableCell>
              <TableCell align="center" style={{ padding: 0 }}>
                <Divider orientation="vertical" className={classes.divider} />
              </TableCell>
              <TableCell align="center">
                {ms_top !== "No Production" && ms_top !== 0 ? (
                  <Link
                    to={{
                      pathname: `/AgentList`,
                      state: {
                        agentName: { ms_top },
                      },
                    }}
                  >
                    <Typography>{ms_top}</Typography>
                  </Link>
                ) : (
                  <Typography>{ms_top}</Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default ProductionOverview;
