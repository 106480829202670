import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { getAgency } from "../store/actions/index";
import { Grid, Paper } from "@mui/material";
import Footer from "../components/common/Footer";
import NavBar from "../components/common/NavBar";
import LoadingOverlay from "@ronchalant/react-loading-overlay";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    menuGrid: {
      [theme.breakpoints.up("md")]: {
        flexBasis: "20%",
        maxWidth: "20%",
      },
    },
    menu: {
      flexGrow: 1,
      textAlign: "center",
      padding: 1,
      background:
        "linear-gradient(351deg, rgba(38, 42, 56, 1) 0%, rgba(144, 164, 174, 1) 75%)",
    },
    container: {
      marginTop: "60px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "56px",
      },
    },
    containerWihtoutApp: {
      overflow: "auto",
      height: "100%",
    },
    content: {
      height: "100%",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    contentWihtoutApp: {
      minHeight: "-webkit-fill-available",
      overflow: "hidden",
    },
    footerGrid: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
    },
    overlay: {
      minHeight: "100vh",
      position: "relative",
    },
    overlayMainChild: {
      paddingBottom: 40,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 46,
      },
    },
  };
});

const PageContainerWithMenu = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const handleForceRefresh = (e) => {
    e.preventDefault();
    const agencyId = sessionStorage.getItem("agencyId");
    const agentId = sessionStorage.getItem("agentId");
    const { type } = props;

    if (type === "agents") {
      /* async load agency */
      props.reloadAgents(true);
      dispatch(getAgency(agencyId, agentId));
    }
  };

  const {
    menuPadding,
    HasAppBar = true,
    HasMenu = true,
    HasHomeButton = true,
    type = "home",
    menu,
    content,
    overlayloaderText,
    overlayActive,
  } = props;

  let currentOverlayActive = overlayActive;

  const isFetchingPlanChange = useSelector(
    (planChange) => planChange.isFetchingPlanChange
  );
  const isFetchingAgentList = useSelector(
    (agents) => agents.isFetchingAgentList
  );
  const FetchingAgentsError = useSelector(
    (agents) => agents.FetchingAgentsError
  );
  const FetchingPlanChangeError = useSelector(
    (planChange) => planChange.FetchingPlanChangeError
  );
  const FetchingProductionError = useSelector(
    (currentProduction) => currentProduction.FetchingProductionError
  );
  const isFetchingProductionList = useSelector(
    (currentProduction) => currentProduction.isFetchingProductionList
  );

  const containerClasses = HasAppBar
    ? classes.container
    : classes.containerWihtoutApp;
  const contentClasses = HasMenu ? classes.content : classes.contentWihtoutApp;

  const loadWithError =
    FetchingAgentsError !== undefined ||
    FetchingProductionError !== undefined ||
    FetchingPlanChangeError !== undefined;
  if (loadWithError) {
    alert("Data loaded with error , please refresh ");
    currentOverlayActive = false;
  }

  return (
    <div className={classes.overlay}>
      <LoadingOverlay
        active={
          currentOverlayActive ||
          isFetchingAgentList ||
          isFetchingProductionList ||
          isFetchingPlanChange
        }
        className={classes.overlay}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
          }),
        }}
        spinner
        text={overlayloaderText}
      >
        <Grid className={classes.overlayMainChild} container direction="column">
          {HasAppBar && (
            <Grid item>
              <NavBar
                HasHomeButton={HasHomeButton}
                ForceRefresh={handleForceRefresh}
                type={type}
              />
            </Grid>
          )}
          <Grid item>
            <Grid
              className={containerClasses}
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              direction="row"
            >
              {HasMenu && (
                <Grid item xs={12} md={4} lg={3} style={{ padding: 10 }}>
                  <Paper
                    className={classes.menu}
                    style={{ padding: menuPadding }}
                    elevation={5}
                  >
                    {menu}
                  </Paper>
                </Grid>
              )}
              <Grid
                className={contentClasses}
                style={{ padding: 10 }}
                item
                xs={12}
                md={HasMenu ? 8 : 12}
                lg={HasMenu ? 9 : 12}
              >
                {content}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LoadingOverlay>
      <Footer feedback={true} />
    </div>
  );
};

export default PageContainerWithMenu;
