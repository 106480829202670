import _ from "lodash";
import agentParse from "../components/agentTileFiles/agentParser";
import StringUtils from "./stringUtils";

const AgentListUtils = {
  LoadAgents,
  BuildAgentContractMap,
  HandleFormateCarriers,
  FilterByGroup,
  FilterByStatus,
  FilterByLevel,
};

function LoadAgents(data) {
  const agencyId = sessionStorage.getItem("agencyId");

  if (agencyId) { 
    const carrierMap = new Map();
    const levelsSet = new Set();
    for (const agent of data) {
      agent.lastname = StringUtils.firstUpperLetter(agent.lastname);
      agent.firstname = StringUtils.firstUpperLetter(agent.firstname);

      for (const agentCarrier of agent.agentCarriers) {
        if (!carrierMap.has(agentCarrier.carrier)) {
          carrierMap.set(agentCarrier.carrier, agentCarrier.carrier);
        }
        levelsSet.add(agentCarrier.carrier + " - " + agentCarrier.agent_level);
      }
    }
    const sortedAgents = _.sortBy(data, ["lastname", "firstname"]);
    const distinctLevels = Array.from(levelsSet);
    const sortedLevels = _.sortBy(distinctLevels);

    const distinctCarriers = Array.from(carrierMap.keys());

    /* format phone in (area code) 333-3333 */
    sortedAgents.map((agent) => {
      if (agent.phone) {
        const digits = agent.phone.replace(/\D+/g, "");
        if (digits.length === 10) {
          agent.phone =
            "(" +
            digits.substr(0, 3) +
            ") " +
            digits.substr(3, 3) +
            "-" +
            digits.substr(6, 4);
        }
        if (digits.length === 7) {
          agent.phone = digits.substr(0, 3) + "-" + digits.substr(3, 4);
        }
      }
      return agent;
    });

    return {
      sortedAgents: sortedAgents,
      distinctLevels: sortedLevels,
      distinctCarriers: distinctCarriers,
    };
  }
}

function BuildAgentContractMap(carriers, agents) {
  const filtered = agents.filter(({ agentCarriers }) =>
    agentCarriers.some(({ carrier }) => carriers.includes(carrier))
  );
  filtered.forEach((agent) => {
    agent.agentCarriers = agent.agentCarriers.filter(({ carrier }) => {
      return carriers.includes(carrier);
    });
  });
  return filtered;
}

function HandleFormateCarriers(agentCarriers) {
  let passedCarries = JSON.parse(JSON.stringify(agentCarriers));
  passedCarries = _.orderBy(
    passedCarries,
    ["carrier", "is_corporation"],
    ["asc", "desc"]
  );
  const contractsUHC = passedCarries.filter(
    (agentCarrier) => agentCarrier.carrier === "UHC"
  );
  if (contractsUHC.length > 1) {
    passedCarries.forEach((agentCarrier) => {
      if (agentCarrier.carrier === "UHC" && agentCarrier.is_corporation) {
        agentCarrier.carrier =
          agentCarrier.carrier + " - " + agentCarrier.companyName;
      }
    });
  }

  return passedCarries;
}

function FilterByLevel(levels, agents) {
  const filtered = agents.filter(({ agentCarriers }) => {
    const result = agentCarriers.some(({ agent_level, carrier }) => {
      const result = levels.includes(carrier + " - " + agent_level);
      return result;
    });
    return result;
  });
  filtered.forEach((agent) => {
    agent.agentCarriers = agent.agentCarriers.filter(
      ({ agent_level, carrier }) => {
        return levels.includes(carrier + " - " + agent_level);
      }
    );
  });
  return filtered;
}

function FilterByGroup(groups, agents) {
  const filtered = agents.filter(({ agentCarriers }) =>
    agentCarriers.some(({ agencies }) =>
      agencies.some((agency) => groups.includes(agency.toLowerCase()))
    )
  );

  filtered.forEach((agent) => {
    agent.agentCarriers = agent.agentCarriers.filter((agentCarrier) => {
      return agentCarrier.agencies.some((agency) =>
        groups.includes(agency.toLowerCase())
      );
    });
  });
  return filtered;
}

function FilterByStatus(value, agents) {
  let statuses = [];
  value.forEach((item) => {
    statuses.push(agentParse.statusParseReverse(item));
  });

  const filtered = agents.filter(({ agentCarriers }) =>
    agentCarriers.some(({ contracts }) =>
      contracts.some(({ contractStatus }) => statuses.includes(contractStatus))
    )
  );

  filtered.forEach((agent) => {
    agent.agentCarriers = agent.agentCarriers.filter((agentCarrier) => {
      const matchedContracts = agentCarrier.contracts.filter(
        ({ contractStatus }) => statuses.includes(contractStatus)
      );
      if (matchedContracts.length) {
        agentCarrier.contracts = matchedContracts;
        return true;
      }
    });
  });

  return filtered;
}

export default AgentListUtils;
