import React, { useState, useEffect } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Grid, Typography } from "@mui/material";
import LoaderButton from "../components/common/LoaderButton";
import { useDispatch } from "react-redux";
import { clearStore } from "../store/actions/index";
import Swal from "sweetalert";
import ReactGA from "react-ga";
import "../components/LandingPage.css";
import { makeStyles } from "tss-react/mui";
import { persistor } from "../store";
import {
  getAgencyInfoForBDSAdminAccount,
  setAgencySessionVariables,
  login,
  isUserAuthenticated,
} from "../services/GraphQl/BDSWebService";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      textAlign: "center",
      height: "100vh",
      background:
        "linear-gradient(to bottom right, #4c5255, #000000) !important",
      backgroundColor: "rgb(68, 66, 66)",
    },
    BannerStart: {
      color: "#FFFFFF",
      fontSize: "calc(48px + (36 - 24) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontFamily: "mazBold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "calc(24px + (36 - 24) * ((50vw - 250px) / (4000 - 250)))",
        lineHeight: "calc(1.3em + (1.5 - 1.2) * ((50vw - 250px)/(3000 - 250)))",
      },
    },
    BannerEnd: {
      color: "#FFFFFF",
      fontSize: "calc(48px + (36 - 24) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontFamily: "maz",
      [theme.breakpoints.down("sm")]: {
        fontSize: "calc(24px + (36 - 24) * ((50vw - 250px) / (4000 - 250)))",
        lineHeight: "calc(1.3em + (1.5 - 1.2) * ((50vw - 250px)/(3000 - 250)))",
      },
    },
  };
});

const LoginWithLanding = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview(
      "/login",
      [],
      "Agency - " + sessionStorage.getItem("agencyId") + " viewed login page."
    );
  }, []);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleChange = (event) => {
    event.preventDefault();

    if (event.target.id === "email") {
      setEmail(event.target.value);
    } else if (event.target.id === "password") {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let selectedAgencyId = "";
    try {
      sessionStorage.clear();
      await login(email, password)
        .then(async (loginResult) => {
          /* for admin users we need to display a agency_id selector */
          sessionStorage.setItem(
            "isStaging",
            window.location.href.includes("://theagencydashboard.com") ||
              window.location.href.includes("://www.theagencydashboard.com")
              ? false
              : true
          );
          if (loginResult.__typename === "AdminLogin") {
            selectedAgencyId = prompt(
              "Enter the agency id that you would like to view.",
              ""
            );
            if (!selectedAgencyId || selectedAgencyId === "") {
              alert("Please enter correct agency id.");
              return;
            }
            await getAgencyInfoForBDSAdminAccount(selectedAgencyId)
              .then(async (res) => {
                if (res) {
                  selectedAgencyId = res[0].agencyId;
                  setAgencySessionVariables(res[0]);
                } else {
                  alert("Please enter current agency id and try again.");
                }
              })
              .catch((e) => {
                throw {
                  message: "cant validate agency id with error " + e.message,
                };
              });
          }
        })
        .catch((e) => {
          let errorMsg = e.message;
          if (errorMsg.search("Incorrect username or password.") != -1) {
            errorMsg = "Incorrect password.";
          }
          if (errorMsg.search("No such user") != -1) {
            errorMsg = "Incorrect username.";
          }
          Swal("Oops!", errorMsg, "error");
        });
    } finally {
      finishLogin(selectedAgencyId);
      setIsLoading(false);
    }
  };

  const finishLogin = async (agencyId) => {
    if (await isUserAuthenticated()) {
      /* clear existing state */
      persistor.purge();
      dispatch(clearStore());
      ReactGA.event({
        category: "UserLoginIn",
        action: "Login",
        label: "Agency : " + agencyId + " logged in.",
      });
      const {
        history: { push },
      } = props;
      push("/main");
    }
  };

  const { classes } = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      spacing={0}
      direction="row"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={5}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <Grid
            container
            justifyContent="center"
            data-tour="ad__dashboardTitle"
            direction="row"
            wrap="nowrap"
          >
            <Typography
              className={classes.BannerStart}
              sx={{ fontWeight: "bolder" }}
            >
              AGENCY
            </Typography>
            <Typography
              className={classes.BannerEnd}
              sx={{ fontWeight: "lighter" }}
            >
              DASHBOARD
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              margin: "10%",
              width: "80%",
              padding: 10,
              textAlign: "center",
            }}
          >
            <form onSubmit={handleSubmit}>
              <FormGroup controlId="email" bsSize="large">
                <ControlLabel style={{ color: "#eee" }}>Username</ControlLabel>
                <FormControl
                  autoFocus
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
              <FormGroup controlId="password" bsSize="large">
                <ControlLabel style={{ color: "#eee" }}>Password</ControlLabel>
                <FormControl
                  value={password}
                  onChange={(e) => handleChange(e)}
                  type="password"
                />
              </FormGroup>
              <LoaderButton
                style={{
                  backgroundColor: "#00aeef",
                  color: "#eee",
                  width: "100%",
                  fontWeight: "bolder",
                  fontSize: "1.65rem",
                }}
                bssize="small"
                disabled={!validateForm()}
                type="submit"
                isLoading={isLoading}
                text="Login"
                loadingText="Logging in…"
              />
            </form>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            direction="column"
          >
            <Grid item xs={12} style={{ padding: 10 }}></Grid>
          </Grid>
          <Grid container alignItems="center" direction="row">
            <Grid
              item
              xs={12}
              style={{
                paddingBottom: 10,
              }}
            >
              <span className="poweredBy" style={{ color: "#eee" }}>
                Powered By: &nbsp;&nbsp;{" "}
                <a
                  data-tour="ad__berwickLogo"
                  target={"_blank"}
                  href="https://berwickinsurance.com"
                  rel="noreferrer"
                >
                  <img
                    style={{
                      height: 80,
                      display: "block",
                      margin: "auto",
                      padding: 5,
                      filter: "brightness(1.75)",
                    }}
                    src="Berwick_Color_Tag@2x.png"
                    alt="berwick logo"
                  />
                </a>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginWithLanding;
