import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Card, CardContent } from "@mui/material";
import agentParse from "./agentParser";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";
import Typography from "@mui/material/Typography";
import HtmlToolTip from "../common/HtmlTooltip";

const styles = (theme) => {
  const titleRoot = {
    fontSize: "2em",
    color: "#003760",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  const tableTextRoot = {
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  return {
    titleRoot,
    tableTextRoot,
    card: {
      height: "100%",
      backgroundColor: "#FFFFFF",
      padding: 0,
      margin: 0,
    },
    title: {
      ...titleRoot,
      fontSize: "1em",
    },
    titleInSingleView: {
      ...titleRoot,
    },
    table: {
      padding: 0,
    },
    tableText: {
      ...tableTextRoot,
      fontSize: "0.8em",
    },
    tableTextInSingleView: {
      ...tableTextRoot,
    },
    Tooltip: {},
  };
};
function AllContractsCard(props) {
  const { classes, isSingleView, agentCarriers } = props;
  const titleClasses = isSingleView ? classes.titleInSingleView : classes.title;
  const tableTextclasses = isSingleView
    ? classes.tableTextInSingleView
    : classes.tableText;
  return (
    <Card className={classes.card} elevation={3}>
      <CardContent>
        <Typography className={titleClasses} color="textPrimary" gutterBottom>
          All Contracts
        </Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={tableTextclasses} padding="none">
                Carrier
              </TableCell>
              <TableCell className={tableTextclasses} padding="none">
                Status
              </TableCell>
              <TableCell className={tableTextclasses} padding="none">
                Agent Level
              </TableCell>
              <TableCell
                className={tableTextclasses}
                padding="none"
                align="center"
              >
                Is Corporation
              </TableCell>
              <TableCell
                className={tableTextclasses}
                padding="none"
                align="center"
              >
                State
              </TableCell>
              <TableCell
                className={tableTextclasses}
                padding="none"
                align="center"
              >
                Writing Number
              </TableCell>
              <TableCell
                className={tableTextclasses}
                padding="none"
                align="center"
              >
                Status Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agentCarriers.map((agent_carrier) =>
              agent_carrier.contracts.map((contract, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                  >
                    {agent_carrier.carrier}
                  </TableCell>
                  <HtmlToolTip
                    title={agentParse.agentParseTooltip(
                      contract.contractStatus,
                      contract.writing_number
                    )}
                  >
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      component="th"
                      scope="row"
                    >
                      {agentParse.createIcon(contract.contractStatus)}{" "}
                      {agentParse.statusParse(contract.contractStatus)}
                    </TableCell>
                  </HtmlToolTip>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                  >
                    {agent_carrier.agent_level}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="center"
                  >
                    {contract.is_corporation
                      ? agentParse.createIcon("APPROVED")
                      : ""}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="center"
                  >
                    {contract.state}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="center"
                  >
                    {contract.writing_number}
                  </TableCell>
                  <TableCell
                    className={classes.tableTextclasses}
                    padding="none"
                    align="center"
                  >
                    {agentParse.formateStatsDate(contract)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
export default withStyles(styles)(AllContractsCard);
