import DownLineCommunications from "./DownLineCommunications";
import PageContainerWithMenu from "../../containers/PageContainerWithMenu";
import PlainMenu from "../PlainMenu";
const DownLineCommunicationsInfo = () => {
  return (
    <PageContainerWithMenu
      data-tour="ad__home"
      type="production"
      HasMenu={true}
      menu={<PlainMenu title="Downline Communications" />}
      content={<DownLineCommunications />}
    />
  );
};
export default DownLineCommunicationsInfo;
