import React from "react";
import { makeStyles } from "tss-react/mui";
import { Paper, Button, Typography } from "@mui/material/";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material/";

const useStyles = makeStyles()((theme) => {
  return {
    stepperPaper: {
      margin: 0,
      marginLeft: 10,
      marginRight: 10,
      padding: 0,
      backgroundColor: "#575757",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    stepper: {
      margin: 0,
      marginLeft: 15,
      marginRight: 15,
      padding: 0,
      backgroundColor: "#575757",
      color: "#eee",
    },
    stepperButton: {
      color: "#eee",
    },
    stepperProgress: {
      color: "#eee",
    },
    stepperPages: {
      color: "#eee",
    },
    input: {
      color: "#eee",
      width: 50,
      textAlign: "center",
    },
    slider: {
      trackColor: "#eee",
      selectionColor: "yellow",
    },
  };
});

const AgentStepper = (props) => {
  const { classes } = useStyles();
  const { Count, pageLimit, currentPage, onBack, onNext } = props;

  return (
    <Paper elevation={10} className={classes.stepperPaper}>
      <Button
        className={classes.stepperButton}
        size="large"
        onClick={onBack}
        disabled={currentPage === 1}
      >
        Previous
        <KeyboardArrowLeft />
      </Button>
      <Typography className={classes.stepperPages}>
        {" "}
        {currentPage} of {Math.ceil(Count / pageLimit)}
      </Typography>
      <Button
        className={classes.stepperButton}
        size="large"
        onClick={onNext}
        disabled={currentPage === Math.ceil(Count / pageLimit)}
      >
        Next
        <KeyboardArrowRight />
      </Button>
    </Paper>
  );
};

export default AgentStepper;
