import CommissionLossReports from "./CommissionLossReports";
import PageContainerWithMenu from "../../containers/PageContainerWithMenu";
import PlainMenu from "../PlainMenu";
import { Box, Grid, Typography } from "@mui/material";
import ProductionDataByCarrier from "../shared/ProductionDataByCarrier";

const CommissionLossReportInfo = () => {
  const notes = `Note:  We anticipate the following report will help you maintain your book of business. 
                All data in this report is based on renewal commission reports from the carriers. 
                Most carriers send monthly commission reports, but some send quarterly reports. 
                The data in this report is based on the most recent commission report received from the carrier. 
                If you have any questions about the data in this report, please contact your carrier representative.`;
  const sxTypography = { color: "white" };

  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  const currentYear = new Date().getFullYear();

  const ReportingDates = [
    { carrierName: "Aetna", ETLDate: currentMonth + " " + currentYear },
    { carrierName: "Humana", ETLDate: currentMonth + " " + currentYear },
    { carrierName: "UHC", ETLDate: currentMonth + " " + currentYear },
  ];

  return (
    <PageContainerWithMenu
      data-tour="ad__home"
      type="clr"
      HasMenu={true}
      menu={
        <PlainMenu
          title="Commission Loss Report"
          submenu={
            <Grid container>
              <Typography
                variant="h9"
                align="center"
                gutterBottom
                sx={sxTypography}
              >
                {notes}
              </Typography>
              <Grid item xs={12} sx={{ paddingTop: 8 }} />
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <ProductionDataByCarrier
                    data={ReportingDates.toSorted((a, b) =>
                      a.carrierName.localeCompare(b.carrierName)
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          }
        />
      }
      content={<CommissionLossReports />}
    />
  );
};
export default CommissionLossReportInfo;
