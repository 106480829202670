import React from "react";
import { makeStyles } from "tss-react/mui";
import {
  Grid,
  Paper,
  Button,
  Typography,
  InputBase,
  ButtonGroup,
} from "@mui/material";
import RenderSelectorWithState from "./RenderSelectorWithState";
import { Clear, CloudDownload } from "@mui/icons-material";
import yellow from "@mui/material/colors/yellow";
import Pagination2 from "./common/Pagination2";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import HtmlToolTip from "./common/HtmlTooltip";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    select: {
      color: "#eee",
    },
    inputContainer: {
      backgroundColor: "currentColor",
      color: "#eee",
      fontWeight: "bold",
    },
    selectorInput: {},
    typoGraphy: {
      color: "#003760",
      fontSize: "calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontWeight: "bold",
    },
    iconButton: {
      display: "inline-block",
      width: "auto",
      margin: 0,
      padding: 0,
      background: "none",
      backgroundColor: "transparent",
      border: "none",
      flexGrow: 1,
    },
    fabYellow: {
      color: theme.palette.common.black,
      backgroundColor: yellow[300],
      "&:hover": {
        backgroundColor: yellow[600],
      },
    },
    iconImage: {
      width: "50%",
    },
    input: {
      width: "80%",
      marginLeft: 8,
      flexGrow: 1,
      fontSize: "calc(8px + (24 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      [theme.breakpoints.down("xs")]: {
        fontSize: "calc(24px + (48 - 24) * ((100vw - 250px) / (4000 - 250)))",
        lineHeight:
          "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      },
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
  };
});

const QuickSearch = (props) => {
  const agencies = useSelector((state) => state.agentListAgencies);
  const { classes } = useStyles();
  const {
    handleAgentSearchInputChange,
    clearAgentNameSearch,
    carrierSearchHandler,
    groupSearchHandler,
    contractStatusSearchHandler,
    levelSearchHandler,
    carriers,
    levels,
    onPageChanged,
    onAlphaPageChanged,
    isSingleView,
    Count,
    pageLimit,
    currentAlphaPage,
    currentPage,
    carriersOptions,
    groupOptions,
    contractOptions,
    levelOptions,
    downloadable,
    download,
    dataToDownload,
    awayEnabledStatus,
    filterByAwayStatus,
    clearSearchHandler,
    popCsvLink,
    agentSearchInputValue,
  } = props;

  const contractStatuses = [
    "Agent Cert",
    "Approved",
    "Approved - Intent to Move Away Submitted",
    "Approved - Released",
    "Intent move big",
    "Pending writing",
    "Received",
    "Sent to agent",
    "Submitted",
    "Submitted missing doc",
    "Submitted no cert",
    "Withdrawn",
    "Rejected",
    "Terminated",
  ];
  const awayFilterTypes = [
    { type: "Off", tooltipLabel: "" },
    {
      type: "Registered",
      tooltipLabel: "Click to filter agents that have registered with AgentWay",
    },
    {
      type: "Unregistered",
      tooltipLabel:
        "Click to filter agents that have NOT registered with AgentWay",
    },
  ];
  return (
    <div className={classes.root}>
      <Grid
        style={{ padding: 0 }}
        container
        justifyContent="space-evenly"
        spacing={3}
        direction="column"
      >
        <div>
          <CSVLink
            data={dataToDownload}
            filename="agent_list.csv"
            className="hidden"
            ref={(r) => popCsvLink(r)}
            target="_blank"
          />
        </div>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item xs={12}>
                  <InputBase
                    name="AgentSearch"
                    className={classes.input}
                    onChange={handleAgentSearchInputChange}
                    id="AgentSearch"
                    placeholder="Agent Search"
                    value={agentSearchInputValue}
                  />
                  <Clear
                    style={{ cursor: "pointer" }}
                    onClick={clearAgentNameSearch}
                    className={classes.buttonIcon}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography
            style={{ background: "#3e4653", color: "#eeeeee" }}
            variant="h5"
          >
            Agent Way Filter
          </Typography>
          <Grid
            item
            xs={12}
            style={{ background: "rgb(216 213 213)", padding: "13px" }}
            align="center"
          >
            <ButtonGroup
              elevation={2}
              variant="contained"
              style={{ color: "#003760" }}
            >
              {awayFilterTypes.map(({ type, tooltipLabel }, i) => {
                const isType = awayEnabledStatus === type;
                return (
                  <HtmlToolTip title={tooltipLabel} color="#003760">
                    <Typography
                      style={{
                        color: isType ? "#eee" : "#003760",
                        background: isType ? "rgba(0,55,96, .8)" : "#eee",
                        fontWeight: "bold",
                        outline: "none",
                        cursor: "pointer",
                        padding: 10,
                      }}
                      key={type}
                      onClick={() => filterByAwayStatus(type)}
                    >
                      {type}
                    </Typography>
                  </HtmlToolTip>
                );
              })}
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <ButtonGroup fullWidth data-tour="ad__resetAndDownload">
            <HtmlToolTip
              placement="top"
              title="Reset All Search Fields"
              color="black"
            >
              <Button
                fullWidth
                onClick={clearSearchHandler}
                className={classes.fabYellow}
              >
                {<Typography style={{ color: "#000" }}>Reset</Typography>}
                <Clear className={classes.buttonIcon} />
              </Button>
            </HtmlToolTip>
            <HtmlToolTip
              color="black"
              title={
                <React.Fragment>
                  <Typography>Download Current Agent List</Typography>
                  {
                    "This will download the current agent list to a CSV file.  The data will be downloaded with all filtering."
                  }
                </React.Fragment>
              }
              placement="top"
            >
              <Button
                disabled={!downloadable}
                onClick={download}
                variant="contained"
                color="primary"
                data-tour="ad__rtsCertsDownload"
                fullWidth
              >
                Download
                <CloudDownload className={classes.buttonIcon} />
              </Button>
            </HtmlToolTip>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12} data-tour="ad__filterSelectors">
          <RenderSelectorWithState
            options={carriersOptions}
            label="Carriers"
            data={carriers}
            searchHandler={carrierSearchHandler}
          />

          <RenderSelectorWithState
            label="Agency/Group"
            options={groupOptions}
            data={agencies.map((agency) =>
              agency.subagency_name ? agency.subagency_name : agency.name
            )}
            searchHandler={groupSearchHandler}
          />

          <RenderSelectorWithState
            options={contractOptions}
            label="Contract Status"
            data={contractStatuses}
            searchHandler={contractStatusSearchHandler}
          />
          <RenderSelectorWithState
            options={levelOptions}
            label="Level"
            data={levels}
            searchHandler={levelSearchHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <Pagination2
            title="Agent's Last Initial"
            totalRecords={27}
            pageLimit={1}
            pageNeighbours={1}
            UseLetter={true}
            currentPage={currentAlphaPage}
            onPageChanged={onAlphaPageChanged}
          />
          {!isSingleView && Count > 1 ? (
            <Pagination2
              title="Page"
              totalRecords={Count}
              pageLimit={pageLimit}
              currentPage={currentPage}
              pageNeighbours={1}
              onPageChanged={onPageChanged}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default QuickSearch;
