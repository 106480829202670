import { graphqlPath, subscriptionsPath } from "../../api.config";
import {
  ApolloClient,
  from,
  InMemoryCache,
  HttpLink,
  split,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { refreshAuthToken } from "./BDSWebService";
import { clearStore } from "../../store/actions";
import history from "../../utils/history";
import { Observable } from "apollo-link";
import { getMainDefinition } from "@apollo/client/utilities";
import { GQLWebSocketLink } from "./wslink";

const httpLink = new HttpLink({
  uri: graphqlPath,
});
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 10,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

//Leave in the case refreshToken is ever used
const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => {
        subscriber.error(err);
      }
    );
  });
};

const getOuttaHere = () => {
  sessionStorage.clear();
  clearStore();
  history.push("/login");
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (
          err.extensions?.code === "UNAUTHENTICATED" ||
          err.message === "unauthorized"
        ) {
          //Uncomment below if refresh ever becomes needed

          // // Grab Headers, username, and refresh token
          // const prevHeaders = operation.getContext().headers;
          // const refreshToken = sessionStorage.getItem("bdsApiRefreshToken");
          // if (refreshToken) {
          //   //Set new context for operations
          //   return promiseToObservable(refreshAuthToken(refreshToken)).flatMap(
          //     (token) => {
          //       operation.setContext({
          //         headers: {
          //           ...prevHeaders,
          //           authorization: token,
          //           "Bit-default-date-format": "M/d/yyyy hh:mm:ss a",
          //         },
          //       });
          //       // Retry the request
          //       return forward(operation);
          //     }
          //   );
          // } else {
          //   //Push to login when no refreshToken available
          //   getOuttaHere();
          // }
          getOuttaHere();
        }
        //Uncomment in the case refresh is used again

        // if (err.message.includes("Invalid Refresh Token")) {
        //   //Push to login when bad refreshToken
        //   getOuttaHere();
        // }
      }
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("bdsApiToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
      "Bit-default-date-format": "M/d/yyyy hh:mm:ss a",
    },
  };
});

const subscriptionLink = new GQLWebSocketLink({
  url: subscriptionsPath,
  connectionParams: () => {
    const token = sessionStorage.getItem("bdsApiToken");
    return {
      authorization: token ? `${token}` : "",
      "Bit-default-date-format": "M/d/yyyy hh:mm:ss a",
    };
  },
  retryTimeout: 1000,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  subscriptionLink,
  httpLink
);

const client = new ApolloClient({
  //from([]) creates a link chain according to the docs
  link: from([errorLink, authLink, link, retryLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache", // Turn off cache. Needs testing before turning on
    },
    watchQuery: {
      fetchPolicy: "no-cache", // Turn off cache. Needs testing before turning on
    },
  },
  queryDeduplication: false,
});

export default client;
