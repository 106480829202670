import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { isUserAuthenticated } from "../../services/GraphQl/BDSWebService";
import { clearStore } from "../../store/actions/index";
export const AuthenticatedRoute = (props) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticatedFlag, setIsAuthenticatedFlag] = useState(false);
  const { component: Component, ...rest } = props;
  const newLocation = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      const result = await isUserAuthenticated();
      if (!result) {
        sessionStorage.clear();
        clearStore();
      }
      setIsAuthenticatedFlag(result);
      setLoading(false);
    };
    fetchData();
  }, [newLocation]);
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticatedFlag ? (
          <Component {...props} />
        ) : loading ? (
          <div>LOADING...</div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
