import {
  CLEAR_CONTRACT_REQUESTS,
  SET_CONTRACT_REQUESTS,
  SET_CONTRACT_REQUESTS_ERROR,
  SET_CONTRACT_REQUESTS_LOADING,
} from "../../constants/action-types";

const initState = {
  contractRequestsError: undefined,
  storedContractRequests: [],
  contractRequestsLoading: false,
};

export const currentContractRequests = (
  state = initState,
  { type, payload }
) => {
  switch (type) {
    case SET_CONTRACT_REQUESTS:
      return {
        ...state,
        storedContractRequests: payload,
      };
    case CLEAR_CONTRACT_REQUESTS:
      return payload;
    case SET_CONTRACT_REQUESTS_LOADING:
      return { ...state, contractRequestsLoading: payload };
    case SET_CONTRACT_REQUESTS_ERROR:
      return { ...state, contractRequestsError: payload };
    default:
      return state;
  }
};
