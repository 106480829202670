import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { createTheme, Grid } from "@mui/material";
import useCalcStyles from "./useCalcStyles";
import orange from "@mui/material/colors/orange";
import { ThemeProvider } from "@mui/material/styles";

const materialTheme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: orange["600"],
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: orange.A700,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: orange.A200,
        color: "white",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: orange["400"],
      },
    },
  },
});

const YearMonthPicker = (props) => {
  const {
    selectedEligibleDate,
    handleEligibleDateChange,
    selectedEnrollDate,
    handleEnrollDateChange,
  } = props;
  const classes = useCalcStyles();
  return (
    <ThemeProvider theme={materialTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container className={classes.DatePicker} spacing={3}>
          <Grid item xs={12}>
            <DatePicker
              className={classes.date}
              openTo="month"
              views={["month", "year"]}
              label="Eligible date"
              value={selectedEligibleDate}
              onChange={handleEligibleDateChange}
              minDate={new Date(2006, 1, 31)}
              dateFormat="MM/yyyy"
              sx={{
                width: "100%",
                overrides: {
                  MuiInputLabel: {
                    root: {
                      color: orange["600"],
                    },
                  },
                  MuiPickersToolbar: {
                    toolbar: {
                      backgroundColor: orange.A700,
                    },
                  },
                  MuiPickersCalendarHeader: {
                    switchHeader: {
                      backgroundColor: orange.A200,
                      color: "white",
                    },
                  },
                  MuiPickersModal: {
                    dialogAction: {
                      color: orange["400"],
                    },
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              className={classes.date}
              openTo="month"
              views={["month", "year"]}
              label="Enroll date"
              value={selectedEnrollDate}
              onChange={(e) => handleEnrollDateChange(e)}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default YearMonthPicker;
