import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tourGrid: {
    marginTop: 20,
    padding: 10,
    textAlign: "center",
  },
  tourIconBtn: {
    margin: 5,
    position: "absolute",
    top: -1,
    right: -1,
    color: "#333",
  },
}));

export default useStyles;
