import {
  SET_CONTRACT_STATUSES,
  CLEAR_CONTRACT_STATUSES,
} from "../constants/action-types";
import {getContractStatuses} from "../../services/GraphQl/BDSWebService";
/* redux-thunk implementation */

const colors = [
  "#42a5f5",
  "#ffab91",
  "#fff9c4",
  "#c8e6c9",
  "#cfd8dc",
  "#A9A9A9",
  "#9fcdf3",
];
const hColors = [
  "#2196f3",
  "#ff8a65",
  "#fff59d",
  "#a5d6a7",
  "#b0bec5",
  "#909090",
  "#9fcdf3",
];

/* get contract statuses for agency */
export const setContractStatuses = (agencyId) => {
  return async (dispatch) => {
    const result = await getContractStatuses(agencyId);
    dispatch(
      setContractStatusesAction({
        raw: result,
        pieData: pieData(result),
      })
    );
  };
};

export const pieData = (result) => {
  var approved = 0;
  var approvedCurrentYear = 0;
  var approvedIntentToMoveAwaySubmitted = 0;
  var approvedReleased = 0;
  var sentToAgent = 0;
  var inProcess = 0;
  var intentMove = 0;
  Object.entries(result).map((entry) => {
    const contract = entry[1];
    approvedCurrentYear = currentYearContracts(contract, approvedCurrentYear);
    switch (contract.status) {
      case "APPROVED":
      case "APPROVED_NOT_SELLING":
        approved += parseInt(contract.total);
        break;
      case "APPROVED_RELEASING":
        approvedReleased += parseInt(contract.total);
        break;
      case "APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED":
        approvedIntentToMoveAwaySubmitted += parseInt(contract.total);
        break;
      case "INTENT_MOVE_BIG":
        intentMove += parseInt(contract.total);
        break;
      case "SENT_TO_AGENT":
        sentToAgent += parseInt(contract.total);
        break;
      case "AGENT_CERT":
      case "PENDING_WRITING":
      case "RECEIVED":
      case "SUBMITTED":
      case "SUBMITTED_MISSING_DOC":
      case "SUBMITTED_NO_CERT":
        inProcess += parseInt(contract.total);
        break;
      default:
        break;
    }
  });
  // need to calculate the labels, data and color
  // sets based on the data we collected
  const calculatedLabels = [];
  const dataSets = [];
  const calculatedColors = [];
  const calculatedHColors = [];
  if (approved !== 0) {
    calculatedLabels.push("Approved - Total");
    dataSets.push(approved);
    calculatedColors.push(colors[0]);
    calculatedHColors.push(hColors[0]);
  }
  if (approvedCurrentYear !== 0) {
    calculatedLabels.push("Approved - Current Year");
    dataSets.push(approvedCurrentYear);
    calculatedColors.push(colors[6]);
    calculatedHColors.push(hColors[6]);
  }
  if (approvedIntentToMoveAwaySubmitted !== 0) {
    calculatedLabels.push("Approved - Intent to Move Away Submitted");
    dataSets.push(approvedIntentToMoveAwaySubmitted);
    calculatedColors.push(colors[5]);
    calculatedHColors.push(hColors[5]);
  }
  if (approvedReleased !== 0) {
    calculatedLabels.push("Approved - Released");
    dataSets.push(approvedReleased);
    calculatedColors.push(colors[1]);
    calculatedHColors.push(hColors[1]);
  }
  if (sentToAgent !== 0) {
    calculatedLabels.push("Sent To Agent");
    dataSets.push(sentToAgent);
    calculatedColors.push(colors[2]);
    calculatedHColors.push(hColors[2]);
  }
  if (inProcess !== 0) {
    calculatedLabels.push("In Process");
    dataSets.push(inProcess);
    calculatedColors.push(colors[3]);
    calculatedHColors.push(hColors[3]);
  }
  if (intentMove !== 0) {
    calculatedLabels.push("Intent To Move");
    dataSets.push(intentMove);
    calculatedColors.push(colors[4]);
    calculatedHColors.push(hColors[4]);
  }
  return {
    labels: calculatedLabels,
    datasets: [
      {
        data: dataSets,
        backgroundColor: calculatedColors,
        hoverBackgroundColor: calculatedHColors,
        label: calculatedLabels,
      },
    ],
  };
};

/* redux action */
const setContractStatusesAction = (data) => {
  return {
    type: SET_CONTRACT_STATUSES,
    payload: data,
  };
};

export const clearContractStatuses = () => {
  return async (dispatch) => {
    dispatch(clearContractStatusesAction({}));
  };
};

/* redux action */
const clearContractStatusesAction = (data) => {
  return {
    type: CLEAR_CONTRACT_STATUSES,
    payload: data,
  };
};

function currentYearContracts(contract, approvedCurrentYear) {
  if (contract.effectivedate !== null) {
    const contractDates = new Date(contract.effectivedate).getFullYear();
    if (
      contractDates === new Date().getFullYear() &&
      contract.status === "APPROVED"
    ) {
      approvedCurrentYear += parseInt(contract.total);
    }
  }
  return approvedCurrentYear;
}
