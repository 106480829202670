import { useEffect } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import OcrList from "./OcrList";
import PageContainerWithMenu from "../../containers/PageContainerWithMenu";
import PlainMenu from "../PlainMenu";
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      placeItems: "center",
      marginTop: theme.spacing(4),
    },
  };
});
const Leads = () => {
  const { classes } = useStyles();
  useEffect(() => {
    localStorage.setItem("newOcrCount", `0`);
  }, []);
  return (
    <PageContainerWithMenu
      HasHomeButton={true}
      HasMenu={true}
      menu={
        <PlainMenu
          classes={classes}
        />
      }      
      type={"leads"}
      content={
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <OcrList />
            </Grid>
          </Grid>
        </div>
      }
    />
    );
};

export default Leads;
