import { makeStyles } from "@mui/styles";

const useCalcStyles = makeStyles((theme) => {
  const fontFam = '"Open Sans", sans-serif';
  return {
    app: {
      backgroundColor: "#f9fafb",
      fontFamily: fontFam,
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      margin: 0,
    },
    title: {
      textAlign: "center",
      backgroundColor: "#fff",
      padding: 7,
      color: "#333",
      display: "block",
    },
    text: {
      color: "#D4AF37",
      lineHeight: 2,
      fontWeight: 600,
    },
    intructions: {
      border: "1px solid #D4AF37",
      borderRadius: "5px",
      padding: "1em",
      marginBottom: "1.5em",
      background: "white",
    },
    eligibleDateTitle: {
      textAlign: "center",
    },
    form: {
      padding: 40,
      background: "#eee",
    },
    datePicker: {
      marginBottom: "20px",
      marginTop: 10,
    },
    date: {
      "& input": {
        color: "#003760",
      },
      "& .MuiYearCalendar-root": {
        width: 700,
        color: "orange",
      },
    },
    result: {
      borderRadius: 5,
      margin: "20px 0",
      padding: 20,
      background: "white",
    },
  };
});

export default useCalcStyles;
