import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { useState, useEffect } from "react";
import { objectDeepEqualityCheck } from "./utils";
import { Edit, Close } from "@mui/icons-material";
import ContractRequestTextField from "./ContractRequestTextField";

const CustomListItem = withStyles({
  root: {
    backgroundColor: "white",
  },
})(ListItem);

const SubmitButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "rgba(0,128,0, .9)",
    "&:hover": { backgroundColor: "rgba(0,128,0, 1)" },
    width: 200,
  },
})(Button);

const defaultValues = {
  statesRequested: "",
  carrierName: null,
  carriernameId: "",
  licenseNumber: "",
  isAsCorp: false,
  corpName: "",
  corpNpn: "",
  corpTin: "",
  notes: "",
};

const ContractCollapseContents = (props) => {
  const {
    contractRequest,
    isCollapsed,
    updateOcr,
    isScan,
    isAnthem,
    carrierNameList,
  } = props;
  const [ocrUpdateInput, setOcrUpdateInput] = useState(defaultValues);
  const [editOcr, setEditOcr] = useState(false);
  const {
    statesRequested,
    carrierName,
    carriernameId,
    licenseNumber,
    isAsCorp,
    corpName,
    corpNpn,
    corpTin,
    notes,
  } = contractRequest;
  const setInputValue = (val, key) => {
    const carrier = carrierNameList.find(({ id }) => id === val);
    setOcrUpdateInput((ocrInput) => {
      return key === "carrier"
        ? { ...ocrInput, carrierName: carrier.name, carriernameId: carrier.id }
        : { ...ocrInput, [key]: val };
    });
  };

  useEffect(() => {
    if (!isCollapsed) {
      setOcrUpdateInput(defaultValues);
      setEditOcr(false);
    }
  }, [isCollapsed]);

  const makeSubmission = () => {
    setEditOcr(!editOcr);
    updateOcr(ocrUpdateInput);
  };

  const changesNotMade = objectDeepEqualityCheck(
    contractRequest,
    ocrUpdateInput
  );

  const secondaryStyleCheck = (bool) => {
    return {
      color: !bool ? "#053760" : "",
      fontWeight: !bool ? "" : "bold",
      fontStyle: !bool ? "italic" : "",
    };
  };

  const editOcrChange = () => {
    if (editOcr) {
      setOcrUpdateInput(defaultValues);
    } else {
      setOcrUpdateInput({
        ...contractRequest,
        statesRequested: statesRequested ?? "",
        carrierName: carrierName ?? null,
        carriernameId: carriernameId ?? null,
        licenseNumber: licenseNumber ?? "",
        corpName: corpName ?? "",
        corpNpn: corpNpn ?? "",
        corpTin: corpTin ?? "",
        notes: notes ?? "",
      });
    }
    setEditOcr(!editOcr);
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{ backgroundColor: "rgb(245 245 245)", padding: 5 }}
    >
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            style={{ color: editOcr ? "red" : "black" }}
            onClick={editOcrChange}
          >
            {editOcr ? <Close /> : <Edit />}
          </IconButton>
        </div>
        <form
          align="center"
          onSubmit={(e) => {
            e.preventDefault();
            changesNotMade ? void 0 : makeSubmission();
          }}
        >
          <Grid
            container
            justifyContent="flex-starts"
            spacing={1}
            style={{
              marginBottom: 5,
            }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem>
                {editOcr ? (
                  <TextField
                    label="State Requested"
                    value={ocrUpdateInput.statesRequested}
                    onChange={(e) =>
                      setInputValue(e.target.value, "statesRequested")
                    }
                    fullWidth
                  />
                ) : (
                  <ListItemText
                    primary="States Requested"
                    secondary={!statesRequested ? "No Data" : statesRequested}
                    secondaryTypographyProps={{
                      style: {
                        ...secondaryStyleCheck(statesRequested),
                      },
                    }}
                  />
                )}
              </CustomListItem>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CustomListItem>
                {editOcr ? (
                  <TextField
                    label="Carrier"
                    value={ocrUpdateInput.carriernameId}
                    onChange={(e) => setInputValue(e.target.value, "carrier")}
                    fullWidth
                    select
                  >
                    {carrierNameList.map(({ name, id }) => (
                      <MenuItem key={name} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <ListItemText
                    primary="Carrier"
                    secondary={!`${carrierName}` ? "No Data" : `${carrierName}`}
                    secondaryTypographyProps={{
                      style: {
                        ...secondaryStyleCheck(`${carrierName}`),
                      },
                    }}
                  />
                )}
              </CustomListItem>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                style={{
                  border: (
                    editOcr
                      ? `${ocrUpdateInput.carrierName}`
                          .toLowerCase()
                          .search("scan") !== -1 &&
                        !ocrUpdateInput.licenseNumber
                      : isScan && !licenseNumber
                  )
                    ? ".5px #ff7b00 solid"
                    : "",
                }}
                fieldToggle={editOcr}
                displayName="License Number"
                stateValue={ocrUpdateInput.licenseNumber}
                rowValue={licenseNumber}
                keyName="licenseNumber"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12}>
              <ContractRequestTextField
                fieldToggle={editOcr}
                displayName="Notes"
                stateValue={ocrUpdateInput.notes}
                rowValue={notes}
                keyName="notes"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid
              item
              style={{ backgoundColor: "red" }}
              xs={12}
              container
              alignItems="flax-start"
              justifyContent="center"
            >
              <Grid item xs={12} sm={4} md={4}>
                <ListItem>
                  <ListItemText primary="Contracting As Corp" />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="start"
                      checked={editOcr ? ocrUpdateInput.isAsCorp : isAsCorp}
                      disableRipple
                      onChange={(e) =>
                        setInputValue(e.target.checked, "isAsCorp")
                      }
                      disabled={!editOcr}
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                style={{
                  border: (
                    editOcr
                      ? ocrUpdateInput.isAsCorp && !ocrUpdateInput.corpName
                      : isAsCorp && !corpName
                  )
                    ? ".5px #ff7b00 solid"
                    : "",
                }}
                fieldToggle={editOcr}
                displayName="Corporation Name"
                stateValue={ocrUpdateInput.corpName}
                rowValue={corpName}
                keyName="corpName"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                style={{
                  border: (
                    editOcr
                      ? ocrUpdateInput.isAsCorp && !ocrUpdateInput.corpNpn
                      : isAsCorp && !corpNpn
                  )
                    ? ".5px #ff7b00 solid"
                    : "",
                }}
                fieldToggle={editOcr}
                displayName="Corporation NPN"
                stateValue={ocrUpdateInput.corpNpn}
                rowValue={corpNpn}
                keyName="corpNpn"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ContractRequestTextField
                style={{
                  border: (
                    editOcr
                      ? `${ocrUpdateInput.carrierName}`
                          .toLowerCase()
                          .search("anthem") !== -1 &&
                        ocrUpdateInput.isAsCorp &&
                        !ocrUpdateInput.corpTin
                      : isAnthem && isAsCorp && !corpTin
                  )
                    ? ".5px #ff7b00 solid"
                    : "",
                }}
                fieldToggle={editOcr}
                displayName="Corporation Tin"
                stateValue={ocrUpdateInput.corpTin}
                rowValue={corpTin}
                keyName="corpTin"
                secondaryStyleCheck={secondaryStyleCheck}
                setInputValue={setInputValue}
              />
            </Grid>
          </Grid>
          {editOcr && (
            <SubmitButton
              variant="contained"
              type="submit"
              disabled={changesNotMade}
            >
              Submit
            </SubmitButton>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

export default ContractCollapseContents;
