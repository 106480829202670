import {
  recruiterInfoById,
  updateRecruiter,
} from "../../../services/GraphQl/BDSWebService";
import {
  CLEAR_RECRUITER_INFO,
  SET_RECRUITER_INFO,
  SET_RECRUITER_INFO_ERROR,
  UPDATE_RECRUITER_INFO,
} from "../../constants/action-types";

export const setRecruiterInfo = () => {
  return (dispatch) => {
    const agencyId = sessionStorage.getItem("agencyId");
    recruiterInfoById(agencyId)
      .then((result) => {
        dispatch({ type: SET_RECRUITER_INFO, payload: result ? result : {} });
      })
      .catch((err) => {
        dispatch({ type: SET_RECRUITER_INFO_ERROR, payload: err });
      });
  };
};

export const updateRecruiterInfo = (input) => {
  return (dispatch) => {
    const agencyId = sessionStorage.getItem("agencyId");
    updateRecruiter({ id: agencyId, ...input })
      .then(() => {
        dispatch({ type: UPDATE_RECRUITER_INFO, payload: input });
      })
      .catch((err) => {
        dispatch({ type: SET_RECRUITER_INFO_ERROR, payload: err });
      });
  };
};

export const clearRecruiterInfo = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_RECRUITER_INFO });
  };
};
