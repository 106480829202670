import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close, FileCopy, Save } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import AwayImage from "../../img/SplashLogo.png";
import { QRCode } from "react-qrcode-logo";
import CommonDialogTitle from "../calculator/CommonDialogTitle";
import { connect } from "react-redux";
import { updateRecruiterInfo } from "../../store/actions/recruiter-info/recruiterInfo";
const defaultInput = {
  recruiterEmail: "",
  recruiterPhone: "",
};

const ShareAway = (props) => {
  const { openShareAway, closeModal, recruiterInfo, updateRecruiterInfo } =
    props;
  const { recruiterCode, recruiterEmail, recruiterPhone } = recruiterInfo;
  const [editActive, setEditActive] = useState(false);
  const [input, setInput] = useState(defaultInput);
  const theme = useTheme();
  const mQuery = useMediaQuery(theme.breakpoints.down("sm"));

  const inputChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const openEdit = () => {
    setInput({ recruiterEmail, recruiterPhone });
    setEditActive(!editActive);
  };

  const closeEdit = () => {
    setEditActive(!editActive);
    setInput(defaultInput);
  };

  const saveEdit = () => {
    updateRecruiterInfo(input);
    closeEdit();
  };

  const isStaging = sessionStorage.getItem("isStaging") === "true";
  const isLocalhost = window.location.hostname === "localhost";
  // This assumes you have AWAY running on port 3000 for localhost
  const url = isLocalhost
    ? `http://localhost:3000/Onboard/${recruiterCode}`
    : isStaging
    ? `http://berwick-agent-way-staging.s3-website.us-east-2.amazonaws.com/Onboard/${recruiterCode}`
    : `https://agentway.io/Onboard/${recruiterCode}`;
  return (
    <Dialog
      open={openShareAway}
      fullScreen={mQuery}
      onClose={() => closeModal("openShareAway")}
    >
      <CommonDialogTitle
        title="Onboard To Agent Way"
        close={() => closeModal("openShareAway")}
        variant="h6"
      />
      <DialogContent style={{ display: "grid", placeItems: "center" }}>
        <QRCode
          id={recruiterCode}
          logoWidth={100}
          logoHeight={100}
          logoImage={AwayImage}
          size={300}
          value={url}
          removeQrCodeBehindLogo
          qrStyle="dots"
          eyeColor="#003660"
          eyeRadius={[
            [10, 10, 0, 10], // top/left eye
            [10, 10, 10, 0], // top/right eye
            [10, 0, 10, 10], // bottom/left
          ]}
          ecLevel="H"
        />
        <div style={{ paddingBottom: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Typography style={{ wordBreak: "break-all" }}>
              <Link target="_blank" rel="noreferrer noopener" href={`${url}`}>
                {url}
              </Link>
            </Typography>
            <IconButton
              style={{ outline: "none" }}
              onClick={() => navigator.clipboard.writeText(url)}
            >
              <FileCopy fontSize="small" />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: 60,
            }}
          >
            <Typography style={{ fontWeight: 600 }}>
              Onboard Contact Info
            </Typography>
            {editActive ? (
              <ButtonGroup>
                <Button size="small" onClick={closeEdit}>
                  <Close color="error" />
                </Button>
                <Button size="small" onClick={saveEdit}>
                  <Save style={{ color: "green" }} />
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup size="small">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={openEdit}
                >
                  Edit / Add
                </Button>
              </ButtonGroup>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              minHeight: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
                gap: 2,
                marginRight: 2,
                width: "50%",
              }}
            >
              <Typography style={{ fontWeight: 600 }}>Email: </Typography>
              {editActive ? (
                <TextField
                  name="recruiterEmail"
                  value={input.recruiterEmail}
                  onChange={inputChange}
                />
              ) : (
                <Typography
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {recruiterEmail ?? "Add"}
                </Typography>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
                gap: 2,
                width: "50%",
              }}
            >
              <Typography style={{ fontWeight: 600 }}>Phone: </Typography>
              {editActive ? (
                <TextField
                  name="recruiterPhone"
                  value={input.recruiterPhone}
                  onChange={inputChange}
                />
              ) : (
                <Typography
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {recruiterPhone ?? "Add"}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  recruiterInfo: state.recruiterStore.recruiterInfo,
});

export default connect(mapStateToProps, { updateRecruiterInfo })(ShareAway);
