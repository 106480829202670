import {
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const CustomListItem = withStyles({
  root: {
    backgroundColor: "white",
  },
})(ListItem);

const ContractRequestTextField = (props) => {
  const {
    style,
    fieldToggle,
    displayName,
    stateValue,
    rowValue,
    keyName,
    secondaryStyleCheck,
    setInputValue,
  } = props;
  return (
    <CustomListItem style={style}>
      {fieldToggle ? (
        <TextField
          label={displayName}
          value={stateValue}
          onChange={(e) => setInputValue(e.target.value, keyName)}
          fullWidth
          autoComplete="off"
        />
      ) : (
        <ListItemText
          primary={displayName}
          secondary={!rowValue ? "No Data" : rowValue}
          secondaryTypographyProps={{
            style: {
              ...secondaryStyleCheck(rowValue),
            },
          }}
        />
      )}
    </CustomListItem>
  );
};

export default ContractRequestTextField;
