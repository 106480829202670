import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DateTime } from "luxon";
import { alpha, styled } from "@mui/material/styles";
import { getNotificationsApi } from "../../services/GraphQl/BDSWebService";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/en-US";
import clsx from "clsx";
import {
  DataGridPro,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import classes from "./DownLineCommunications.module.css";

const DownLineCommunications = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    const paging = {
      first: 10000,
      orderBy: { field: "lastModifiedDate", sort: "desc" },
    };
    const filters = [{ field: "agencyId", comparison: "==", value: agencyId }];
    const fetchData = async () => {
      if (agencyId !== null) {
        const results = await getNotificationsApi(paging, filters);
        setData(results.data.awayNotifications.data);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  /// Columns for the DataGrid
  /// The field property is the name of the property in the data object.
  /// The headerName property is the name of the column header.
  /// The width property is the width of the column.
  /// The headerAlign property is the alignment of the column header.
  /// The align property is the alignment of the column cells.
  /// The headerClassName property is the CSS class name for the column header.
  /// The type property is the type of column.
  /// The valueOptions property is the list of options for the column.
  /// The valueGetter property is the function to get the value for the column.
  /// The sortComparator property is the function to sort the column.
  /// The cellClassName property is the CSS class name for the column cells.
  const columns = [
    {
      field: "createDate",
      headerName: "Sent Date",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "dateTime",
      valueGetter: (params) => {
        if (!params.row.createDate) {
          return null;
        }
        return new Date(params.row.createDate);
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1 =
          cellParams1 && cellParams1.value ? new Date(cellParams1.value) : null;
        const date2 =
          cellParams2 && cellParams2.value ? new Date(cellParams2.value) : null;

        if (!date1 && !date2) {
          return 0;
        }
        if (!date1) {
          return -1;
        }
        if (!date2) {
          return 1;
        }

        return date1.getTime() - date2.getTime();
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 300,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "body",
      headerName: "Body",
      width: 500,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "agents",
      headerName: "Recipients",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "string",
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      valueOptions: ["Contract Request Submitted", "Contract Request Denial"],
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }
        return clsx("super-app", {
          submitted: params.value === "Contract Request Submitted",
          denied: params.value === "Contract Request Denial",
        });
      },
    },
  ];

  /// The uniqueRows array is used to store the unique rows.
  /// The dataRows array is used to store the data rows.
  const uniqueRows = [];
  const dataRows = data.map((row) => {
    const zone = DateTime.now().zoneName;
    const convertTimezone = new Date(row.createDate);
    return {
      id: row.createDate + row.subject,
      subject: row.subject,
      createDate: convertTimezone.toLocaleString("en-US", {
        timeZone: zone,
      }),
      body: row.body,
      agents: row.agents && row.agents.map((i) => i.displayName).join(", "),
      type: row.type,
    };
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const ODD_OPACITY = 0.2;

  /// StripedDataGrid is a styled version of DataGridPro
  /// The getRowClassName property is used to apply the even and odd CSS classes to the rows.
  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const sxBox = {
    height: 640,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
    "& .super-app.submitted": {
      color: "green",
      fontWeight: "600",
    },
    "& .super-app.denied": {
      color: "red",
      fontWeight: "600",
    },
  };

  const paginationProps = {
    pagination: true,
    pageSize: 25,
    rowsPerPageOptions: [25, 50, 100],
  };

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <Box sx={sxBox}>
          <StripedDataGrid
            slots={{ toolbar: CustomToolbar }}
            loading={isLoading}
            rows={uniqueRows}
            columns={columns}
            pagination={paginationProps}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </Box>
      </LocalizationProvider>
    </div>
  );
};

export default DownLineCommunications;
