import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Typography, Grid, Paper } from "@mui/material";

function AgentContactList(agents) {
  agents.forEach((agent) => {
    agent.displayname = agent.firstname + " " + agent.lastname;
  });

  const columns = [
    {
      Header: "Name",
      accessor: "displayname",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      maxWidth: 200,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      // width: 300,
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      maxWidth: 120,
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      // width: 300,
    },
    {
      Header: "City",
      accessor: "city",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      maxWidth: 175,
    },
    {
      Header: "State",
      accessor: "state",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      maxWidth: 50,
    },
    {
      Header: "Zip",
      accessor: "zip",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      maxWidth: 100,
    },
  ];

  return (
    <Grid item direction="row" alignItems="center" xs={11}>
      <Paper style={{ marginTop: 10 }}>
        <ReactTable data={agents} columns={columns} />
      </Paper>
    </Grid>
  );
}

export default AgentContactList;
