import React from "react";
import withStyles from "@mui/styles/withStyles";
import footerStyle from "../../assets/jss/material-dashboard-react/components/footerStyle";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField
} from "@mui/material";
import ReactGA from "react-ga";

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      handleClose,
      handleSubmit,
      handleNameFieldChange,
      handleEmailFieldChange,
      handleFeedbackFieldChange,
      nameValue,
      emailValue,
      feedbackValue
    } = this.props;

    ReactGA.event({
      category: "Feedback",
      action: "Open",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " opened the feedback dialog."
    });
    return (
      <div data-tour="ad__feedbackDialog">
        <DialogTitle
          style={{
            textAlign: "center",
            color: "#eee",
            backgroundColor: "#444242"
          }}
          fullWidth
        >
          Provide Your Feedback Below
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              display: "flex",
              flexWrap: "wrap",
              color: "#eee",
              width: "100%"
            }}
          >
            What's on your mind? How can we make this experience better for you?
          </DialogContentText>
          <DialogContentText
            style={{
              display: "flex",
              flexWrap: "wrap",
              color: "#eee",
              width: "100%"
            }}
          >
            Send us bugs or glitches you find, compliments or complaints, and
            any other feedback or suggestions you may have!
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%"
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="filled-name-input"
                label="Name"
                margin="normal"
                variant="filled"
                autoComplete="name"
                required
                fullWidth
                classes={{ root: classes.root }}
                value={nameValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                id="filled-email-input"
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="filled"
                required
                fullWidth
                classes={{ root: classes.root }}
                value={emailValue}
                onChange={handleEmailFieldChange}
              />
              <TextField
                multiline
                id="filled-feedback-input"
                label="Feedback"
                rows="6"
                fullWidth
                margin="normal"
                variant="filled"
                required
                classes={{ root: classes.root }}
                value={feedbackValue}
                onChange={handleFeedbackFieldChange}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: "#444242",
            padding: 22
          }}
          fullWidth
        >
          <Button
            onClick={handleSubmit}
            style={{ color: "#eee", backgroundColor: "#3f51b5" }}
            fullWidth
          >
            Submit
          </Button>
          <Button
            onClick={handleClose}
            style={{ backgroundColor: "#fff176", width: 200 }}
          >
            Cancel
          </Button>
        </DialogActions>
      </div>
    );
  }
}

export default withStyles(footerStyle)(FeedbackModal);
