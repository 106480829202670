import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import {
  pushNotificationApi,
  sendMsGraphEmail,
  sendDashEmailAws,
} from "../../services/GraphQl/BDSWebService";
import Swal from "sweetalert";

export default class SendNotificationsDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filteredValues: this.props.currentAgents,
      body: null,
      subject: null,
      to: [],
      cc: [],
      bcc: [],
      toError: "",
      ccError: "",
      bccError: "",
      errorMessageTo: "",
      errorMessageCc: "",
      errorMessageBcc: "",
    };
  }

  handleChange = (e, state) => {
    const { value } = e.target;
    this.setState({ [state]: value });
  };

  clearForm = () => {
    this.setState({
      filteredValues: [],
      body: null,
      subject: null,
      to: [],
      cc: [],
      bcc: [],
      toError: "",
      ccError: "",
      bccError: "",
      errorMessageTo: "",
      errorMessageCc: "",
      errorMessageBcc: "",
    });
  };

  closeAll = () => {
    this.clearForm();
    this.props.onClose();
  };

  handleSentNotes = async () => {
    let { filteredValues, body, subject } = this.state;
    if (filteredValues.length === 0) {
      filteredValues = this.props.currentAgents;
    }

    if (filteredValues.length === 0 || subject === null || body === null) {
      alert("Validation Error");
      return;
    }

    const agencyId = sessionStorage.getItem("agencyId");
    var input = {
      body,
      subject,
      agencyId,
      agentIds: filteredValues.map((i) => parseInt(i.agentId)),
      type: "Notification",
    };
    const res = await pushNotificationApi(input);
    this.closeAll();
  };

  apiChunkCall = async (currentEmails, to, cc, bcc, subject, body) => {
    const isStaging =
      sessionStorage.getItem("isStaging") === "true" ? true : false;
    try {
      const chunk = 50;
      let i,
        j,
        temparray,
        firstHit = true;
      for (i = 0, j = currentEmails.length; i < j; i += chunk) {
        temparray = currentEmails.slice(i, i + chunk);
        await sendDashEmailAws({
          FromEmailAddress: "info-blast@theagencydashboard.com",
          Destination: {
            ToAddresses: to,
            CcAddresses: firstHit ? cc : [],
            BccAddresses: isStaging
              ? ["bit.testing@berwickInsurance.com"]
              : firstHit
              ? [...bcc, ...temparray]
              : temparray,
          },
          Content: {
            Simple: {
              Subject: { Data: subject },
              Body: { Text: { Data: body } },
            },
          },
        });
        firstHit = false;
      }
      return "Success";
    } catch (error) {
      const agencyId = sessionStorage.getItem("agencyId");
      await sendMsGraphEmail({
        from: "bit@berwickinsurance.com",
        to: [
          "j.taylor@berwickinsurance.com",
          "eli.london@berwickinsurance.com",
        ],
        subject: `Error Sending Email for Agency ${agencyId}`,
        body: `${error}`,
      });
      return "There was a problem sending your email - an email was sent to our administrators to look into it.";
    }
  };

  sendEmail = async () => {
    const { subject, body, to, cc, bcc, filteredValues } = this.state;
    const { currentAgents } = this.props;
    const currentEmails =
      filteredValues.length > 0
        ? filteredValues.map(({ email }) => email)
        : currentAgents.map(({ email }) => email);
    if (filteredValues.length === 0) {
      Swal({
        text: "Please know you are about to email all Agents in your Agency, please click confirm to proceed.",
        title: "Email Confirmation",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (proceed) => {
        if (proceed) {
          var result = await this.apiChunkCall(
            currentEmails,
            to,
            cc,
            bcc,
            subject,
            body
          );
          const success = result === "Success";
          Swal({
            text: success ? "Your email was sent!" : result,
            title: success ? result : "Error",
            icon: success ? "success" : "error",
            dangerMode: !success,
          }).then(() => this.closeAll());
        }
      });
    } else {
      const result = await this.apiChunkCall(
        currentEmails,
        to,
        cc,
        bcc,
        subject,
        body
      );
      const success = result === "Success";
      Swal({
        text: success ? "Your email was sent!" : result,
        title: success ? result : "Error",
        icon: success ? "success" : "error",
        dangerMode: !success,
      }).then(() => this.closeAll());
    }
  };

  validateEmailFromArr(arr) {
    var error = "";
    arr.map((email) => {
      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email)) {
        error = `Invalid email address: ${email}`;
      }
    });
    return error;
  }

  handleBlur = (e, type) => {
    if (e.target.value !== "") {
      this.setState({
        [`errorMessage${type}`]: `You must press enter to add ${e.target.value} to the ${type} list`,
      });
    }
  };

  onFocus = (type) => {
    this.setState({ [`errorMessage${type}`]: "" });
  };

  render() {
    const { onClose, open, currentAgents, title } = this.props;
    const {
      subject,
      body,
      to,
      cc,
      bcc,
      toError,
      ccError,
      bccError,
      errorMessageTo,
      errorMessageCc,
      errorMessageBcc,
    } = this.state;
    const isEmail = title === "Email";
    return (
      <Dialog
        open={open}
        onClose={this.onCloseAll}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="note-dialog-title">Send {title}</DialogTitle>
        <DialogContent>
          {isEmail && (
            <>
              *Emails are sent via our system email address - add cc or bcc
              below for any additional emails
              <br />
              <br />
              *Agents listed at the bottom will be sent an email through the Bcc
              field in addition to any emails you add
              <Autocomplete
                multiple
                options={to}
                getOptionLabel={(option) => option}
                freeSolo
                defaultValue={to}
                style={{ marginTop: 10 }}
                onBlur={(e) => this.handleBlur(e, "To")}
                onFocus={() => this.onFocus("To")}
                onChange={(e, newValue) => {
                  const error = this.validateEmailFromArr(newValue);
                  error
                    ? this.setState({ toError: error })
                    : this.setState({ to: newValue, toError: "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please insert your email address in this field"
                    required
                    error={toError || errorMessageTo}
                    helperText={
                      <Grid container>
                        <Grid item xs={12}>
                          {toError}
                        </Grid>
                        <Grid item xs={12}>
                          {errorMessageTo}
                        </Grid>
                      </Grid>
                    }
                  />
                )}
              />
              <Autocomplete
                multiple
                options={cc}
                getOptionLabel={(option) => option}
                freeSolo
                defaultValue={cc}
                style={{ marginTop: 10 }}
                onBlur={(e) => this.handleBlur(e, "Cc")}
                onFocus={() => this.onFocus("Cc")}
                onChange={(e, newValue) => {
                  const error = this.validateEmailFromArr(newValue);
                  error
                    ? this.setState({ ccError: error })
                    : this.setState({ cc: newValue, ccError: "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cc"
                    error={ccError || errorMessageCc}
                    helperText={
                      <Grid container>
                        <Grid item xs={12}>
                          {ccError}
                        </Grid>
                        <Grid item xs={12}>
                          {errorMessageCc}
                        </Grid>
                      </Grid>
                    }
                  />
                )}
              />
              <Autocomplete
                multiple
                options={bcc}
                getOptionLabel={(option) => option}
                freeSolo
                defaultValue={bcc}
                style={{ marginTop: 10 }}
                onBlur={(e) => this.handleBlur(e, "Bcc")}
                onFocus={() => this.onFocus("Bcc")}
                onChange={(e, newValue) => {
                  const error = this.validateEmailFromArr(newValue);
                  error
                    ? this.setState({ bccError: error })
                    : this.setState({ bcc: newValue, bccError: "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bcc"
                    error={bccError || errorMessageBcc}
                    helperText={
                      <Grid container>
                        <Grid item xs={12}>
                          {bccError}
                        </Grid>
                        <Grid item xs={12}>
                          {errorMessageBcc}
                        </Grid>
                      </Grid>
                    }
                  />
                )}
              />
            </>
          )}
          {title === "Notification" && (
            <Typography
              variant="caption"
              align="center"
              component="p"
              style={{ color: "#333", fontSize: 16 }}
            >
              Note: ONLY agents that have
              <br /> activated AgentWay can be sent notifications
            </Typography>
          )}
          <TextField
            required
            margin="dense"
            id="subject"
            label="Subject"
            value={subject}
            onChange={(e) => this.handleChange(e, "subject")}
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="body"
            label="Message"
            multiline
            rows={2}
            value={body}
            onChange={(e) => this.handleChange(e, "body")}
            fullWidth
          />
          <Autocomplete
            multiple
            limitTags={1}
            id="multiple-limit-tags"
            style={{ marginTop: 10 }}
            options={currentAgents}
            getOptionLabel={(option) =>
              option.firstname + " " + option.lastname
            }
            defaultValue={currentAgents}
            onChange={(e, newValue) => {
              this.setState({ filteredValues: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="AgentList"
                placeholder="Agent"
                helperText={
                  <Typography variant="caption" color="error">
                    {!isEmail && currentAgents.length === 0
                      ? `No Agents Currently Registered With Agent Way`
                      : ""}
                  </Typography>
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isEmail) {
                this.setState({ errorMessageCc: "", errorMessageBcc: "" });
              }
              onClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={isEmail ? this.sendEmail : this.handleSentNotes}
            disabled={
              isEmail
                ? !subject || !body || to.length === 0
                : !subject || !body || currentAgents.length === 0
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
