import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import LineRechartComponent from "./LineRechartComponent";
import {
  getProductionByCarrierAndQtr,
  getLatestProductionSalesYear,
} from "../../services/GraphQl/BDSWebService";

const ProductionInformation = (props) => {
  const { agencyId, classes } = props;
  const [data, setData] = useState([]);
  const [latestSalesYear, setLatestSalesYear] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getProductionByCarrierAndQtr(agencyId).then((data) => {
        setData(data.data.getProductionByCarrierAndQtr);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getLatestProductionSalesYear().then((data) => {
        setLatestSalesYear(data.data.getLatestProductionSalesYear);
      });
    };
    fetchData();
  }, []);

  if (data.length !== 0) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            className={classes.typography}
            gutterBottom
            variant="h5"
            component="h3"
            style={{ padding: 4 }}
          >
            Production By Year and Quarter
          </Typography>
          <Paper className={classes.paper}>
            <LineRechartComponent
              data={data}
              latestSalesYear={latestSalesYear}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export default ProductionInformation;
