import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { getCertsPerCarrier } from "../../services/GraphQl/BDSWebService.js";

const styles = () => ({
  card: {
    background: "linear-gradient(to bottom right,  white, #0e76bc) !important",
    minHeight: "fit-content",
  },
  typography: {
    color: "#003760",
  },
  table: {
    padding: 10,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 34,
    width: 3,
    backgroundColor: "grey",
  },
});

const RTSByCarrier = ({ classes }) => {
  const [carriers, setCarriers] = useState([]);
  useEffect(() => {
    (async () => {
      const agencyId = sessionStorage.getItem("agencyId");
      const res = await getCertsPerCarrier(parseInt(agencyId));
      setCarriers(res);
    })();
  }, []);

  return (
    <>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        Ready to Sell (RTS) Agents by Carrier ({`${carriers[0]?.planYear}`})
      </Typography>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Carrier Name</TableCell>
              <TableCell align="center">RTS Agent Count</TableCell>
              <TableCell align="center">Plan Year</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carriers.map((carrier, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {carrier.name}
                </TableCell>
                <TableCell align="center">{carrier.count}</TableCell>
                <TableCell align="center">{carrier.planYear}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default withStyles(styles)(RTSByCarrier);
