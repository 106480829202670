import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
const ProductionStatisticsTotals = (props) => {
  const { productionStatisticsToggle } = props;
  const maTotal = useSelector((state) =>
    productionStatisticsToggle
      ? state.currentProduction.filteredProductionTotals.maTotal
      : state.planChange.filteredPlanChangeTotals.maTotal
  );
  const msTotal = useSelector((state) =>
    productionStatisticsToggle
      ? state.currentProduction.filteredProductionTotals.msTotal
      : state.planChange.filteredPlanChangeTotals.msTotal
  );
  const pdpTotal = useSelector((state) =>
    productionStatisticsToggle
      ? state.currentProduction.filteredProductionTotals.pdpTotal
      : state.planChange.filteredPlanChangeTotals.pdpTotal
  );

  const items = [
    { label: "Medicare Advantage", total: maTotal },
    { label: "Medicare Supplement", total: msTotal },
    { label: "Prescription Drug", total: pdpTotal },
  ];
  const sxTypography = { color: "white" };
  return (
    <Typography sx={sxTypography}>
      {items.map((item, index) => (
        <Box key={index}>
          {item.label}: {item.total}
        </Box>
      ))}
    </Typography>
  );
};

export default ProductionStatisticsTotals;
