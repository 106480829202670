import React from "react";
import {
  Select,
  Paper,
  Button,
  MenuItem,
  ButtonGroup,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  AccountBalance,
  AllOut,
  Adjust,
  Equalizer,
  Assessment,
  NoEncryption,
} from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import BallotIcon from "@mui/icons-material/Ballot";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const theme1 = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#3f51b5",
        },
      },
    },
  },
});

const disabledBtn = {
  "&:disabled": {
    "-webkit-text-decoration": "none",
    textDecoration: "none",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "5px 5px 0 0",
    },
  },
};

const styles = (theme) => ({
  root: {
    minWidth: 60,
    padding: 0,
    textAlign: "center",
    color: "#000",
    fontWeight: "bold",
    backgroundColor: "green",
  },
});

class CarrierTab extends React.Component {
  handleBreakoutProductionChange = (event) => {
    event.preventDefault();
    this.props.handleBreakoutProductionChange();
  };

  handleShowAllContractsChange = (event) => {
    event.preventDefault();
    this.props.handleShowAllContractsChange(this.props.agent);
  };

  handleChange = (name, setShowAllContracts) => (event) => {
    if (event.target.value === 99) {
      setShowAllContracts(this.props.agent, true);
    } else {
      const selectContract = this.props.agentCarriers[event.target.value];
      this.props.onSelectContract(selectContract);
      setShowAllContracts(this.props.agent, false);
    }
  };

  renderView = () => {
    const {
      classes,
      agentCarriers,
      currentContract,
      showAllContracts,
      setShowAllContracts,
      toggleShowallCerts,
      openCerts,
      openProducts,
    } = this.props;

    const currentIndex = agentCarriers.indexOf(currentContract);
    var value = currentIndex === -1 ? 0 : currentIndex;
    if (showAllContracts) {
      value = 99;
    }
    if (!openCerts && !openProducts) {
      if (agentCarriers.length > 1) {
        return (
          <ThemeProvider theme={theme1}>
            <Select
              disableUnderline={true}
              component={<Button />}
              value={value}
              sx={{
                fontSize: "1em",
                backgroundColor: "#3f51b5",
                color: "#fff",
                p: 1,
                borderRadius: "0",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #484850",
                },
                "&:visited": { backgroundColor: "#3f51b5" },
              }}
              onChange={this.handleChange("value", setShowAllContracts)}
              name="value"
              size="small"
              variant="standard"
            >
              <MenuItem key={99} value={99}>
                All
              </MenuItem>
              {agentCarriers.map((contractItem, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {contractItem.is_corporation && (
                      <AccountBalance style={{ fontSize: "1.5em" }}>
                        {"  "}
                      </AccountBalance>
                    )}
                    {contractItem.carrier}
                  </MenuItem>
                );
              })}
            </Select>
          </ThemeProvider>
        );
      } else {
        return (
          <Button
            size="small"
            variant="contained"
            disabled
            sx={{
              ...disabledBtn,
              "&:disabled": { backgroundColor: "#3f51b5", color: "#eee" },
            }}
          >
            {agentCarriers[0].carrier + "  "}
            {agentCarriers[0].is_corporation && (
              <AccountBalance style={{ fontSize: "1.5em" }}> </AccountBalance>
            )}
          </Button>
        );
      }
    }
  };

  render() {
    const {
      currentContract,
      classes,
      handleShowAllContractsChange,
      showAllContracts,
      agent,
      breakoutProduction,
      toggleShowallCerts,
      openCerts,
      openProducts,
      filterDataByYear,
      handleChangeYear,
      selectedPlanYear,
      certsData,
      planYears,
    } = this.props;

    //backgroundColor: "#3949ab";
    // sx={{backgroundColor: "#3f51b5", '&:hover': {backgroundColor: "#3949ab"}}

    return (
      <Paper
        style={{
          width: "100%",
          height: "2.4em",
          padding: 0,
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <ButtonGroup variant="contained" sx={{ height: "2.4em" }}>
            <Tooltip
              title={
                !showAllContracts ? "Show All Contracts" : "Hide all contracts"
              }
            >
              <Button
                onClick={this.handleShowAllContractsChange}
                data-tour="ad__showAllContracts"
                sx={{
                  backgroundColor: "#3f51b5",
                  "&:hover": { backgroundColor: "#3949ab" },
                }}
              >
                {!showAllContracts ? (
                  <AllOut style={{ fontSize: "18px" }} />
                ) : (
                  <Adjust style={{ fontSize: "18px" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip
              title={
                !breakoutProduction
                  ? "Expand agents production"
                  : "Hide agents production"
              }
            >
              <Button
                onClick={this.handleBreakoutProductionChange}
                data-tour="ad__showAgentProduction"
                sx={{
                  backgroundColor: "#3f51b5",
                  "&:hover": { backgroundColor: "#3949ab" },
                }}
              >
                {!breakoutProduction ? (
                  <Equalizer style={{ fontSize: "18px" }} />
                ) : (
                  <Assessment style={{ fontSize: "18px" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title={!openCerts ? "Show RTS Certs" : "Hide RTS Certs"}>
              {!openProducts || breakoutProduction || showAllContracts ? (
                <Button
                  id="rtsButton"
                  onClick={toggleShowallCerts}
                  variant="contained"
                  sx={{
                    backgroundColor: "#3f51b5",
                    "&:hover": { backgroundColor: "#3949ab" },
                  }}
                  size="small"
                  data-tour="ad__rtsCertsTable"
                >
                  {!openCerts ? (
                    <BallotIcon style={{ fontSize: "18px", color: "#fff" }} />
                  ) : (
                    <BallotOutlinedIcon
                      style={{ fontSize: "18px", color: "#fff" }}
                    />
                  )}
                </Button>
              ) : (
                <Tooltip title="Back to RTS Certs">
                  <Button
                    onClick={toggleShowallCerts}
                    variant="contained"
                    sx={{
                      backgroundColor: "#3f51b5",
                      "&:hover": { backgroundColor: "#3949ab" },
                    }}
                    size="small"
                    style={{ padding: 6 }}
                  >
                    <CancelPresentationIcon
                      style={{ fontSize: "18px", color: "#fff" }}
                    />
                  </Button>
                </Tooltip>
              )}
            </Tooltip>
            {!breakoutProduction ? this.renderView() : null}
            {!showAllContracts && !openCerts && !openProducts ? (
              <Button
                sx={{
                  pointerEvents: "none",
                  color: "#333",
                  backgroundColor: "transparent",
                }}
              >
                {"  "}
                Level : {currentContract.agent_level}
              </Button>
            ) : null}
            {openCerts ? (
              <ThemeProvider theme={theme1}>
                <Select
                  data-tour="ad__rtsCertsTablePlanYears"
                  value={selectedPlanYear}
                  onChange={(e) => handleChangeYear(e)}
                  disableUnderline={true}
                  component={<Button />}
                  variant="standard"
                  sx={{
                    fontSize: "1em",
                    outline: "none",
                    backgroundColor: "#3f51b5",
                    color: "#fff",
                    p: 1,
                    borderRadius: "0 5px 5px 0",
                    "&:visited": { backgroundColor: "#3f51b5" },
                  }}
                >
                  {planYears.map((year, index) => {
                    return (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </ThemeProvider>
            ) : null}
          </ButtonGroup>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CarrierTab);
