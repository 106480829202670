import React from "react";
import LineChartModel from "./LineChartModel";
import { Grid, Typography } from "@mui/material";

const LineRechartComponent = (props) => {
  const { data, latestSalesYear } = props;

  const maData = getProductData(data, "MA");
  const msData = getProductData(data, "MS");
  const pdpData = getProductData(data, "PDP");

  const year3 = latestSalesYear.map((data) => data.salesYear);
  const year2 = year3 - 1;
  const year1 = year3 - 2;

  return (
    <div>
      <Grid container direction="row" alignItems="flex-center" spacing={1}>
        <Grid item xs={4}>
          <Typography
            style={{ color: "#F3A3F1", fontWeight: "bold", fontSize: 20 }}
            align="justify"
          ></Typography>{" "}
        </Grid>
        <Grid item xs={2}>
          <Typography
            style={{ color: "#F3A3F1", fontWeight: "bold", fontSize: 20 }}
            align="justify"
          >
            {year1}
          </Typography>{" "}
        </Grid>
        <Grid item xs={2}>
          <Typography
            style={{ color: "#040400", fontWeight: "bold", fontSize: 20 }}
            align="justify"
          >
            {year2}
          </Typography>{" "}
        </Grid>
        <Grid item xs={1}>
          <Typography
            style={{ color: "#33A1FF", fontWeight: "bold", fontSize: 20 }}
            align="justify"
          >
            {year3}
          </Typography>{" "}
        </Grid>
      </Grid>

      <LineChartModel lineData={maData} product="MA" />
      <LineChartModel lineData={msData} product="MS" />
      <LineChartModel lineData={pdpData} product="PDP" />
    </div>
  );
};

const getProductData = (productData, product) => {
  const lineDataByProduct = productData.filter(
    (item) => item.product === product
  );
  const lineData = reduceData(lineDataByProduct);
  return lineData;
};

const reduceData = (productData) => {
  const Q123Out = [];
  const Q4Out = [];

  productData.reduce((acc, currentObj) => {
    if (acc.quarter === undefined) {
      acc = { quarter: currentObj.quarter, Year1: 0, Year2: 0, Year3: 0 };
    }

    if (acc.quarter !== currentObj.quarter) {
      Q123Out.push(acc);
      acc = { quarter: currentObj.quarter, Year1: 0, Year2: 0, Year3: 0 };
    }

    acc.Year1 += currentObj.Year1;
    acc.Year2 += currentObj.Year2;
    acc.Year3 += currentObj.Year3;

    if (currentObj.quarter === "Q4") {
      Q4Out.pop();
      Q4Out.push(acc);
    }

    return acc;
  }, {});
  return Q123Out.concat(Q4Out);
};

export default LineRechartComponent;
