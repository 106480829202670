import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { FormControl, Select, Chip, MenuItem, Input } from "@mui/material/";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: 2,
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    fontSize: "calc(8px + (20 - 8) * ((100vw - 250px) / (4000 - 250)))",
    lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    top: "-0%",
    color: "#003760",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 4,
    fontSize: "calc(0.3vw + 0.6vh)",
    color: "#eee",
    backgroundColor: "#003760",
    borderRadius: 5,
  },
  noLabel: {
    marginBottom: 10,
    "&:before": {
      borderColor: "#000",
    },
    "&:after": {
      borderColor: "#000",
    },
  },
  icon: {
    fill: "#000",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, that) {
  return {
    fontWeight:
      that.props.options.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

const mapStateToProps = (state) => {
  return {
    agencies: state.agentListAgencies,
  };
};

class ConnectedSelectWithState extends React.Component {
  handleChange = (event) => {
    this.props.searchHandler(event);
  };

  // handleChangeMultiple = event => {
  //   const { options } = event.target;
  //   const value = [];
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     if (
  //       options[i].selected &&
  //       this.props.agentListAgencies.contains(options[i])
  //     ) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   this.props.options = value;
  // };

  render() {
    const { classes, data, label, options } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Select
            disableUnderline
            className={classes.noLabel}
            aria-label={label}
            multiple
            displayEmpty
            fullWidth
            value={options}
            onChange={this.handleChange}
            input={<Input id="select-multiple-chip" />}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {data && !data.id
              ? data.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    aria-label={label}
                    style={getStyles(name, this)}
                  >
                    {name}
                  </MenuItem>
                ))
              : data && data.id
              ? data.map((id, name) => (
                  <MenuItem key={id} value={id} style={getStyles(name, this)}>
                    {name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const SelectWithState = connect(mapStateToProps, {})(ConnectedSelectWithState);

SelectWithState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectWithState);
