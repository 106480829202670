import React, { Component } from "react";
import { List, ListItemText, Paper } from "@mui/material/";
import withStyles from "@mui/styles/withStyles";
import "react-sortable-tree/style.css";
import stringUtls from "../../utils/stringUtils";

const styles = theme => {
  const paperStyle = {
    textAlign: "center",
    color: "#0092d7",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em"
    }
  };
  const listItemStyle = {
    color: "white",
    paddingLeft: "5px",
    borderStyle: "solid",
    borderColor: "gray",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em"
    }
  };
  return {
    paperStyle,
    paper: {
      ...paperStyle,
      fontSize: "0.8em"
    },
    paperInSingleScreen: {
      ...paperStyle,
      fontSize: "1.5em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8em"
      }
    },
    root: {
      height: "100%",
      margin: " 5px",
      marginLeft: "15px"
    },
    listItem: {
      ...listItemStyle
    },
    listItemInSingleScreen: {
      ...listItemStyle,
      fontSize: "1.5em"
    }
  };
};

class AgentHierarchy3 extends Component {
  spliteTwoParts = element => {
    const index = element.indexOf("(");
    const firstPart = stringUtls.firstUpperLetter(
      element.substring(0, index).trim()
    );
    const secondPart = stringUtls.firstUpperLetter(
      element.substring(index, element.length).trim()
    );
    return firstPart + " : " + secondPart;
  };
  render() {
    const { agentUpline, classes, isSingleView } = this.props;
    if (!agentUpline) return null;
    const paperClasses = isSingleView
      ? classes.paperInSingleScreen
      : classes.paper;
    const listItemClasses = isSingleView
      ? classes.listItemInSingleScreen
      : classes.listItem;
    var agentUplineArray = agentUpline.split("<").reverse();
    agentUplineArray.shift(); // to remove first item Berwick one

    return (
      <div className={classes.root}>
        <Paper className={paperClasses} square elevation={0}>
          Agent Hierarchy
        </Paper>
        <List component="nav" aria-label="Contacts">
          {agentUplineArray.map((element, index) => {
            return (
              <ListItemText
                key={index}
                disableTypography
                className={listItemClasses}
                primary={this.spliteTwoParts(element)}
              />
            );
          })}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(AgentHierarchy3);
