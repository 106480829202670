import { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const OcrRadioGroup = (props) => {
  const {
    contractRequest,
    currentClickedRow,
    setRequestsToProcess,
    clearSelected,
    setClearSelected,
    radioDisabled,
  } = props;
  const [radioValue, setRadioValue] = useState("");
  const requestId = contractRequest.ocrId;

  const toggleRadioPerContractRequest = (e) => {
    const status = e.target.value;
    const insertVal = { ...contractRequest, status };
    setRequestsToProcess((req) => {
      const valueExists = req.some((req) => req.ocrId === requestId);
      return valueExists
        ? req.map((row) => (row.ocrId === requestId ? insertVal : row))
        : [...req, insertVal];
    });
    setRadioValue(status);
  };

  useEffect(() => {
    if (currentClickedRow === false || clearSelected === true) {
      setRequestsToProcess([]);
      setRadioValue("");
      setClearSelected(false);
    }
  }, [currentClickedRow, clearSelected]);

  return (
    <RadioGroup value={radioValue} onChange={toggleRadioPerContractRequest} row>
      <FormControlLabel
        style={{ color: radioDisabled ? "grey" : "green" }}
        value="send"
        control={
          <Radio style={{ color: "inherit" }} disabled={radioDisabled} />
        }
        label="Send"
      />
      <FormControlLabel
        value="deny"
        style={{ color: "red" }}
        control={<Radio style={{ color: "inherit" }} />}
        label="Deny"
      />
    </RadioGroup>
  );
};
export default OcrRadioGroup;
