import React from "react";
import { Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const HtmlToolTip = (props) => {
  const { backgroundColor, color, title, children, placement } = props;
  const useStyles = makeStyles()((theme) => {
    return {
      tooltip: {
        backgroundColor: backgroundColor ?? "#f5f5f9",
        color: color ?? "rgba(0, 0, 0, 0.87)",
        maxWidth: 350,
        wordWrap: "break-word",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
      },
    };
  });

  const { classes } = useStyles();
  return (
    <Tooltip placement={placement} title={title} classes={classes}>
      {children}
    </Tooltip>
  );
};
export default HtmlToolTip;
