import React from "react";
import Reactour from "reactour";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { withRouter } from "react-router-dom";
import { Typography } from "@mui/material/";

const AgentListTour = withRouter(
  ({
    isOpen,
    closeTour,
    location: { pathname },
    history,
    onBeforeClose,
    onAfterOpen,
  }) => {
    var HasProduction = sessionStorage.getItem("HasProduction");

    const steps = [
      {
        selector: "",
        content: () => (
          <Typography style={{ paddingTop: 5 }}>
            Welcome to your Agents page!
          </Typography>
        ),
      },
      {
        selector: '[data-tour="ad__agencyMenu"]',
        content:
          "Here you will find a useful menu where you can refine your search for agent information!",
      },
      {
        selector: '[data-tour="ad__agencyPill"]',
        content:
          "We will show your agency's total MA and MS production for the current year, as well as the top producing agents in each category!",
      },
      {
        selector: '[data-tour="ad__resetAndDownload"]',
        content:
          "These buttons are really useful if you need to reset your search results or download the current filtered data. Data that is downloaded will be in an Excel format, and includes phone and email for every agent!",
      },
      {
        selector: '[data-tour="ad__filterSelectors"]',
        content:
          "Dropdowns here will help you filter the agents so you can see only the agents you need.",
      },
      {
        selector: '[data-tour="ad__pagination2"]',
        content:
          "You can go directly to a page or letter to filter the agent area.",
        action: (node) => {
          //scroll to the top of the window to allow for the next step's focus to be in view despite the top navbar.
          window.scrollTo(1, 1);
        },
      },
      {
        selector: '[data-tour="ad__showAllContracts"]',
        content:
          "Pressing this button will allow you to see all contracts for a single agent. Go ahead and press it to see all contracts for this agent. It can also do this for all agents currently filtered if you click above!",
      },
      {
        selector: '[data-tour="ad__showAgentProduction"]',
        content:
          "Pressing this button will show a production summary for just this agent (try it out!).  Pressing it again will return to the contract view",
      },
      {
        selector: '[data-tour="ad__contractData"]',
        content:
          "This area displays contract information such as writing # and status. Hovering over the status will give you a more detailed description!",
      },
      {
        selector: '[data-tour="ad__appbar_production"]',
        content: "Now let's move on to your production page.",
      },
      HasProduction !== "false"
        ? {
            selector: "",
            content: "",
            action: () =>
              history.push({
                pathname: "/production",
                state: {
                  tourIsOpen: true,
                  carrier: "1",
                },
              }),
          }
        : {
            selector: "",
            content: "",
            action: () =>
              history.push({
                pathname: "/main",
                state: {
                  tourIsOpen: false,
                },
              }),
          },
    ];

    return (
      <Reactour
        onAfterOpen={onAfterOpen}
        onBeforeClose={onBeforeClose}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={() => {
          closeTour();
          clearAllBodyScrollLocks();
        }}
        update={pathname}
        closeWithMask={false}
        rounded={5}
      />
    );
  }
);

export default AgentListTour;
