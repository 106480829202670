import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";
import Typography from "@mui/material/Typography";
import { ZoomIn } from "@mui/icons-material";
const styles = (theme) => {
  const titleRoot = {
    fontSize: "2em",
    color: "#003760",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  const tableTextRoot = {
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  };
  return {
    titleRoot,
    tableTextRoot,
    card: {
      height: "100%",
      backgroundColor: "#FFFFFF",
      padding: 0,
      margin: 0,
    },
    title: {
      ...titleRoot,
      fontSize: "1em",
    },
    titleInSingleView: {
      ...titleRoot,
    },
    table: {
      padding: 0,
    },
    tableText: {
      ...tableTextRoot,
      fontSize: "0.8em",
    },
    tableTextInSingleView: {
      ...tableTextRoot,
    },
    Tooltip: {},
    rowHover: {
      cursor: "pointer",
      "&:hover": { backgroundColor: "hsl(202, 12%, 95%)" },
    },
  };
};
function CertsTable(props) {
  const {
    classes,
    isSingleView,
    certsData,
    showProductsPerRow,
    selectedPlanYear,
    certsLoading,
    changeState,
    agentCarriers,
  } = props;

  const [data, setData] = useState([]);
  const contractIdState = (contractId, agentCarriers) => {
    let state = "";
    for (let parent of agentCarriers) {
      for (let child of parent.contracts) {
        if (child.contract_id == parseInt(contractId)) {
          state = child.state;
        }
      }
    }
    return state ? state : "N/A";
  };

  useEffect(() => {
    setData(
      certsData.filter(({ contractId }) =>
        agentCarriers.some(({ contracts }) =>
          contracts.some(({ contract_id }) => contract_id == contractId)
        )
      )
    );
  }, [certsData, agentCarriers.length]);

  const titleClasses = isSingleView ? classes.titleInSingleView : classes.title;
  const tableTextclasses = isSingleView
    ? classes.tableTextInSingleView
    : classes.tableText;
  if (certsLoading) {
    return (
      <Card elevation={3}>
        <Grid style={{ padding: "2.5em" }} container justifyContent="center">
          <CircularProgress />
        </Grid>
      </Card>
    );
  }
  if (certsData.length === 0) {
    setTimeout(() => {
      return changeState();
    }, 2000);

    return (
      <Card className={classes.card} elevation={3}>
        <Typography
          className={titleClasses}
          color="textPrimary"
          gutterBottom
          align="left"
          style={{
            textTransform: "uppercase",
            letterSpacing: "3px",
            fontWeight: "bold",
            paddingInlineStart: "1em",
            margin: "3em 0 3em",
            color: "red",
          }}
        >
          No Certs Rows
        </Typography>
      </Card>
    );
  } else {
    return (
      <Card className={classes.card} elevation={3}>
        <CardContent>
          <Typography className={titleClasses} color="textPrimary" gutterBottom>
            RTS & Certs
          </Typography>
          <Table
            className={classes.table}
            size="small"
            // data-tour="ad__rtsCertsButton--observe"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  padding="checkbox"
                  colSpan={12}
                  style={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  Click Rows to expand
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding="none" colSpan={1}></TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Carrier
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  State
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Corporate Contract?
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Is Certified
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Ready To Sell?
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Writing Number
                </TableCell>
                <TableCell
                  className={tableTextclasses}
                  padding="none"
                  align="left"
                >
                  Plan Year
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .filter(({ planYear }) => selectedPlanYear === planYear)
                .map((row, i) => (
                  <TableRow
                    key={i}
                    onClick={() => showProductsPerRow(row)}
                    className={classes.rowHover}
                    data-tour="ad__rtsCertsTableRow"
                  >
                    <TableCell padding="none" colSpan={1}>
                      <ZoomIn style={{ color: "#008000" }} />
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {contractIdState(row.contractId, agentCarriers)}
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {row.isCorporation ? "YES" : "NO"}
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {row.isCertified ? "YES" : "NO"}
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {row.isReady ? "YES" : "NO"}
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {row.writingNumber}
                    </TableCell>
                    <TableCell
                      className={classes.tableTextclasses}
                      padding="none"
                      align="left"
                    >
                      {row.planYear}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
export default withStyles(styles)(CertsTable);
