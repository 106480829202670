import React from "react";
import { Grid, DialogTitle, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const CommonDialogTitle = (props) => {
  const { title, close, variant } = props;

  return (
    <DialogTitle
      style={{ background: "rgb(0, 55, 96)", borderRadius: "#d50000" }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={2} />
        <Grid item xs={8} align="center">
          <Typography variant={variant ?? "h5"} style={{ color: "#eee" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2} align="end">
          <IconButton
            style={{ color: "#eee", outline: "none" }}
            onClick={(e) => close(e)}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default CommonDialogTitle;
