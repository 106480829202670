import React from "react";
import withStyles from "@mui/styles/withStyles";
import HtmlToolTip from "../common/HtmlTooltip";
import {
  Button,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Zoom,
  Checkbox,
} from "@mui/material";

const DirectUplineTooltipText =
  "Typically your agency, or one of your sub agencies";
const StatesRequestedTooltipText =
  "Needed in order to send proper contracting paperwork to agent. Note: Agents MUST select appropriate states on the paperwork and approval is at the discretion of the carrier.";
const RequestLevelTooltipText = "Examples: Solicitor, Agent, GA, MGA";

const styles = (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "#3f51b5",
    },
    "& .MuiInputLabel-root": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-root": {
      color: "#3f51b5",
    },
    "& .MuiInputBase-root": {
      color: "#3f51b5",
      backgroundColor: "#eee",
    },
    "& .Mui-error": {
      color: "black",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
});

class ContractPanel extends React.Component {
  render() {
    const {
      classes,
      handleInputChange,
      handleAddContract,
      resetContractPanel,
      contractChipIndex,
      existingContract,
      contractValidationErrors,
      contractsLength,
      rowData,
      carrierMap,
    } = this.props;
    const disabledFromTable =
      contractChipIndex == null && contractsLength === 1 && rowData;
    return (
      <div>
        <Grid
          container
          style={{
            paddingTop: 0,
            paddingBottom: 2,
          }}
        >
          <Grid
            item
            xs={"auto"}
            style={{
              padding: "0 2.5px 0 5px",
            }}
            justifyContent="space-between"
          >
            <Typography
              align="flex-start"
              style={{ margin: "0px", height: 20, display: "inline-block" }}
            >
              <b>Add Contract</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={"auto"}
            style={{
              padding: "0 2.5px 0 5px",
            }}
          >
            <Typography align="flex-end">
              <span
                style={{
                  color: "red",
                  fontStyle: "italic",
                  fontSize: "smaller",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                (All requested contracts will be processed for each of the
                agents added above)
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            border: "solid black thin",
          }}
        >
          <Grid
            item
            xs={12}
            container
            sx={{ ml: 1 }}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item>
              <Typography variant="body1">Carrier not listed?</Typography>
            </Grid>
            <Checkbox
              id="filled-unlistedCarrier-input"
              name="isOther"
              checked={existingContract.isOther}
              onChange={handleInputChange}
              color="primary"
              size="small"
              disabled={rowData}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              padding: "0 2.5px 0 5px",
            }}
          >
            {!existingContract.isOther ? (
              <TextField
                id="filled-carrier-input"
                label="Carrier"
                name="carrier"
                fullWidth
                required
                select
                margin="dense"
                variant="filled"
                value={existingContract.carrier || ""}
                classes={{ root: classes.root }}
                onChange={handleInputChange}
                error={
                  !existingContract.carrier &&
                  contractValidationErrors.carrier !== undefined
                }
                helperText={
                  !existingContract.carrier && contractValidationErrors.carrier
                }
                disabled={rowData}
              >
                {carrierMap.map((carrierData) => (
                  <MenuItem key={carrierData.name} value={carrierData.name}>
                    {carrierData.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                value={existingContract.carrier || ""}
                name="carrier"
                label="Enter Carrier"
                onChange={handleInputChange}
                classes={{ root: classes.root }}
                required
                fullWidth
                margin="dense"
                variant="filled"
                error={
                  contractValidationErrors.carrier !== undefined &&
                  !existingContract.carrier
                }
                helperText={
                  contractValidationErrors.carrier !== undefined &&
                  !existingContract.carrier
                }
                disabled={rowData}
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              padding: "0 5px 0 5px",
            }}
          >
            <HtmlToolTip
              title={RequestLevelTooltipText}
              placement="top"
              interactive
              TransitionComponent={Zoom}
            >
              <TextField
                disabled={disabledFromTable}
                id="filled-level-input"
                label="Requested Level"
                name="requestLevel"
                inputprops={{
                  inputProps: { maxLength: 16, autocomplete: "new-password" },
                }}
                fullWidth
                required
                margin="dense"
                variant="filled"
                value={existingContract.requestLevel || ""}
                classes={{ root: classes.root }}
                onChange={handleInputChange}
                error={
                  !existingContract.requestLevel &&
                  contractValidationErrors.requestLevel !== undefined
                }
                helperText={
                  !existingContract.requestLevel &&
                  contractValidationErrors.requestLevel
                }
              />
            </HtmlToolTip>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              padding: "0 2.5px 0 5px",
            }}
          >
            <HtmlToolTip
              title={DirectUplineTooltipText}
              placement="left"
              TransitionComponent={Zoom}
            >
              <TextField
                disabled={disabledFromTable}
                id="filled-upline-input"
                label="Direct Upline"
                name="upline"
                fullWidth
                inputprops={{
                  inputProps: { maxLength: 40, autocomplete: "new-password" },
                }}
                required
                margin="dense"
                variant="filled"
                value={existingContract.upline || ""}
                classes={{ root: classes.root }}
                onChange={handleInputChange}
                error={
                  !existingContract.upline &&
                  contractValidationErrors.upline !== undefined
                }
                helperText={
                  !existingContract.upline && contractValidationErrors.upline
                }
              />
            </HtmlToolTip>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              padding: "0 5px 0 5px",
            }}
          >
            <HtmlToolTip
              title={StatesRequestedTooltipText}
              placement="right"
              TransitionComponent={Zoom}
            >
              <TextField
                disabled={disabledFromTable}
                id="filled-carrier-input"
                label="States Requested"
                name="statesRequested"
                fullWidth
                autoComplete="new-password"
                inputprops={{
                  inputProps: { maxLength: 60 },
                }}
                required
                margin="dense"
                variant="filled"
                value={existingContract.statesRequested || ""}
                classes={{ root: classes.root }}
                onChange={handleInputChange}
                error={
                  contractValidationErrors.statesRequested !== undefined &&
                  !existingContract.statesRequested
                }
                helperText={
                  !existingContract.statesRequested &&
                  contractValidationErrors.statesRequested
                }
              />
            </HtmlToolTip>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div className={classes.root}>
              <Button
                onClick={handleAddContract}
                style={{
                  color: "#eee",
                  backgroundColor: disabledFromTable ? "grey" : "#3f51b5",
                }}
                classes={{ root: classes.button }}
                disabled={disabledFromTable}
              >
                {contractChipIndex == null ? "Add Contract" : "Update Contract"}
              </Button>
              {Object.keys(existingContract).length > 0 ? (
                <Button
                  onClick={resetContractPanel}
                  style={{ backgroundColor: "#fff176" }}
                  classes={{ root: classes.button }}
                  disabled={rowData}
                >
                  Reset Contract Form
                </Button>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ContractPanel);
