import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import history from "../../utils/history";
import { setCriteria } from "../../store/actions/production/productionActions";

const navigate = (path, name, year) => {
  history.push(path, { state: { planYear: String(year), carrierName: name } });
};

let dataYear = "";

const customTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "3px",
          border: "1px solid #cccc",
          fontSize: "12px",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        <label> {`${payload[0].name} : ${payload[0].value.toFixed(2)}%`}</label>
      </div>
    );
  }
  return null;
};

const customLegend = (props) => {
  const { payload } = props;
  return (
    <ul>
      {payload.map((entry, index) => (
        <label
          align="center"
          key={`item-${index}`}
          style={{ color: entry.color, padding: 2 }}
          fontSize="3px"
        >
          {entry.value} : {entry.payload.value.toFixed(2)}%
        </label>
      ))}
    </ul>
  );
};

const renderCustomizedLabel = ({ percent }) => {
  return `${(percent * 100).toFixed(2)}%`;
};

// pieData is passed from CarrierInformation to PieRechartComponent to here
const PieChartModel = (props) => {
  const { pieData, year, carrierList } = props;
  dataYear = year;

  const onPieChartClickHandler = (e) => {
    const name = e.name;
    const type = e.type;
    const year = dataYear;
    const carrierId = carrierList.filter((item) => item.name === name)[0].id;
    setCriteria(carrierId, year, type);
    navigate("/Production", name, year);
  };

  // this returns a div containing the pie, its legend, and its tooltip
  if (pieData.length !== 0) {
    return (
      <div style={{ width: 400, height: 500 }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Legend
              layout="horizontal"
              verticalAlign="top"
              textAlign="justify"
              align="center"
              margin="0"
              justifyContent="flex-end"
              content={customLegend}
              height={200}
            />
            <Pie
              data={pieData}
              color="#000000"
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={120}
              label={renderCustomizedLabel}
              labelLine={false}
              fill="#8884d8"
              onClick={onPieChartClickHandler}
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} />
              ))}
            </Pie>
            <Tooltip content={customTooltip} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return null;
};

export default PieChartModel;