import {
  SET_PRODUCTION,
  CLEAR_CRITERIA,
  SET_CRITERIA,
  SET_LEVELS,
  CLEAR_LEVELS,
  SET_AGENCIES,
  CLEAR_AGENCIES,
  PRODUCTION_LOADING,
  SET_PRODUCTION_TOTALS,
  SET_ETL_DATES,
  CLEAR_ETL_DATES,
} from "../../constants/action-types";
import _ from "lodash";

const initState = {
  criteria: {},
  currentData: [],
  levels: [],
  agencies: [],
  carriers: [],
  productionTotals: [],
  etlDates: [],
};
export const productionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_PRODUCTION:
      return { ...state, currentData: payload, isLoading: false };
    case SET_CRITERIA:
      return { ...state, criteria: _.merge(state.criteria, payload) };
    case CLEAR_CRITERIA:
      return { ...state, criteria: {} };
    case SET_LEVELS:
      return { ...state, levels: payload };
    case CLEAR_LEVELS:
      return { ...state, levels: [] };
    case SET_AGENCIES:
      return { ...state, agencies: payload };
    case CLEAR_AGENCIES:
      return { ...state, agencies: [] };
    case PRODUCTION_LOADING:
      return { ...state, isLoading: payload };
    case SET_PRODUCTION_TOTALS:
      return { ...state, productionTotals: payload };
    case SET_ETL_DATES:
      return { ...state, etlDates: payload };
    case CLEAR_ETL_DATES:
      return { ...state, etlDates: [] };
    default:
      return state;
  }
};
