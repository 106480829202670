import {
  SET_PLAN_PRODUCTION,
  SET_PLAN_PRODUCTION_ERROR,
  CLEAR_PLAN_PRODUCTION,
  PLAN_PRODUCTION_LOADING,
  SET_FILTERED_PLANCHANGE_TOTALS,
  CLEAR_FILTERED_PLANCHANGE_TOTALS,
  SET_PLANCHANGE_FILTER_MODEL,
  CLEAR_PLANCHANGE_FILTER_MODEL,
} from "../../constants/action-types";

const initState = {
  FetchingPlanChangeError: undefined,
  isFetchingPlanChange: false,
  production: {},
  filteredPlanChangeTotals: { maTotal: 0, msTotal: 0, pdpTotal: 0 },
  filterModel: {
    items: [],
  },
};

export const planChange = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_PLAN_PRODUCTION:
      return { ...state, FetchingPlanChangeError: undefined, ...payload };
    case CLEAR_PLAN_PRODUCTION:
      return payload;
    case PLAN_PRODUCTION_LOADING:
      return { ...state, isFetchingPlanChange: payload };
    case SET_PLANCHANGE_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_PLANCHANGE_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    case SET_FILTERED_PLANCHANGE_TOTALS:
      return { ...state, filteredPlanChangeTotals: payload };
    case CLEAR_FILTERED_PLANCHANGE_TOTALS:
      return {
        ...state,
        filteredPlanChangeTotals: { maTotal: 0, msTotal: 0, pdpTotal: 0 },
      };
    case SET_PLAN_PRODUCTION_ERROR:
      return { ...state, FetchingPlanChangeError: payload };
    default:
      return state;
  }
};
