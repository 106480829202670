import {
  CLEAR_AGENT_LIST,
  SET_AGENT_LIST
} from "../../constants/action-types";

export const agents = (state = [], action) => {
  switch (action.type) {
    case SET_AGENT_LIST:
      return [...state, ...action.payload];
    case CLEAR_AGENT_LIST:
      return action.payload;
    default:
      return state;
  }
};
