/** This will load agents for large agencies in the background */
import { store } from "../../store";
import { fetchAgentList } from "../../store/actions/agents/agents";

const AgentLoader = {
  loadAgents
};
function loadAgents(agencyId, callback) {
  const increment = 10000;
  loadChunk(parseInt(agencyId), increment, 0, callback);
}

async function loadChunk(
  agencyId,
  increment,
  currentOffset,
  callback
) {
  const internalCallBack = (agencyId, callback) => res => {
    if (callback) {
      callback(res.length);
    }
    if (res.length === increment) {
      loadChunk(agencyId, increment, currentOffset + increment, callback);
    }
  };
  store.dispatch(
    fetchAgentList(
      agencyId,
      currentOffset,
      Math.abs(increment),
      internalCallBack(agencyId, callback)
    )
  );
}

export default AgentLoader;
