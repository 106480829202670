import {
  SET_PLANCHANGE_CARRIERS,
  SET_PLANCHANGE_CARRIERS_ERROR,
  CLEAR_PLANCHANGE_CARRIERS,
  PLANCHANGE_CARRIERS_LOADING,
} from "../../constants/action-types";
import {
  getCurrentProductionYear,
  getAvailablePlanYears,
  getPlanChangeCarriers,
} from "../../../services/GraphQl/BDSWebService";

/* redux-thunk implementation */
export const setPlanChangeCarriers = () => {
  return async (dispatch) => {
    try {
      const planChangeCarriersData = await getPlanChangeCarriers();
      dispatch(setPlanChangeCarrierResults(planChangeCarriersData));
    } catch (error) {
      dispatch(setPlanChangeCarrierError(error));
    }
  };
};

export const clearPlanChangeCarriers = () => {
  return (dispatch) => {
    dispatch(clearPlanChangeCarrierResults());
  };
};

/* redux action */
const setPlanChangeCarrierResults = (data) => {
  return {
    type: SET_PLANCHANGE_CARRIERS,
    payload: data,
  };
};

const clearPlanChangeCarrierResults = (data) => {
  return {
    type: CLEAR_PLANCHANGE_CARRIERS,
    payload: data,
  };
};

const setPlanChangeCarrierError = (data) => {
  return {
    type: SET_PLANCHANGE_CARRIERS_ERROR,
    payload: data,
  };
};

const setPlanChangeCarrierLoading = (data) => {
  return {
    type: PLANCHANGE_CARRIERS_LOADING,
    payload: data,
  };
};
