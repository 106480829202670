import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Typography } from "@mui/material";
import AgencyPill from "./AgencyPill";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "10px 5px 5px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  typoGraphy: {
    fontSize: "calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)))",
    lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    color: "white",
    // fontWeight: "lighter"
  },
});

function PlainMenu(props) {
  const { classes, title, submenu } = props;
  const agencyName = sessionStorage.getItem("agencyName");

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      data-tour="ad__agencyMenu"
    >
      <Grid item style={{ padding: 8 }}>
        <AgencyPill agencyName={agencyName} />
      </Grid>
      <Grid item style={{ padding: 8 }}>
        <Typography class={classes.typoGraphy}>{title}</Typography>
      </Grid>
      <Grid item style={{ width: "100%", height: "35vh", padding: "0" }}>
        {submenu}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(PlainMenu);
