import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import { Route } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import ReactGA from "react-ga";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    card: {
      background:
        "linear-gradient(to bottom right,  white, #0e76bc) !important",
      minHeight: "fit-content",
    },
    typography: {
      color: "#003760",
    },
    table: {
      padding: 10,
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  };
});

const ClickableTableRow = (props) => {
  const { carrierName, history, currentYear, ...restProps } = props;
  return (
    <TableRow
      {...restProps}
      onClick={() =>
        handleProductionClick(history, props.carrierName, props.currentYear)
      }
    >
      {props.children}
    </TableRow>
  );
};

const handleProductionClick = (history, carrierName, currentYear) => {
  ReactGA.event({
    category: "Production By Carrier - Dashboard",
    action: "Click Through",
    label:
      "Agency : " +
      sessionStorage.getItem("agencyId") +
      " navigated to production from dashboard.",
  });
  history.push({
    pathname: "/production",
    state: {
      carrier: carrierName,
      planYear: currentYear,
    },
  });
};

const ProductionByCarrier = (props) => {
  const { classes } = useStyles();
  const {
    currentProduction,
    currentProduction: { currentYear },
  } = props;
  const currentProductionData = currentProduction.production[currentYear];

  let sumMa = 0;
  let sumMs = 0;
  let sumPdp = 0;

  if (currentProductionData) {
    const grandTotalMA = currentProductionData?.data?.map((data) =>
      data.ma_total ? parseInt(data.ma_total) : (data.ma_total = 0)
    );
    const grandTotalMS = currentProductionData?.data?.map((data) =>
      data.ms_total ? parseInt(data.ms_total) : (data.ms_total = 0)
    );
    const grandTotalPDP = currentProductionData?.data?.map((data) =>
      data.pdp_total ? parseInt(data.pdp_total) : (data.pdp_total = 0)
    );
    sumMa = grandTotalMA.reduce((prev, next) => prev + next);
    sumMs = grandTotalMS.reduce((prev, next) => prev + next);
    sumPdp = grandTotalPDP.reduce((prev, next) => prev + next);
  }

  return (
    <>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        Production By Carrier
      </Typography>
      <Route
        render={({ history }) => (
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell padding={"none"} align="left" />
                  <TableCell padding={"none"} align="center">
                    Medicare Advantage
                  </TableCell>
                  <TableCell padding={"none"} align="center">
                    Medicare Supplement
                  </TableCell>
                  <TableCell padding={"none"} align="center">
                    Prescription Drug Plan
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProductionData &&
                  currentProductionData.data &&
                  currentProductionData?.data?.map((item) => {
                    return (
                      <ClickableTableRow
                        style={{ cursor: "pointer" }}
                        history={history}
                        carrierName={item.name}
                        key={item.id}
                        hover={true}
                        currentYear={currentYear}
                      >
                        <TableCell component="th" scope="row">
                          {item.name}
                        </TableCell>
                        <TableCell align="center">{item.ma_total}</TableCell>
                        <TableCell align="center">{item.ms_total}</TableCell>
                        <TableCell align="center">{item.pdp_total}</TableCell>
                      </ClickableTableRow>
                    );
                  })}
                <TableRow style={{ borderTop: "solid 2px" }}>
                  <TableCell
                    style={{ fontWeight: 600 }}
                    component="th"
                    scope="row"
                  >
                    Totals
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="center">
                    {sumMa}
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="center">
                    {sumMs}
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="center">
                    {sumPdp}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
      />
    </>
  );
};

export default ProductionByCarrier;
