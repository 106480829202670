import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { agencyContractRequest } from "../../services/GraphQl/BDSWebService";
import { useDispatch, useSelector } from "react-redux";
import { SET_CONTRACT_REQUESTS } from "../../store/constants/action-types";

const DenyRequestModal = (props) => {
  const { closeDenyModal, denyModalOpen, rowData } = props;
  const { agentId, id, carrierName, agentName } = rowData;
  const contractRequests = useSelector(
    ({ currentContractRequests }) =>
      currentContractRequests.storedContractRequests
  );
  const dispatch = useDispatch();
  const agencyId = sessionStorage.getItem("agencyId");
  const [input, setInput] = useState({
    id,
    status: "denied",
    subject: `Contract Request Denial for ${carrierName}`,
    body: "",
    agencyId,
    agentIds: [agentId],
    type: "Contract Request Denial",
  });
  const handleChange = (e) => {
    setInput({ ...input, body: e.target.value });
  };
  const _denyContractRequest = () => {
    const body = `${input.body} - please contact your top of hierarchy with any questions.`;
    agencyContractRequest({ ...input, body }).then((row) => {
      const result = contractRequests.filter(({ id }) => id !== input.id);
      dispatch({ type: SET_CONTRACT_REQUESTS, payload: result });
    });
    closeDenyModal();
  };
  return (
    <Dialog
      open={denyModalOpen}
      onClose={closeDenyModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="note-dialog-title">Contract Request Denial</DialogTitle>
      <DialogContent>
        <Typography style={{ color: "#ed2518" }} variant="textSecondary">
          This option will stop the request process for this contract and notify
          the requesting agent. Additional comments entered below will accompany
          the message.
        </Typography>
        <br />
        <br />
        <Typography
          style={{ color: "#ed2518", fontWeight: 600 }}
          variant="textSecondary"
        >
          PLEASE NOTE:
        </Typography>{" "}
        <Typography style={{ color: "#ed2518" }} variant="textSecondary">
          Once denied, the request will have to be re-submitted by the agent.
        </Typography>
        <br />
        <br />
        <Typography style={{ fontWeight: 600 }}>Subject:</Typography>
        {input.subject}
        <br />
        <TextField
          margin="dense"
          id="body"
          label={<Typography style={{ fontWeight: 600 }}>*Message</Typography>}
          placeholder={`Please include a brief message to ${agentName}`}
          multiline
          rows={2}
          value={input.body}
          onChange={(e) => handleChange(e)}
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDenyModal} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _denyContractRequest()}
          disabled={!input.body}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DenyRequestModal;
