import {
  SET_PLANCHANGE_FILTER_MODEL,
  CLEAR_PLANCHANGE_FILTER_MODEL,
  SET_FILTERED_PLANCHANGE_TOTALS,
  CLEAR_FILTERED_PLANCHANGE_TOTALS,
} from "../../constants/action-types";

/* redux-thunk implementation */
export const setFilteredPlanChangeTotals = (
  filteredMaTotal,
  filteredMsTotal,
  filteredPdpTotal
) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILTERED_PLANCHANGE_TOTALS,
      payload: filteredMaTotal,
      filteredMsTotal,
      filteredPdpTotal,
    });
  };
};

export const setPlanChangeFilters = (filterModel) => {
  return (dispatch) => {
    dispatch({
      type: SET_PLANCHANGE_FILTER_MODEL,
      payload: filterModel,
    });
  };
};

export const clearFilteredPlanChangeTotals = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTERED_PLANCHANGE_TOTALS });
  };
};

export const clearPlanChangeFilterModel = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PLANCHANGE_FILTER_MODEL });
  };
};
