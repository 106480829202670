import {
  SET_PRODUCTION,
  SET_PRODUCTION_ERROR,
  SET_CRITERIA,
  CLEAR_CRITERIA,
  SET_PLAN_PRODUCTION,
  SET_PLAN_PRODUCTION_ERROR,
  LOADING,
  SET_PRODUCTION_TOTALS,
  SET_ETL_DATES,
} from "../../constants/action-types";
import {
  getProductionStatisticsGql,
  getLatestETLDates,
} from "../../../services/GraphQl/BDSWebService";
/* redux-thunk implementation */
export const setProduction = (queryData) => {
  return (dispatch, getState) => {
    getProductionStatisticsGql(
      queryData.agencyId,
      // queryData.carrierId,
      queryData.planYear,
      queryData.level,
      // queryData.subagency,
      true,
      true,
      queryData.products
    )
      .then((result) => {
        dispatch({ type: SET_PRODUCTION, payload: result });
      })
      .catch((err) => {
        dispatch({ type: SET_PRODUCTION_ERROR, err });
      });
  };
};

export const setETLDates = () => {
  return (dispatch) => {
    getLatestETLDates().then((result) => {
      dispatch({ type: SET_ETL_DATES, payload: result });
    });
  };
};

export const setProductionTotals = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCTION_TOTALS, payload: data });
  };
};

export const setIsLoading = (value) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: value });
  };
};

/* redux-thunk implementation */
export const setCriteria = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_CRITERIA, payload: data });
  };
};

export const clearCriteria = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CRITERIA });
  };
};
