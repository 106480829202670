import {
  CLEAR_RECRUITER_INFO,
  SET_RECRUITER_INFO,
  SET_RECRUITER_INFO_ERROR,
  UPDATE_RECRUITER_INFO,
} from "../../constants/action-types";

const initState = {
  recruiterInfoError: undefined,
  recruiterInfo: {},
};

export const recruiterStore = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_RECRUITER_INFO:
      return {
        ...state,
        recruiterInfo: payload,
      };
    case UPDATE_RECRUITER_INFO:
      return {
        ...state,
        recruiterInfo: { ...state.recruiterInfo, ...payload },
      };
    case CLEAR_RECRUITER_INFO:
      return payload;
    case SET_RECRUITER_INFO_ERROR:
      return { ...state, recruiterInfoError: payload };
    default:
      return state;
  }
};
