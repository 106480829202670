import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import { HelpOutline, Feedback } from "@mui/icons-material";
// core components
import footerStyle from "../../assets/jss/material-dashboard-react/components/footerStyle";
import { Typography, Link, Dialog } from "@mui/material";
import FeedbackModal from "./FeedbackModal";
import { sendFeedback } from "../../services/GraphQl/BDSWebService";
import ReactGA from "react-ga";
import "../LandingPage.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: "",
      emailValue: "",
      feedbackValue: "",
      open: false,
    };
  }

  setOpen = (value) => {
    this.setState({ open: value });
  };

  render() {
    const { classes, feedback } = this.props;

    const handleClickOpen = () => {
      this.setOpen(true);
    };
    const handleClose = () => {
      this.setOpen(false);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      const { nameValue, emailValue, feedbackValue } = this.state;
      const res = await sendFeedback(nameValue, emailValue, feedbackValue);
      if (res && res.success) {
        this.setState({ nameValue: "", emailValue: "", feedbackValue: "" });
        this.setOpen(false);
        alert("Thank you for providing some feedback!");
      }

      ReactGA.event({
        category: "Feedback",
        action: "Send",
        label:
          "Agency : " + sessionStorage.getItem("agencyId") + " sent feedback.",
      });
    };

    const handleNameFieldChange = (event) => {
      this.setState({
        nameValue: event.target.value,
      });
    };

    const handleEmailFieldChange = (event) => {
      this.setState({
        emailValue: event.target.value,
      });
    };

    const handleFeedbackFieldChange = (event) => {
      this.setState({
        feedbackValue: event.target.value,
      });
    };

    return (
      <footer className={classes.footer}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Grid
              container
              alignItems="flex-start"
              direction="row"
              justifyContent="flex-start"
            >
              <Grid item>
                <span className="poweredBy">
                  Powered By: &nbsp;&nbsp;{" "}
                  <div style={{ display: "inline-block" }}>
                    <a
                      data-tour="ad__berwickLogo"
                      target={"_blank"}
                      href="https://berwickinsurance.com"
                    >
                      <img
                        style={{ height: 40, padding: 2 }}
                        src="Berwick_Color_Tag@2x.png"
                        alt="berwick logo"
                      />
                    </a>
                  </div>
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              alignItems="flex-end"
              direction="row"
              justifyContent="flex-end"
            >
              {feedback ? (
                <Grid item xs={12} md={6} lg={3}>
                  <div data-tour="ad__feedback" style={{ textAlign: "right" }}>
                    <Typography data-tour="ad__footerFeedback">
                      <Link
                        component="button"
                        onClick={handleClickOpen}
                        style={{ color: "#3085d6" }}
                      >
                        <Feedback
                          style={{ color: "#3085d6", paddingRight: 5 }}
                        />
                        Send Feedback
                      </Link>
                    </Typography>
                    <Dialog
                      open={this.state.open}
                      onClose={handleClose}
                      classes={{ paper: classes.paper }}
                    >
                      <FeedbackModal
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        handleNameFieldChange={handleNameFieldChange}
                        handleEmailFieldChange={handleEmailFieldChange}
                        handleFeedbackFieldChange={handleFeedbackFieldChange}
                      />
                    </Dialog>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6} lg={3}>
                <div style={{ textAlign: "right" }} data-tour="ad__contactUs">
                  <Link
                    style={{ color: "#003760" }}
                    target={"_blank"}
                    href="https://berwickinsurance.com/contact-us"
                  >
                    <HelpOutline
                      style={{
                        color: "#003760",
                        paddingRight: 5,
                        fontSize: 24,
                      }}
                    />
                    Contact Us
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(footerStyle)(Footer);
