import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage/src";

const persistConfig = {
  key: "agency",
  storage: createIdbStorage({ name: "agencyDashboard", storeName: "store" }),
  stateReconciler: autoMergeLevel2,
  serialize: true
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
