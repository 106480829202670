import { gql } from "apollo-boost";

export const fragments = {
  contractRequestData: gql`
    fragment ContractRequestData on ContractRequestAgency {
      id
      createDate
      agentId
      requestEmail
      gender
      residentState
      licenseNumber
      isAsCorp
      corpName
      corpNpn
      corpTin
      carrierId
      carrierName
      states
      notes
      agentName
      phone
      email
      npn
      ssn
      birthDate
      status
      notes
    }
  `,
};
