const StringUtils = {
  getSubStr,
  getFirstPar,
  firstUpperLetter,
};

function getSubStr(str, delim) {
  var a = str.indexOf(delim);

  if (a === -1) return "";

  var b = str.length;
  return str.substr(a, b);
}
function getFirstPar(str, delim) {
  var a = str.indexOf(delim);

  if (a === -1) return "";

  return str.substr(0, a - 1);
}

function firstUpperLetter(word) {
  if (!word) return word;
  var convertWord = "";
  word
    .split(" ")
    .map((part) =>
      part.charAt(0) !== "("
        ? (convertWord +=
            part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() + " ")
        : (convertWord += part + " ")
    );
  return convertWord.trim();
}
export default StringUtils;
