import React from "react";
import Reactour from "reactour";
import { withRouter } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import CustomTourContent from "../common/CustomTourContent";

const AgentListRtsTour = withRouter(
  ({
    isOpen,
    closeTour,
    location: { pathname },
    history,
    onAfterOpen,
    onBeforeClose,
    openedFrom,
  }) => {
    const steps = [
      {
        selector: "",
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "RTS/Certs data is found in the 'My Agents' section. It is only available for Approved contracts. We have pre-selected Approved contracts for you."
            }
          />
        ),
      },
      {
        selector: '[data-tour="ad__rtsCertsTable"]',
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "This button will allow you to toggle between the normal contracts view and the RTS/Certs view. Please click this button to show the table and then click the right arrow to continue."
            }
          />
        ),
      },
      {
        selector: '[data-tour="ad__rtsCertsTableView"]',
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "This view shows the RTS/Cert status of the selected plan year. Any contracts not shown are either not Approved, or do not have available data."
            }
          />
        ),
      },
      {
        selector: '[data-tour="ad__rtsCertsTablePlanYears"]',
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={"This dropdown will allow selection of the Plan Year."}
          />
        ),
      },
      {
        selector: '[data-tour="ad__rtsCertsTableRow"]',
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "Clicking on any row will open the product level view. That view shows the Product/State/Plan Year breakdown for certification of a specific contract."
            }
          />
        ),
      },
      {
        selector: "",
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "If the row you clicked on had no certifications, you will automatically be returned to the main contract view. Otherwise, click on the RTS/Certs button to return to the prior view."
            }
          />
        ),
      },
      {
        selector: '[data-tour="ad__rtsCertsButton"]',
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "This button will show all certs for all agents listed. Click to toggle views."
            }
          />
        ),
      },
      {
        selector: '[data-tour="ad__rtsCertsDownload"]',
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            message={
              "This button is where you can download agents listed in the table, if the table is open"
            }
          />
        ),
      },
      {
        selector: "",
        content: () => (
          <CustomTourContent
            openedFrom={openedFrom}
            closeTour={closeTour}
            history={history}
            message={"This concludes the tour, thank you for your time!"}
          />
        ),
      },
    ];
    return (
      <Reactour
        onAfterOpen={onAfterOpen}
        onBeforeClose={onBeforeClose}
        startAt={0}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={() => {
          closeTour();
          clearAllBodyScrollLocks();
        }}
        update={pathname}
        closeWithMask={false}
      />
    );
  }
);
export default AgentListRtsTour;
