import {
  SET_AGENT_LIST_AGENCIES,
  CLEAR_AGENT_LIST_AGENCIES
} from "../../constants/action-types";

export const agentListAgencies = (state = [], action) => {
  switch (action.type) {
    case SET_AGENT_LIST_AGENCIES:
      return action.payload;
    case CLEAR_AGENT_LIST_AGENCIES:
      return action.payload;
    default:
      return state;
  }
};
