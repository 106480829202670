import {
  defaultFont,
  container,
  primaryColor,
} from "../../material-dashboard-react.jsx";

const footerStyle = {
  block: {
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px",
    background: "linear-gradient(to bottom right, #ffffff, #0e76bc) !important",
  },
  left: {
    float: "left!important",
  },
  center: {
    textAlign: "center",
  },
  right: {
    float: "right!important",
  },
  footer: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    top: "auto",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#eee",
    zIndex: 200,
    ...defaultFont,
  },
  container,
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor:
      "linear-gradient(to bottom right, white, #0e76bc) !important",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  root: {
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "#3f51b5",
    },
    "& .MuiInputLabel-root": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-root": {
      color: "#3f51b5",
    },
    "& .MuiInputBase-root": {
      color: "#3f51b5",
      backgroundColor: "#eee",
    },
  },
  paper: {
    backgroundColor: "#575757",
  },
};
export default footerStyle;
