import React, { useEffect, useState, Fragment } from "react";
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Error,
  Share,
  CheckCircle,
  FileCopy,
  Check,
  CloudDownload,
} from "@mui/icons-material";
import {
  onboardLeadsByRecruiter,
  createAgentLead,
  ocrUpdateStatus,
  updateOcr,
  updateOnboardLead,
  getAllCarriers,
  sendDenialMessages,
} from "../../services/GraphQl/BDSWebService";
import ShareAway from "../common/ShareAway";
import OcrCollapseContents from "./OcrCollapseContents";
import ProcessDialog from "./ProcessDialog";
import HtmlToolTip from "../common/HtmlTooltip";
import { CSVLink } from "react-csv";
import moment from "moment";
const leadReportHeaders = [
  "Agent Name",
  "Email",
  "Phone",
  "NPN",
  "Requested A Contract?",
  "Created ",
  "Note",
];

const useStyles = makeStyles()((theme) => {
  return {
    ocrCard: {
      width: "inherit",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        paddingTop: 167,
        minHeight: "100vh",
        paddingBottom: 46.313,
        overflow: "auto",
      },
    },
    textFieldClass: {
      "& .MuiInput-underline:after": { borderBottomColor: "rgb(0, 55, 96)" },
    },
    ocrCardHeader: {
      backgroundColor: "rgb(0, 55, 96)",
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        top: 56,
        zIndex: 1,
        width: "100%",
      },
    },
    allLeadsSwitch: {
      color: "white",
      backgroundColor:"rgb(0, 55, 96)",
      padding: "0px 8px",
      borderRadius: 5,
      marginBottom: 0,
      marginRight: 0,
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "rgb(0, 55, 96)",
        opacity: 0.75,
      },
      "& .MuiSwitch-colorSecondary": {
        color: "rgb(0, 55, 96)",
      },
      "& .MuiSwitch-input": { display: "flex" },
    },
    csvLink: {
      color: "#eee",
      "&:focus": {
        color: "#eee",
        textDecoration: "none",
        outline: "none",
        border: "none",
      },
      "&:visited": {
        color: "#eee",
        textDecoration: "none",
        outline: "none",
        border: "none",
      },
      "&:link": {
        outline: "none",
        border: "none",
      },
      "&:hover": {
        color: "#eee",
        textDecoration: "none",
        outline: "none",
        border: "none",
      },
      "&:active": {
        color: "#eee",
        textDecoration: "none",
        outline: "none",
        border: "none",
      },      
    },
    customTextField: {
      "& .MuiInputBase-root": {
        color: "rgb(0, 55, 96)",
        backgroundColor: "white",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "white",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#eee",
        },
        "&:hover fieldset": {
          borderColor: "#eee",
        },
        "&.Mui-focused fieldset": {
          borderColor: "white",
        },
      },
    },
  };
});

const OcrList = (props) => {
  const {classes} = useStyles();
  const mediaQuery800px = useMediaQuery("(max-width:800px)");
  const [carrierNameList, setCarrierNameList] = useState([]);
  const [leads, setLeads] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [leadsLoading, setLeadsLoading] = useState(true);
  const [openShareAway, setOpenShareAway] = useState(false);
  const [filteredByValue, setFilteredByValue] = useState("");
  const [clickedRow, setClickedRow] = useState("");
  const [requestsToProcess, setRequestsToProcess] = useState([]);
  const [infoCollapseArr, setInfoCollapseArr] = useState([]);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [toggleListView, setToggleListView] = useState(false);
  const [copiedVal, setCopiedVal] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    onboardLeadsByRecruiter(agencyId).then((leadsResults) => {
      setLeads(
        leadsResults
          .filter(
            (lead) =>
              lead.onboardContractRequests.length > 0 &&
              lead.onboardContractRequests.filter(
                (rtp) => rtp.status === "Denied"
              ).length !== lead.onboardContractRequests.length
          )
          .map((lead) => ({
            ...lead,
            onboardContractRequests: lead.onboardContractRequests.filter(
              (row) => row.status !== "Denied"
            ),
          }))
      );
      setAllLeads(leadsResults);
      setCsvData(
        leadsResults.map((row) => {
          const leadNotes = [
            ...new Set(row.onboardContractRequests.map((r) => r.notes)),
          ];
          return Object.values({
            agentName: `${row.firstName} ${row.lastName}`,
            email: row.email,
            phone: row.phone,
            npn: row.npn,
            contractRequested:
              row.onboardContractRequests.length > 0 ? "Yes" : "No",
            createDate: row.createDate.split(" ")[0],
            note: leadNotes.join(", "),
          });
        })
      );
      setLeadsLoading(false);
    });
    getAllCarriers().then((result) => setCarrierNameList(result));
  }, []);

  const filterLeads = (e) => {
    const val = e.target.value;
    setFilteredByValue(val);
  };

  const arrByToggle = toggleListView ? allLeads : leads;

  const ocrArrToUse =
    filteredByValue !== ""
      ? arrByToggle.filter((row) => {
          const first = row.firstName.toLowerCase();
          const last = row.lastName.toLowerCase();
          const displayName = row.displayName.toLowerCase();
          const trimmedLowerVal = filteredByValue
            .toLowerCase()
            .trim()
            .replace(/[^a-zA-Z ]+/g, "");
          return trimmedLowerVal !== ""
            ? first.search(trimmedLowerVal) !== -1 ||
                last.search(trimmedLowerVal) !== -1 ||
                displayName.search(trimmedLowerVal) !== -1
            : false;
        })
      : arrByToggle;

  const collappseToggle = (id) => {
    setInfoCollapseArr([]);
    setClickedRow((cr) => (cr === id ? "" : id));
  };

  const openAway = () => {
    setOpenShareAway(true);
  };

  const closeAway = () => {
    setOpenShareAway(false);
  };

  const _updateOcr = (input) => {
    const { __typename, ...rest } = input;
    updateOcr(rest);
    setLeads((_leads) =>
      _leads.map((lead) =>
        lead.onboardId === input.onboardId
          ? {
              ...lead,
              onboardContractRequests: lead.onboardContractRequests.map((ocr) =>
                ocr.ocrId === input.ocrId ? input : ocr
              ),
            }
          : lead
      )
    );
  };

  const _updateLead = (input) => {
    const { __typename, onboardContractRequests, ...rest } = input;

    updateOnboardLead(rest);
    setLeads((_leads) =>
      _leads.map((lead) => (lead.onboardId === input.onboardId ? input : lead))
    );
  };

  const removeFromArr = (arr, id) => {
    const arrCopy = [...arr];
    const findIndex = arrCopy.findIndex((obj) => obj.onboardId === id);
    arrCopy.splice(findIndex, 1);
    return arrCopy;
  };

  const submitContractRequests = async (ocrSubmissionInput) => {
    const onboardId = ocrSubmissionInput[0].onboardId;
    const allDeny = !ocrSubmissionInput.some((rtp) => rtp.status === "send");
    const denialMessages = ocrSubmissionInput.filter(
      (r) => r.sendDenialMessage
    );
    const isStaging = sessionStorage.getItem("isStaging") === "true";
    const agencyId = sessionStorage.getItem("agencyId");
    setRequestsToProcess([]);
    setLeads(removeFromArr(leads, onboardId));
    if (!allDeny) {
      setAllLeads(removeFromArr(allLeads, onboardId));
      const { __typename, ...agentLead } = leads.find(
        (lead) => lead.onboardId === onboardId
      );
      await createAgentLead({
        ...agentLead,
        onboardLeadStatus: "Transferred",
        isStaging,
        agencyId,
        onboardContractRequests: ocrSubmissionInput.map(
          ({ __typename, ...rest }) => {
            return {
              ...rest,
              status:
                rest.status === "deny" ? "Denied" : "Submitted To Contracting",
            };
          }
        ),
      });
    } else {
      await ocrUpdateStatus(
        [...ocrSubmissionInput.map(({ ocrId }) => ocrId)],
        "Denied"
      );
    }

    //send Denial messages if they exist
    if (denialMessages.length > 0) {
      const agencyName = sessionStorage.getItem("agencyName");
      const agencyEmail = isStaging
        ? "bit.testing@berwickinsurance.com"
        : sessionStorage.getItem("email");
      const agentEmail = isStaging
        ? "bit.testing@berwickinsurance.com"
        : allLeads.find((r) => r.onboardId === onboardId).email;

      await sendDenialMessages(
        agencyName,
        agentEmail,
        agencyEmail,
        denialMessages
      );
    }
  };

  const checkMissingContractInfo = (ocrs) => {
    let result = [];
    for (let item of ocrs) {
      const {
        carrierName,
        licenseNumber,
        isAsCorp,
        corpTin,
        corpName,
        corpNpn,
      } = item;
      if (carrierName.toLowerCase().search("scan") !== -1) {
        !licenseNumber && result.push(1);
      }
      if (carrierName.toLowerCase().search("anthem") !== -1) {
        isAsCorp && !corpTin && result.push(1);
      }
      if (isAsCorp && (!corpName || !corpNpn)) {
        result.push(1);
      }
    }
    return result.length > 0;
  };

  const checkMissingPersonalInfo = (row) => {
    let result = [];
    for (let item of row.onboardContractRequests) {
      if (item.carrierName.toLowerCase().search("anthem") !== -1) {
        !row.ssn && result.push(1);
      }
      if (item.carrierName.toLowerCase().search("welbe") !== -1) {
        !row.birthDate && result.push(1);
      }
      if (item.carrierName.toLowerCase().search("florida blue") !== -1) {
        (!row.gender || !row.ssn || !row.birthDate) && result.push(1);
      }
    }
    return result.length > 0;
  };

  const itemToShow = (hasMissingInfo) => {
    return hasMissingInfo ? (
      <Error style={{ color: "#ff7b00" }} />
    ) : (
      <CheckCircle style={{ color: "green" }} />
    );
  };

  const _openProcessDialog = () => {
    setOpenProcessDialog(true);
  };

  const _closeProcessDialog = () => {
    setOpenProcessDialog(false);
  };

  const toggleViews = () => {
    setToggleListView(!toggleListView);
    collappseToggle();
  };

  const runCopyTimeout = (incValue) => {
    timeoutId && clearTimeout(timeoutId);
    setCopiedVal(incValue);
    const timeout = setTimeout(() => {
      setCopiedVal("");
      setTimeoutId(null);
    }, 3000);
    setTimeoutId(timeout);
  };

  const sorted = ocrArrToUse.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  const currentDate = moment().format("MM-DD-YYYY");
  return (
    <Card className={classes.ocrCard}>
      <CardHeader
        title={
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            
            style={{
              marginBottom: 10,
              color: "white",
              backgroundColor:"rgb(0, 55, 96)"
            }}
          >
            <Grid item xs={5} sm={6} style={{backgroundColor:"rgb(0, 55, 96)"}}>
              <Typography
                style={{
                  fontSize: "1.2em",
                }}
              >
                Leads{" "}
                {!mediaQuery800px && (
                  <span
                    style={{
                      fontSize: ".66em",
                    }}
                  >
                    by {!toggleListView ? "Contract Requests" : "Total"}
                  </span>
                )}
              </Typography>
            </Grid>

            <Grid item xs={7} sm={6} justifyContent="flex-end" container>
              <div style={{ marginRight: 20 }}>
                <CSVLink
                  data={csvData}
                  headers={leadReportHeaders}
                  filename={`LeadsReport ${currentDate}.csv`}
                  className={classes.csvLink}
                  target="_blank"
                >
                  <IconButton size="small" style={{ color: "white" }}>
                    <CloudDownload style={{ fontSize: 32 }} />
                  </IconButton>
                </CSVLink>
              </div>
              <HtmlToolTip title="Show All Leads List - Some leads may not have contract requests yet. This list also includes leads who have their contract requests denied and have not had an approved request yet.">
                <FormControlLabel
                  className={classes.allLeadsSwitch}
                  control={
                    <Switch
                      checked={toggleListView}
                      onChange={() => toggleViews()}
                      name="LeadSwitch"
                      
                    />
                  }
                  label="All Leads"
                />
              </HtmlToolTip>
            </Grid>
          </Grid>
        }
        subheader={
          <Grid item xs={12} sm={6} md={4}>
            <TextField
            className={classes.customTextField}
              fullWidth
              placeholder="Filter By First And/Or Last"
              variant="outlined"
              value={filteredByValue}
              onChange={filterLeads}
              size="small"
              disabled={leads.length === 0}
              InputProps={{
                endAdornment:
                  filteredByValue === "" ? null : (
                    <IconButton
                      size="small"
                      onClick={() => setFilteredByValue("")}
                    >
                      <Close color="error" />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        }
        className={classes.ocrCardHeader}
      />
      <CardContent
        style={
          mediaQuery800px
            ? { padding: 0 }
            : {
                padding: 0,
                height: 600,
                overflow: "auto",
              }
        }
      >
        {leadsLoading ? (

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={leadsLoading}
          >
            <CircularProgress sx={{ color: "#eee" }} size={100} thickness={1.6}  />
          </Backdrop>
        ) : sorted.length === 0 && filteredByValue !== "" ? (
          <div style={{ placeItems: "center", display: "grid", height: 400 }}>
            <div style={{ textAlign: "center" }}>
              No values by that filter option
            </div>
          </div>
        ) : sorted.length === 0 ? (
          <div style={{ placeItems: "center", display: "grid", height: 400 }}>
            <div style={{ textAlign: "center" }}>
              No Leads to show.
              <br />
              Leads are generated by onboarding with us in Agent Way. Click this
              button to start sharing.
              <IconButton onClick={openAway}>
                <Share />
              </IconButton>
            </div>
          </div>
        ) : (
          <List style={{ paddingTop: 0 }}>
            {sorted.map((row, i) => {
              const currentClickedRow = clickedRow === row.onboardId;
              const contractInfoMissing = checkMissingContractInfo(
                row.onboardContractRequests
              );
              const personalInfoMissing = checkMissingPersonalInfo(row);
              const hasMissingInfo = contractInfoMissing || personalInfoMissing;
              const requestStatusIcon = itemToShow(hasMissingInfo);
              return (
                <Fragment key={row.onboardId}>
                  <ListItem
                    Button={!toggleListView}
                    style={{
                      backgroundColor: currentClickedRow
                        ? "rgba(0, 55, 96, .2)"
                        : i % 2 === 0
                        ? "rgb(213 213 213)"
                        : "white",
                      padding: 15,
                      boxShadow: currentClickedRow
                        ? "0px 0px 2px 0px rgba(0,0,0,0.75)"
                        : "none",
                      position: "relative",
                    }}
                    onClick={() =>
                      !toggleListView ? collappseToggle(row.onboardId) : void 0
                    }
                  >
                    <Grid
                      container
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontSize: "1.5rem",
                            color: "rgb(0, 55, 96)",
                          },
                        }}
                        primary={`${row.firstName} ${row.lastName}`}
                        secondary={
                          <Typography
                            style={{
                              color: "rgb(0, 55, 96)",
                            }}
                            gutterBottom
                          >
                            Created {row.createDate.split(" ")[0]}
                          </Typography>
                        }
                      />

                      <Grid
                        onClick={(e) => e.stopPropagation()}
                        style={{ marginBottom: 5 }}
                      >
                        <div
                          style={{
                            color: "rgb(0, 55, 96)",
                            textAlign: "end",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {row.email}{" "}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.email);
                              runCopyTimeout(row.email);
                            }}
                            size="small"
                          >
                            {copiedVal === row.email ? (
                              <Check style={{ color: "green" }} />
                            ) : (
                              <FileCopy />
                            )}
                          </IconButton>
                        </div>

                        <div
                          style={{
                            color: "rgb(0, 55, 96)",
                            textAlign: "end",
                            position: "relative",
                          }}
                        >
                          {row.phone.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          )}{" "}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.phone);
                              runCopyTimeout(row.phone);
                            }}
                            size="small"
                          >
                            {copiedVal === row.phone ? (
                              <Check style={{ color: "green" }} />
                            ) : (
                              <FileCopy />
                            )}
                          </IconButton>
                        </div>
                      </Grid>
                      {!toggleListView && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Chip
                            style={{
                              borderColor: hasMissingInfo
                                ? "rgba(255,123,0, 1)"
                                : "rgba(0,128,0, 1)",
                              backgroundColor: hasMissingInfo
                                ? "rgba(255,123,0, .2)"
                                : "rgba(0,128,0, .2)",
                              fontSize: "1.05rem",
                            }}
                            avatar={requestStatusIcon}
                            variant="outlined"
                            label={`${row.onboardContractRequests.length} Contract Request(s)`}
                          />
                          <IconButton
                            style={{ color: "rgb(0, 55, 96)" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              collappseToggle(row.onboardId);
                            }}
                          >
                            {currentClickedRow ? (
                              <ArrowDropUp />
                            ) : (
                              <ArrowDropDown />
                            )}
                          </IconButton>
                        </div>
                      )}
                    </Grid>
                  </ListItem>
                  {!toggleListView && (
                    <Collapse in={currentClickedRow}>
                      <OcrCollapseContents
                        row={row}
                        onboardContractRequests={row.onboardContractRequests}
                        currentClickedRow={currentClickedRow}
                        requestsToProcess={requestsToProcess}
                        setRequestsToProcess={setRequestsToProcess}
                        infoCollapseArr={infoCollapseArr}
                        setInfoCollapseArr={setInfoCollapseArr}
                        mediaQuery800px={mediaQuery800px}
                        updateOcr={_updateOcr}
                        updateLead={_updateLead}
                        _openProcessDialog={_openProcessDialog}
                        checkMissingContractInfo={checkMissingContractInfo}
                        personalInfoMissing={personalInfoMissing}
                        itemToShow={itemToShow}
                        carrierNameList={carrierNameList}
                        hasMissingInfo={hasMissingInfo}
                      />
                    </Collapse>
                  )}
                  <Divider />
                </Fragment>
              );
            })}
          </List>
        )}
      </CardContent>
      <ProcessDialog
        openProcessDialog={openProcessDialog}
        closeProcessDialog={_closeProcessDialog}
        submitContractRequests={submitContractRequests}
        requestsToProcess={requestsToProcess}
      />
      <ShareAway openShareAway={openShareAway} closeModal={closeAway} />
    </Card>
  );
};

export default OcrList;
