import React from "react";
import PieChartModel from "./PieChartModel";
import { Grid } from "@mui/material";
const PieRechartComponent = (props) => {
  const { data, year, type, carrierList } = props;      // for linechart these will default to all data, all years, all types (products) and all carriers
  const pieData = getPieData(data, year, type);        // linechart will default to just data; after drop lists are in it'll be data, year, carrier 
  const newYear = getDefaultYear(year, data);           // default year is all years; probably don't need it

  // PieChartModel returns a div with a pie, its legend, and its tooltip. the Grid item below it contains the product labels
  return pieData.length > 0 ? (
    <Grid>
      <PieChartModel
        pieData={pieData}
        year={newYear}
        carrierList={carrierList}
      />
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          {(() => {
            if (type === "MA") return <label>Medicare Advantage</label>;                // pieData already contains these labels
            if (type === "Med Supp") return <label>Medicare Supplement</label>;
            if (type === "PDP") return <label>Prescription Drug Plan</label>;
          })()}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

const getDefaultYear = (year, data) => {
  if (year === 0) {
    return getYear(data.map((item) => item.salesYear));
  }
  return year;
};

const getType = (type) => {
  if (type === "MA") return "Medicare Advantage";
  if (type === "Med Supp") return "Medicare Supplement";
  if (type === "PDP") return "Prescription Drug Plan";
  return "";
};


const getPieData = (data, year, type) => {
  if (data.length > 0) {
    const productData = data.filter((item) => item.product === type);   // get rows for the product we're working on
    const maxYear = getYear(data.map((item) => item.salesYear));         
    const yearData = getYearData(productData, year, maxYear);           // maxYear is the default if year = 0; only return rows from productData for the given year
    const totalValue = getTotalValue(yearData);                         // totalValue = all totals from all carriers, allowing each carrier's % to be calculated
    const result = yearData.map((item) => {
      return {
        name: item.carrierName,
        value: getValuePercent(parseInt(item.sum), totalValue),
        fill: getFillColor(item.carrierName),
        type: getType(item.product),                                    // type is the labels under the pies
      };
    });
    return result;
  }
  return [];
};

const getValuePercent = (dataValue, totalValue) => {
  let result = 0;
  result = (dataValue / totalValue) * 100;
  return result;
};

const getTotalValue = (data) => {
  let total = 0;
  data.map((item) => {
    total += parseInt(item.sum);
  });
  return total;
};

const getFillColor = (carrierName) => {
  if (carrierName === "Aetna US Health Care") return "#F90101";
  if (carrierName === "Cigna MA/PDP") return "#56B4E9";
  if (carrierName === "Elevance/Anthem") return "#F07900";
  if (carrierName === "Humana") return "#39C05E";
  if (carrierName === "UHC") return "#0072B2";
  if (carrierName === "WellCare") return "#CC79A7";
  if (carrierName === "Aetna MS") return "#9F05C5";
  if (carrierName === "SCAN") return "#D55E00";
  if (carrierName === "Molina") return "#A9A19F";
  if (carrierName === "ATRIO") return "#FFE134";
  if (carrierName === "UHC IFP") return "#7E1215";
  if (carrierName === "FL Blue") return "#2C73E9";
  if (carrierName === "Devoted Health") return "#062A77";
  if (carrierName === "ACE Medicare Supplements") return "#A27900";
  if (carrierName === "LA Care") return "#A2C8EC";
  return "#000000";
};

const getYearData = (productData, year, maxYear) => {
  if (year === 0) {
    return productData.filter((item) => item.salesYear === maxYear);
  } else {
    return productData.filter((item) => item.salesYear === year);
  }
};

const getYear = (salesYearList) => {
  if (salesYearList.length > 0) {
    return Math.max(...salesYearList);
  }
  return 0;
};

export default PieRechartComponent;