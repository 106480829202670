import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Typography, Grid, Paper, CircularProgress } from "@mui/material";
import React from "react";

const RTSTable = (rts) => {
  const columns = [
    {
      Header: "Name",
      accessor: "agentName",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      width: 220,
    },
    {
      Header: "Plan Year",
      accessor: "planYear",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      width: 120,
    },
    {
      Header: "Carrier",
      accessor: "carrierName",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      maxWidth: 150,
    },
    {
      Header: "Is Corp",
      accessor: "isCorporation",
      Cell: (row) => {
        return (
          <Typography align="center">{row.value ? "Yes" : "No"}</Typography>
        ); //isCorporation is boolean
      },
      // width: 100,
    },
    {
      Header: "Is Certified",
      accessor: "certified",
      Cell: (row) => {
        return (
          <Typography align="center">
            {row.value === "true" ? "Yes" : "No"}
          </Typography>
        ); // certified is string
      },
      // maxWidth: 100,
    },
    {
      Header: "Is RTS",
      accessor: "ready",
      Cell: (row) => {
        return (
          <Typography align="center">
            {row.value === "true" ? "Yes" : "No"}
          </Typography>
        ); // ready is string
      },
      // maxWidth: 100,
    },
    {
      Header: "Product",
      accessor: "product",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      // maxWidth: 100,
    },
    {
      Header: "State",
      accessor: "state",
      Cell: (row) => {
        return <Typography align="center">{row.value}</Typography>;
      },
      // maxWidth: 100,
    },
  ];

  return (
    <Grid item direction="row" alignItems="center" xs={11}>
      <Paper style={{ marginTop: 10 }}>
        <ReactTable
          noDataText={() => <CircularProgress style={{ color: "#777" }} />}
          data={rts}
          columns={columns}
        />
      </Paper>
    </Grid>
  );
};

export default RTSTable;
