import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";
import ProductionOverview from "./ProductionOverview";
import ProductionByCarrier from "./ProductionByCarrier";
import AgentInformation from "./AgentInformation";
import "react-table-6/react-table.css";
import { setContractStatuses } from "../../store/actions/contracts";
import RTSByCarrier from "../rtsFiles/RTSByCarrier";
import CarrierInformation from "./CarrierInformation";
import ProductionInformation from "./ProductionInformation";
import { Box } from "@mui/system";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    card: {
      background:
        "linear-gradient(to bottom right, #cfd8dc, #546e7a) !important",
      minHeight: "fit-content",
    },
    typography: {
      color: "#003760",
    },
    table: {
      padding: 10,
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    production: {
      borderStyle: "solid",
      borderWidth: 1,
      marginLeft: 10,
      marginRight: -10,
      paddingRight: 15,
      paddingBottom: 15,
      marginBottom: -15,
      color: "#003760",
    },
    productionYear: {
      paddingTop: 10,
      fontWeight: 500,
      fontSize: "2em",
    },
  };
});

const AgencyDetail = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const agencyName = sessionStorage.getItem("agencyName");
  const agentTotal = sessionStorage.getItem("agent_total");
  const currentProduction = useSelector(
    ({ currentProduction }) => currentProduction
  );
  const contracts = useSelector(({ contracts }) => contracts);

  useEffect(() => {
    if (Object.keys(contracts).length === 0) {
      dispatch(setContractStatuses(sessionStorage.getItem("agencyId")));
    }
  }, []);

  return (
    <Grid container sx={{ backgroundColor: "#9e9e9e", p: 1, flexGrow: 1 }}>
      <Box
        sx={{ flexGrow: 1, backgroundColor: "#b1bec4", p: 1, borderRadius: 1 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.typography}
              gutterBottom
              variant="h3"
              component="h2"
            >
              {agencyName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={`${classes.productionYear} ${classes.typography}`}
            >
              Summary For PY {currentProduction.currentYear}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ProductionOverview currentProduction={currentProduction} />
          </Grid>
          <Grid item xs={7}>
            <ProductionByCarrier currentProduction={currentProduction} />
          </Grid>
          <Grid item xs={5}>
            <ProductionInformation
              agencyId={sessionStorage.getItem("agencyId")}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <CarrierInformation
              agencyId={sessionStorage.getItem("agencyId")}
              classes={classes}
            />
          </Grid>
          <Grid item xs={6}>
            <AgentInformation agentTotal={agentTotal} contracts={contracts} />
          </Grid>
          <Grid item xs={6}>
            <RTSByCarrier />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AgencyDetail;
