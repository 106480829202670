import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import Routes from "./Routes";
import "bootstrap/dist/css/bootstrap.css";
import * as Sentry from "@sentry/browser";
import "./App.css";
import { LicenseInfo } from '@mui/x-license-pro';

class App extends Component {
  constructor(props) {
    LicenseInfo.setLicenseKey('fd0d7dbb28c0a857f9a3dacb2c440455Tz03ODgyMCxFPTE3MzE3MDczNjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
    super(props);
    
    Sentry.init({
      dsn: "https://86561c267c004aef9b32e24f74cfb305@o281205.ingest.sentry.io/5192635",
      environment: process.env.NODE_ENV,
      tracesSampleRate: process.env.NODE_ENV === "development" ? 0 : 0.7,
    });
  }

  render() {
    return <Routes />;
  }
}

export default withRouter(App);
