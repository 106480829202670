import {
  CLEAR_CONTRACT_STATUSES,
  SET_CONTRACT_STATUSES
} from "../constants/action-types";

export const contracts = (state = {}, action) => {
  switch (action.type) {
    case SET_CONTRACT_STATUSES:
      return action.payload;
    case CLEAR_CONTRACT_STATUSES:
      return action.payload;
    default:
      return state;
  }
};
