import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { SwapVert } from "@mui/icons-material";
import stringUtls from "../utils/stringUtils";
import HtmlToolTip from "../components/common/HtmlTooltip";
import moment from "moment";
import { sum } from "d3-array";
import { nest } from "d3-collection";

export function GetTopsFromCarriers(carriers) {
  let groupedMaTops = [];
  let groupedMsTops = [];
  carriers.forEach((carrier) => {
    groupedMaTops = groupedMaTops.concat(
      _(carrier.result)
        .groupBy("agentname")
        .map((production, id) => ({
          agentname: id,
          total: _.sumBy(production, "ma_production"),
        }))
        .orderBy("total", "desc")
        .value()
    );
    groupedMsTops = groupedMsTops.concat(
      _(carrier.result)
        .groupBy("agentname")
        .map((production, id) => ({
          agentname: id,
          total: _.sumBy(production, "medsupp_production"),
        }))
        .orderBy("total", "desc")
        .value()
    );
  });
  const ma_top_row = _(groupedMaTops)
    .groupBy("agentname")
    .map((production, id) => ({
      agentname: id,
      total: _.sumBy(production, "total"),
    }))
    .orderBy("total", "desc")
    .value()[0];
  const ms_top_row = _(groupedMsTops)
    .groupBy("agentname")
    .map((production, id) => ({
      agentname: id,
      total: _.sumBy(production, "total"),
    }))
    .orderBy("total", "desc")
    .value()[0];
  return {
    ma_top_total: ma_top_row && ma_top_row.total ? ma_top_row.total : 0,
    ma_top:
      ma_top_row && ma_top_row.total ? ma_top_row.agentname : "No Production",
    ms_top_total: ms_top_row && ms_top_row.total ? ms_top_row.total : 0,
    ms_top:
      ms_top_row && ms_top_row.total ? ms_top_row.agentname : "No Production",
  };
}
export function GetLevelNameById(production, selectedLevel) {
  if (
    !production ||
    !production.levels ||
    !selectedLevel ||
    selectedLevel === ""
  )
    return;
  const level = production.levels.find(
    (item) => item.agentLevelId === selectedLevel
  );
  if (level) return level.carrierLabel;
}
export function GetCarrierNameById(currentProduction, criteria) {
  const { currentYear } = currentProduction;
  const { selectedCarrier, selectedPlanYear } = criteria;

  let selectedYear = selectedPlanYear;
  if (!selectedYear) {
    selectedYear = currentYear;
  }
  if (
    !selectedCarrier ||
    !currentProduction ||
    !currentProduction.production ||
    !currentProduction.production[selectedYear]
  )
    return;

  const carrier = currentProduction.production[selectedYear].data.find(
    (item) => item.id === selectedCarrier
  );
  if (carrier) return carrier.name;
}

export function ProductionDataExists(currentProduction, criteria) {
  let { selectedPlanYear } = criteria;
  /* filter by year */
  if (!selectedPlanYear) {
    selectedPlanYear = currentProduction.currentYear;
  }
  return currentProduction.production
    ? currentProduction.production[selectedPlanYear] &&
        currentProduction.production[selectedPlanYear].data
    : false;
}

export function GetProductionDataForCarriers(currentProduction, production) {
  let dataToShow = [];

  let productionSource = [];
  let { selectedPlanYear, selectedCarrier, selectedAgency, selectedLevel } =
    production.criteria;
  /* filter by year */
  if (!selectedPlanYear) {
    selectedPlanYear = currentProduction.currentYear;
  }

  productionSource = currentProduction.production[selectedPlanYear];
  if (!productionSource || !productionSource.data) return dataToShow;
  /* filter by carrier */

  if (selectedCarrier === "*") {
    const all = [];
    productionSource.data.forEach((carrierProd) => {
      all.push(...carrierProd.result);
    });
    dataToShow = all;
  } else if (selectedCarrier) {
    const filteredByCarrier = productionSource.data.find(
      (carrier) => carrier.id === selectedCarrier
    );
    if (filteredByCarrier === undefined) {
      dataToShow = [];
    } else {
      dataToShow = filteredByCarrier.result;
    }
  }
  /* filter by agency */
  if (selectedAgency && selectedAgency !== "") {
    dataToShow = dataToShow.filter((row) =>
      row.principalids.includes(selectedAgency)
    );
  }
  /* filter by level */
  if (selectedLevel && selectedLevel !== "") {
    const levelName = GetLevelNameById(production, selectedLevel);
    if (levelName)
      dataToShow = dataToShow.filter((item) => item.hlevel === levelName);
  }
  return dataToShow;
}
export function CalcAllSums(dataArr) {
  let sums = { ma_sum: 0, ms_sum: 0, pdp_sum: 0 };
  if (dataArr) {
    dataArr.forEach((row) => {
      sums.ma_sum += row.ma_production;
      sums.ms_sum += row.medsupp_production;
      sums.pdp_sum += row.pdp_production;
    });
  }
  return sums;
}
const rowContainsAgency = (row) => {
  const agencies = row.original.agencies;
  const result = agencies.filter((agency) => {
    return agency === sessionStorage.getItem("agencyId");
  });
  return result.length > 0;
};

const sortProductionColumn = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

const getFilter = (placeholder, initial) => {
  return ({ filter, onChange }) => (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {initial ? (
        <Grid item xs={4} md={4}>
          <Typography>Filters: </Typography>
        </Grid>
      ) : (
        <div />
      )}
      {initial ? (
        <Grid item xs={8} md={8}>
          <Grid container direction="row">
            <input
              type="text"
              autoComplete="off"
              placeholder={placeholder}
              value={filter ? filter.value : ""}
              onChange={(event) => onChange(event.target.value)}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} md={12}>
          <input
            type="text"
            placeholder={placeholder}
            value={filter ? filter.value : ""}
            onChange={(event) => onChange(event.target.value)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export function BuildTableColumns(criteria) {
  const {
    displayState,
    displayCounty,
    displayEffectiveDate,
    displayWritingNumber,
    selectedProducts,
  } = criteria;
  const tableColumns = [
    {
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Agencies
          </Typography>
        );
      },
      HeaderText: "Agencies",
      accessor: "agencies",
      show: false,
    },
    {
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Agent Name
          </Typography>
        );
      },
      HeaderText: "Agent Name",
      accessor: "agentname",
      sortable: true,
      maxWidth: 250,
      Filter: getFilter("...agent", true),
      Cell: (row) => {
        if (rowContainsAgency(row)) {
          return (
            <Link
              to={{
                pathname: `/AgentList`,
                state: {
                  agentName: row.value,
                },
              }}
            >
              <Typography>{row.value}</Typography>
            </Link>
          );
        } else {
          return (
            <HtmlToolTip
              placement="top"
              title="This agent is no longer contracted with you."
            >
              <Typography variant="span">
                {row.value} -
                <Typography variant="span" style={{ color: "red" }}>
                  {" "}
                  Not With Agency
                </Typography>
              </Typography>
            </HtmlToolTip>
          );
        }
      },
    },
    {
      accessor: "company_name",
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Company Name
          </Typography>
        );
      },
      HeaderText: "Company Name",
      Filter: getFilter("...company"),
      maxWidth: 250,
      sortable: true,
      wrap: true,
    },
    {
      accessor: "hlevel",
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Level
          </Typography>
        );
      },
      HeaderText: "Level",
      Filter: getFilter("...level"),
      style: { textAlign: "center" },
      maxWidth: 100,
      sortable: true,
      wrap: true,
    },
    {
      id: "uplines",
      accessor: (d) => {
        return stringUtls.firstUpperLetter(d.uplines);
      },
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Upline
          </Typography>
        );
      },
      HeaderText: "Upline",
      Filter: getFilter("...value"),
      sortable: true,
      wrap: false,
    },
    {
      accessor: "ma_production",
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> MA
          </Typography>
        );
      },
      HeaderText: "MA",
      Filter: getFilter("...value"),
      sortMethod: sortProductionColumn,
      style: { textAlign: "right" },
      maxWidth: 100,
      sortable: true,
      wrap: true,
      show:
        !selectedProducts || selectedProducts.includes("Medicare Advantage"),
    },
    {
      id: "medsupp_production",
      accessor: (d) => {
        return d.medsupp_production;
      },
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Med Supp
          </Typography>
        );
      },
      HeaderText: "Med Supp",
      Filter: getFilter("...value"),
      sortMethod: sortProductionColumn,
      style: { textAlign: "right" },
      maxWidth: 100,
      sortable: true,
      wrap: true,
      show:
        !selectedProducts || selectedProducts.includes("Medicare Supplement"),
    },
    {
      accessor: "pdp_production",
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> PDP
          </Typography>
        );
      },
      HeaderText: "PDP",
      Filter: getFilter("...value"),
      sortMethod: sortProductionColumn,
      style: { textAlign: "right" },
      maxWidth: 100,
      sortable: true,
      wrap: true,
      show:
        !selectedProducts ||
        selectedProducts.includes("Prescription Drug Plan"),
    },
  ];

  if (displayState) {
    tableColumns.push({
      accessor: "state",
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> State
          </Typography>
        );
      },
      HeaderText: "State",
      style: { textAlign: "center" },
      Filter: getFilter("...abbr"),
      maxWidth: 120,
      sortable: true,
      wrap: true,
    });
  }
  if (displayCounty) {
    tableColumns.push({
      id: "county",
      accessor: (d) => {
        return stringUtls.firstUpperLetter(d.county);
      },
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> County
          </Typography>
        );
      },
      HeaderText: "County",
      style: { textAlign: "center" },
      Filter: getFilter("...abbr"),
      maxWidth: 120,
      sortable: true,
      wrap: true,
    });
  }
  if (displayEffectiveDate) {
    tableColumns.push({
      id: "effectivedate",
      accessor: (d) => {
        // Note this is very specific to what is returned
        // if something is not working it is likely the
        // database function is now returning a different
        // format
        //
        // current format supported: YYYY/MM/DDTZ
        //
        // we want MM/DD/YYYY
        //
        // note for day we need to strip the timezone
        return moment.utc(d.effectivedate).format("L");
      },
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Effective Date
          </Typography>
        );
      },
      HeaderText: "Effective Date",
      Filter: getFilter("...date"),
      style: { textAlign: "right" },
      maxWidth: 120,
      sortable: true,
      wrap: true,
    });
  }
  if (displayWritingNumber) {
    tableColumns.push({
      accessor: "writing_number",
      Header: () => {
        return (
          <Typography>
            <SwapVert fontSize="small" /> Writing #
          </Typography>
        );
      },
      HeaderText: "Writing #",
      style: { textAlign: "center" },
      Filter: getFilter("...abbr"),
      maxWidth: 120,
      sortable: true,
      wrap: true,
    });
  }

  return tableColumns;
}

export function aggregateData(criteria, data) {
  if ((!data && !Array.isArray(data)) || (data && data.message)) {
    return [];
  }
  const allCarriers = criteria.selectedCarrier === "*";
  const aggregations = getAggregations(criteria);
  /* for each aggregation, group by */
  let aggregator = nest()
    .key((d) => {
      return d.agencies;
    })
    .key((d) => {
      return d.agentname;
    })
    .key((d) => {
      return d.carrier;
    })
    .key((d) => {
      return d.company_name;
    })
    .key((d) => {
      return d.uplines;
    })
    .key((d) => {
      return d.hlevel;
    });
  if (allCarriers) {
    aggregator = nest().key((d) => {
      return d.agentname;
    });
  }
  aggregations.map((agg) => {
    aggregator.key((d) => {
      return d[agg];
    });
  });
  const aggregated = aggregator
    .rollup((v) => {
      return {
        agencies: v[0].agencies,
        carrier: v[0].carrier,
        agentname: v[0].agentname,
        company_name: v[0].company_name,
        hlevel: v[0].hlevel,
        uplines: v[0].uplines,
        state: v[0].state,
        county: v[0].county,
        effectivedate: v[0].effectivedate,
        writing_number: v[0].writing_number,
        ma_production: sum(v, (d) => {
          return d.ma_production;
        }),
        medsupp_production: sum(v, (d) => {
          return d.medsupp_production;
        }),
        pdp_production: sum(v, (d) => {
          return d.pdp_production;
        }),
        record_date: v[0].record_date,
      };
    })
    .entries(data);
  /* need to flatten now for table expectation */
  let flat = [];
  flatten(aggregated, flat);
  return flat;
}
const flatten = (array, flatlist) => {
  array.map((obj, values) => {
    if (
      (typeof obj === "object" || typeof obj === "function") &&
      obj !== null &&
      !("value" in obj)
    ) {
      flatten(obj.values, flatlist);
    } else {
      flatlist.push(obj.value);
    }
  });
};

/**
 * Possible criteria is:
 *
 * - displayState
 * - displayCounty
 * - displayEffectiveDate
 * - displayWritingNumber
 *
 * Individually group those that are true.
 */
const getAggregations = (criteria) => {
  let aggregations = [];
  if (criteria.displayState) {
    aggregations.push("state");
  }
  if (criteria.displayCounty) {
    aggregations.push("county");
  }
  if (criteria.displayEffectiveDate) {
    aggregations.push("effectivedate");
  }
  if (criteria.displayWritingNumber) {
    aggregations.push("writing_number");
  }
  return aggregations;
};
