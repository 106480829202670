import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.css";
import { ThemeProvider } from "@mui/material/styles";
import AppTheme from "./AppTheme";

import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import ReactGA from "react-ga";

window.store = store;

function initializeReactGA() {
  ReactGA.initialize("UA-143269749-1");
  ReactGA.pageview(
    "/homePage",
    [],
    "Agency - " + sessionStorage.getItem("agencyId") + " viewed home page."
  );
}

initializeReactGA();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <div className="content">
        <ThemeProvider theme={AppTheme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </div>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
