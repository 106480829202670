import {
  SET_PLANCHANGE_CARRIERS,
  SET_PLANCHANGE_CARRIERS_ERROR,
  CLEAR_PLANCHANGE_CARRIERS,
  PLANCHANGE_CARRIERS_LOADING,
} from "../../constants/action-types";

const initState = {
  FetchingPlanChangeCarriersError: undefined,
  isFetchingPlanChangeCarriers: false,
};

export const planChangeCarriers = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_PLANCHANGE_CARRIERS:
      return {
        ...state,
        FetchingPlanChangeCarriersError: undefined,
        carriers: payload,
      };
    case CLEAR_PLANCHANGE_CARRIERS:
      return payload;
    case PLANCHANGE_CARRIERS_LOADING:
      return { ...state, isFetchingPlanChangeCarriers: payload };
    case SET_PLANCHANGE_CARRIERS_ERROR:
      return { ...state, FetchingPlanChangeCarriersError: payload };
    default:
      return state;
  }
};
