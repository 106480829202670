import React from "react";
import { Grid, InputBase, IconButton, Typography, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Route } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import SearchIcon from "@mui/icons-material/Search";
import ReactGA from "react-ga";
import "chart.piecelabel.js";

const handleAgentSearch = (history) => (event) => {
  event.preventDefault();
  const value = event.target.name.value;
  history.push({
    pathname: "/AgentList",
    state: {
      agentName: { value },
    },
  });
};

const styles = () => ({
  card: {
    background: "linear-gradient(to bottom right,  white, #0e76bc) !important",
    minHeight: "fit-content",
  },
  typography: {
    color: "#003760",
  },
  table: {
    padding: 10,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});

const handleSliceClick = (history) => (elements) => {
  if (elements.length === 1) {
    const chartModel = elements[0]._model;
    const statuses = [];
    switch (chartModel.label) {
      case "Approved - Total":
        statuses.push("Approved");
        break;
      case "Approved - Current Year":
        statuses.push("Approved");
        break;
      case "Approved - Intent to Move Away Submitted":
        statuses.push("Approved - Intent to Move Away Submitted");
        break;
      case "Approved - Released":
        statuses.push("Approved - Released");
        break;
      case "Sent To Agent":
        statuses.push("Sent to agent");
        break;
      case "In Process":
        statuses.push(
          "Agent Cert",
          "Pending writing",
          "Received",
          "Submitted",
          "Submitted missing doc",
          "Submitted no cert"
        );
        break;
      case "Intent To Move":
        statuses.push("Intent move big");
        break;
      default:
        break;
    }
    ReactGA.event({
      category: "Pie Chart - Dashboard",
      action: "Click Through",
      label:
        "Agency : " +
        sessionStorage.getItem("agencyId") +
        " navigated to agents from dashboard.",
    });
    history.push({
      pathname: "/AgentList",
      state: {
        statuses: statuses,
        isContractBased: true,
      },
    });
  }
};

const AgentInformation = (props) => {
  const { classes, agentTotal, contracts } = props;
  return (
    <>
      <Typography
        className={classes.typography}
        variant="h5"
        component="h3"
        gutterBottom
        // style={{ padding: 5 }}
      >
        Agent Information
      </Typography>
      <Grid item container justify="space-around" xs={12}>
        <Paper>
          <Typography style={{ padding: 10 }}>
            Total Number of Agents: {agentTotal}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography style={{ fontWeight: "bolder" }}>
                  Agent Contract Status
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="center">
                <Typography
                  style={{
                    paddingTop: 10,
                    fontWeight: "bolder",
                  }}
                >
                  Select legend items to filter chart entries
                </Typography>
                <Route
                  render={({ history }) => (
                    <div
                      data-tour="ad__legend"
                      style={{
                        display: "block",
                        height: "365px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Pie
                        height={300}
                        data={contracts.pieData}
                        onElementsClick={handleSliceClick(history)}
                        options={{
                          legend: {
                            onHover: function (e) {
                              e.target.style.cursor = "pointer";
                            },
                          },

                          hover: {
                            onHover: function (e) {
                              const point = this.getElementAtEvent(e);
                              if (point.length)
                                e.target.style.cursor = "pointer";
                              else e.target.style.cursor = "default";
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                stacked: false,
                                gridLines: {
                                  color: "rgba(0, 0, 0, 0)",
                                  display: false,
                                },
                                ticks: {
                                  display: false,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                stacked: false,
                                gridLines: {
                                  color: "rgba(0, 0, 0, 0)",
                                  display: false,
                                },
                                ticks: {
                                  display: false,
                                },
                              },
                            ],
                          },
                          pieceLabel: {
                            render: "value",
                            position: "outside",
                            fontStyle: "bold",
                            arc: false,
                          },
                          maintainAspectRatio: false,
                        }}
                      />
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{ padding: 10 }}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Paper elevation={1}>
              <Route
                render={({ history }) => (
                  <form onSubmit={handleAgentSearch(history)}>
                    <InputBase
                      name="name"
                      className={classes.input}
                      placeholder="Search Agents"
                      inputProps={{
                        "aria-label": "Search Agents",
                      }}
                    />
                    <IconButton
                      className={classes.iconButton}
                      aria-label="Search"
                      type="submit"
                    >
                      <SearchIcon />
                    </IconButton>
                  </form>
                )}
              />
            </Paper>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default withStyles(styles)(AgentInformation);
