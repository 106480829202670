import React, { useState, useEffect } from "react";
import { Dialog, Grid, Typography, useMediaQuery } from "@mui/material";
import useCalcStyles from "./useCalcStyles";
import YearMonthPicker from "./YearMonthPicker";
import CommonDialogTitle from "./CommonDialogTitle";
import { getCalc } from "../../services/GraphQl/BDSWebService";

const CalcForm = (props) => {
  const classes = useCalcStyles();
  const { open, handleClose } = props;
  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;

  const [eligibleDate, setEligibleDate] = useState(new Date());

  const [enrollDate, setEnrollDate] = useState(new Date());

  const [penalty, setPenalty] = useState(0);

  useEffect(() => {
    const goGetTheRate = async () => {
      const res = await getCalc(eligibleDate, enrollDate);
      console.log(res.data.pdpCalc);
      setPenalty(res.data.pdpCalc.toFixed(2));
    };
    if (eligibleDate && enrollDate) {
      goGetTheRate();
    } else {
      setPenalty(0);
    }
  }, [eligibleDate, enrollDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={mQuery}
    >
      <CommonDialogTitle
        title="MEDICARE PART D PENALTY CALCULATOR"
        close={handleClose}
        variant="h6"
      />
      <Grid container justifyContent="center">
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid item xs={12}>
            <div className={classes.intructions}>
              <Typography variant="subtitle2" className={classes.text}>
                * Please fill in the dates below to get an estimated penalty
                amount.
              </Typography>

              <Typography
                variant="subtitle2"
                className={classes.text}
                gutterBottom
              >
                * If your eligibility date is prior to January 2006 you will use
                January 2006 as the starting date
              </Typography>
            </div>
            <Typography
              style={{ color: "#003760" }}
              variant={mQuery ? "h6" : "h5"}
              align="center"
            >
              MEDICARE ELIGIBILITY DATE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <YearMonthPicker
              selectedEligibleDate={eligibleDate}
              handleEligibleDateChange={setEligibleDate}
              selectedEnrollDate={enrollDate}
              handleEnrollDateChange={setEnrollDate}
              handleClose={handleClose}
            />
          </Grid>
          <Grid item xs={12} className={classes.result}>
            <Typography
              variant="subtitle2"
              style={{ color: "red" }}
              gutterBottom
            >
              This is only an estimate. Your actual penalty cost may vary.
            </Typography>
            <Typography variant="body1" style={{ color: "#003760" }}>
              Penalty: {penalty}
            </Typography>
          </Grid>
        </form>
      </Grid>
    </Dialog>
  );
};

export default CalcForm;
