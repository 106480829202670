import assert from "assert";

// This function uses nodes deep equality assertion to compare objects
// and assumes objects have been trimmed (strings)
export const objectDeepEqualityCheck = (obj1, obj2) => {
  try {
    // If objects are equal return true
    assert.deepStrictEqual(obj1, obj2);
    return true;
  } catch (error) {
    // Assertion error hit - deep equality failed
    return false;
  }
};
