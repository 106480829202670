import {
  SET_ALL_CARRIERS,
  SET_ALL_CARRIERS_ERROR,
  CLEAR_ALL_CARRIERS,
  GET_STATES,
  GET_STATES_ERROR,
  GET_AGENTLIST,
  GET_AGENTLIST_ERROR,
} from "../../constants/action-types";
import { getStates , getAgents , getAllCarriers} from "../../../services/GraphQl/BDSWebService";
import _ from "lodash";

export const getAllCarriersMethod = () => {
  return (dispatch) => {
    getAllCarriers()
      .then((result) => {
        /* alpha sort */
        result = _.sortBy(result, [(row) => row.name.toString().toLowerCase()]);
        result = [...new Set(result)];
        result = result.map((carrier) => {
          return carrier;
        });
        dispatch({ type: SET_ALL_CARRIERS, payload: result });
      })
      .catch((err) => {
        dispatch({ type: SET_ALL_CARRIERS_ERROR, payload: err });
      });
  };
};

export const clearAllCarriers = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ALL_CARRIERS });
  };
};

export const getStatesMethod = () => {
  return (dispatch) => {
    getStates()
      .then((result) => {
        /* alpha sort */
        result = _.sortBy(result, [(row) => row.name.toString().toLowerCase()]);
        result = [...new Set(result)];
        dispatch({ type: GET_STATES, payload: result });
      })
      .catch((err) => {
        dispatch({ type: GET_STATES_ERROR, payload: err });
      });
  };
};

export const getAgentsMethod = (agencyId, offset, limit) => {
  return (dispatch) => {
  getAgents(agencyId, offset, limit)
      .then((result) => {
        /* alpha sort */
        result = _.sortBy(result, [
          (row) => row.lastname.toString().toLowerCase(),
        ]);
        result = [...new Set(result)];
        dispatch({ type: GET_AGENTLIST, payload: result });
      })
      .catch((err) => {
        dispatch({ type: GET_AGENTLIST_ERROR, payload: err });
      });
  };
};
